import { PropertyConstants } from "./action-types";
import { propertyDataApis } from "../utils/api";
import { message } from "antd";
import { v4 as uuidv4 } from "uuid";
import { appStatusChange } from "../../App/state/actions";
import { transactionLaneConstants } from "../../Rtd/state/action-types";
import { transactionLaneDataHandle } from "../../Rtd/state/actions";

const adminURL = process.env.REACT_APP_ADMIN_BASE_URL;

// To Get All Property Data Method
const getPropertyDataLoading = ({pageNumber}) => {
  return {
    type: PropertyConstants.GET_PROPERTY_DATA_LOADING,
    dataLoading: true,
    pageNumber,
  };
};
const getPropertyDataError = (error) => {
  return {
    type: PropertyConstants.GET_PROPERTY_DATA_ERROR,
    getTcDataError: error?.response?.data?.message || "Error occurred while getting property data!",
  };
};

const getPropertyDataSuccess = (response, setselectedData) => {
  // setselectedData(response?.data?.info?.[0])
  return {
    type: PropertyConstants.GET_PROPERTY_DATA_SUCCESS,
    tcData: response?.data?.info?.transactions,
  };
};

const getPropertyData =
  ({ type, query, filterSort, source, sourceAxios, unmounted, categoryId, category, readOnly, pageNumber, pageSize }) =>
  (dispatch) => {
    dispatch(getPropertyDataLoading({pageNumber}));
    return propertyDataApis
      .getPropertyDataRequest({ type, query, filterSort, source, sourceAxios, categoryId, category, readOnly, pageNumber, pageSize })
      .then((response) => {
        if (!unmounted.value) {
          dispatch(getPropertyDataSuccess(response));
          if(response?.data?.info?.message?.trim()){
            message.error(response?.data?.info?.message?.trim() || "Error occurred while getting property data!");
          }
        }
        return response;
      })
      .catch((error) => {
        if (!unmounted.value) {
          message.error(error?.response?.data?.message || "Error occurred while getting property data!");
          dispatch(getPropertyDataError(error));
        }
      });
  };

  const toggleFolderOpenState = (folderId) => ({
    type: PropertyConstants.TOGGLE_FOLDER_OPEN_STATE,
    folderId,
  });
  const resetFolderOpenState = () => {
    return {
      type: PropertyConstants.RESET_FOLDER_OPEN_STATE,
    }
  }
const setSelectedPropertyData = (payload) => {
  return {
    type: PropertyConstants.SET_SELECTED_PROPERTY_DATA,
    selectedData: payload,
  };
};

// Get Property Docs
const getClientDocRequestLoading = (error) => {
  return {
    type: PropertyConstants.GET_CLIENT_DOC_LOADING,
    uploadPdfError: error,
    uploadPdfLoading: false,
  };
};

const getClientDocRequestSuccess = (response) => {
  return {
    type: PropertyConstants.GET_CLIENT_DOC_SUCCESS,
    propertyDocs: response?.data?.info,
    uploadPdfLoading: false,
  };
};

const getClientDocRequestError = (error) => {
  return {
    type: PropertyConstants.GET_CLIENT_DOC_ERROR,
    uploadPdfError: error,
    uploadPdfLoading: false,
  };
};
const getPropertyDocWithoutSectionLoading = (error) => {
  return {
    type: PropertyConstants.GET_PROPERTY_DOC_WITHOUT_SECTION_LOADING,
  };
};

const getPropertyDocWithoutSectionSuccess = (transactionDocs, contactDocs) => {
  return {
      type: PropertyConstants.GET_PROPERTY_DOC_WITHOUT_SECTION_SUCCESS,
      propertyDocsWithoutSection: transactionDocs || [],
      contactDocs: contactDocs || [] 
    };
};

const getPropertyDocWithoutSectionError = (error) => {
  return {
    type: PropertyConstants.GET_PROPERTY_DOC_WITHOUT_SECTION_ERROR,
    propertDocsWithoutSectionError: error,
    };
};
const getPropertyDocsFun = ({params, unmounted, source,type}) => {
  return (dispatch, getState) => {
    if((type==="SendEmail") || (type === "commonSendEmail")){ 
      dispatch(getPropertyDocWithoutSectionLoading())
    }else{
    dispatch(getClientDocRequestLoading());
    }
    propertyDataApis
      .getClientDocRequest({params, source})
      .then((response) => {
        if(!unmounted.value){
          if(type==="SendEmail"){ 
             dispatch(getPropertyDocWithoutSectionSuccess(response?.data?.info));
            }else if(type === "commonSendEmail"){
              dispatch(getPropertyDocWithoutSectionSuccess(response?.data?.info?.transactionDocs, response?.data?.info?.contactDocs));
            }else{
            dispatch(getClientDocRequestSuccess(response));
            }
         }
      })
      .catch((error) => {
        if(!unmounted.value){
          if(type==="SendEmail"){ 
          dispatch(getPropertyDocWithoutSectionError(error));
        }else{
          dispatch(getClientDocRequestError(error));
        }message.error(
            error?.response?.data?.message || "Error occured while getting property docs data!",
          );
        }
      });
  };
};

const createFolderFun = ({ newFolderData }) => {
  return (dispatch, getState) => {
    dispatch(appStatusChange.loadOpacityLoader(true));
    propertyDataApis
      .createFolderRequest({ newFolderData })
      .then((response) => {
        message.success(response?.data?.info?.message || "Folder Created Successfully!");
        dispatch(appStatusChange.loadOpacityLoader(false));
        dispatch(uploadPropertyDocsSuccess());
      })
      .catch((error) => {
        dispatch(appStatusChange.loadOpacityLoader(false));
        message.error(
          error?.response?.data?.message || "We encountered an issue while creating folder. Please try again later!",
        );
      });
  };
};

const updatePropertyDocsFolderFun = ({ folderData }) => {
  return (dispatch, getState) => {
    dispatch(appStatusChange.loadOpacityLoader(true));
    propertyDataApis
      .updateFolderRequest({ folderData })
      .then((response) => {
        message.success(response?.data?.info?.message || "File Moved to Folder Successfully!");
        dispatch(appStatusChange.loadOpacityLoader(false));
        dispatch(uploadPropertyDocsSuccess());
      })
      .catch((error) => {
        dispatch(appStatusChange.loadOpacityLoader(false));
        message.error(
          error?.response?.data?.message || "We encountered an issue while creating folder. Please try again later!",
        );
      });
  };
};

const getPropertyDocsFolderFunSuccess = (response) => {
  return {
    type: PropertyConstants.GET_PROPERTY_DOC_FOLDER_DATA_SUCCESS,
    propertyFolderData: response,
  }
}

const getPropertyDocsFolderFun = ({ params }) => {
  return (dispatch, getState) => {
    // dispatch(appStatusChange.loadOpacityLoader(true));
    propertyDataApis
      .getPropertyDocsFolderFunRequest({ params })
      .then((response) => {
        // message.success(response?.data?.info?.message || "Data Fetched Successfully!");
        // dispatch(appStatusChange.loadOpacityLoader(false));
        // dispatch(uploadPropertyDocsSuccess());
        dispatch(getPropertyDocsFolderFunSuccess(response?.data?.info?.[0]))
      })
      .catch((error) => {
        // dispatch(appStatusChange.loadOpacityLoader(false));
        message.error(
          error?.response?.data?.message || "We encountered an issue while creating folder. Please try again later!",
        );
      });
  };
};

const getMoveToFolderFunSuccess = (response) => {
  return {
    type: PropertyConstants.GET_MOVE_TO_FOLDER_DATA_SUCCESS,
    moveToFolderData: response,
  }
}

const getMoveToFolderFunLoading = () => {
  return {
    type: PropertyConstants.GET_MOVE_TO_FOLDER_DATA_LOADING,
  }
}

const getMoveToFolderFunError = () => {
  return {
    type: PropertyConstants.GET_MOVE_TO_FOLDER_DATA_ERROR,
  }
}



const getMoveToFolderFun = ({ params }) => {
  return (dispatch, getState) => {
    dispatch(getMoveToFolderFunLoading())
    propertyDataApis
      .getMoveToFolderFunRequest({ params })
      .then((response) => {
        dispatch(getMoveToFolderFunSuccess(response?.data?.info))
      })
      .catch((error) => {
        dispatch(getMoveToFolderFunError());
        message.error(
          error?.response?.data?.message || "We encountered an issue while getting the folder data. Please try again later!",
        );
      });
  };
};

const moveToFolderFun = ({params}) => {
  return (dispatch) => {
    dispatch(appStatusChange.loadOpacityLoader(true));
    propertyDataApis.moveToFolderFunRequest({params})
    .then((response) => {
      dispatch(appStatusChange.loadOpacityLoader(false));
      dispatch(uploadPropertyDocsSuccess());
      message.success(response?.data?.info?.message || "Document successfully moved to the folder.");
    })
    .catch((error) => {
      dispatch(appStatusChange.loadOpacityLoader(false));
      message.error(
        error?.response?.data?.message || "We encountered an issue while moving files into the folder. Please try again later!",
      );
    })
  }
}


//   Upload Property Documents
const uploadPropertyDocsError = (error) => {
  return {
    type: PropertyConstants.UPLOAD_DOCUMENT_FAILURE,
    uploadPdfError: error,
    uploadPdfLoading: false,
  };
};

const uploadPropertyDocsLoading = (error) => {
  return {
    type: PropertyConstants.UPLOAD_DOCUMENT_LOADING,
    uploadPdfError: error,
    uploadPdfLoading: false,
  };
};

const uploadPropertyDocsSuccess = () => {
  return {
    type: PropertyConstants.UPLOAD_DOCUMENT_SUCCESS,
  };
};

const uploadPropertyDocsFun = (data) => {
  return (dispatch, getState) => {
    dispatch(appStatusChange.loadOpacityLoader(true));
    propertyDataApis
      .uploadPropertyDocsRequest(data)
      .then((response) => {
        message.success(response?.data?.info?.message || "File Uploaded Successfully!");
        dispatch(appStatusChange.loadOpacityLoader(false));
        dispatch(uploadPropertyDocsSuccess());
      })
      .catch((error) => {
        dispatch(appStatusChange.loadOpacityLoader(false));
        message.error(
          error?.response?.data?.message || "We encountered an issue while uploading property docs. Please try again later!",
        );
      });
  };
};

// Delete Property Docs
const removePropertyDocsFun = (docId) => {
  return (dispatch) => {
    dispatch(appStatusChange.loadOpacityLoader(true));
    propertyDataApis
    .deletepropertyDocsRequest(docId)
      .then((response) => {
        message.success(response?.data?.info?.message || "File Deleted Successfully!");
        dispatch(appStatusChange.loadOpacityLoader(false));
        dispatch(uploadPropertyDocsSuccess());
      })
      .catch((error) => {
        dispatch(appStatusChange.loadOpacityLoader(false));
        message.error( error?.response?.data?.message || "We encountered an issue while deleting property docs data. Please try again later!");
      });
  };
}
const getPreDoraDocumentsLoading = () => {
  return {
    type: PropertyConstants.GET_PRE_DORA_DOCUMENTS_LOADING,
  };
};
const getPreDoraDocumentsError = (error) => {
  return {
    type: PropertyConstants.GET_PRE_DORA_DOCUMENTS_FAILURE,
    getPreDoraDocumentsError: error,
    getPreDoraDocumentsLoading: false,
  };
};

const getPreDoraDocumentsSuccess = (response) => {
  return {
    type: PropertyConstants.GET_PRE_DORA_DOCUMENTS_SUCCESS,
    getPreDoraDocumentsData: response,
    getPreDoraDocumentsLoading: false,
  };
};

const getPreDoraDocumentsData = ({data, unmounted, source, fiters, brokerageId}) => {
  return (dispatch) => {
    dispatch(getPreDoraDocumentsLoading());
    propertyDataApis
      .getPreNestedDoraDocuments({data, source, fiters, brokerageId})
      .then((response) => {
        if(!unmounted.value){
          dispatch(getPreDoraDocumentsSuccess(response.data.info));
        }
      })
      .catch((error) => {
        if(!unmounted.value){
          dispatch(getPreDoraDocumentsError(error));
          message.error(error?.response?.data?.message || "We encountered an issue while getting dora documents data. Please try again later!");
        }
      });
  };
};

const sharePropertyDocsLoading = () => {
  return {
    type: PropertyConstants.SHARE_PROPERTY_DOCS_LOADING,
  };
};
const sharePropertyDocsError = (error) => {
  return {
    type: PropertyConstants.SHARE_PROPERTY_DOCS_FAILURE,
  };
};

const sharePropertyDocsSuccess = (response) => {
  return {
    type: PropertyConstants.SHARE_PROPERTY_DOCS_SUCCESS,
    sharePropertyDocsData: response.data.info,
  };
};

const sharePropertyDocs = (payloadData, requiresRender) => {
  return (dispatch, getState) => {
    dispatch(appStatusChange.loadOpacityLoader(true));
    propertyDataApis
      .sharePropertyDocsRequest(payloadData)
      .then((response) => {
        if (payloadData.unShare) {
          message.success("Document unshared Successfully!");
        } else {
          message.success("Document Shared Successfully!");
        }
        dispatch(appStatusChange.loadOpacityLoader(false));
        if(requiresRender){
          dispatch(uploadPropertyDocsSuccess());
        }
      })
      .catch((error) => {
        message.error(error?.response?.data?.message || "Error occured while sharing property docs data!");
        dispatch(appStatusChange.loadOpacityLoader(false));
      });
  };
};


const deletePropertyContractDocs = ({payloadData}) => {
  return (dispatch, getState) => {
    dispatch(appStatusChange.loadOpacityLoader(true));
    propertyDataApis
      .deletePropertyContractDocsRequest({payloadData})
      .then((response) => {
        if (response) {
          message.success("Document Deleted Successfully!");
        } 
        dispatch(appStatusChange.loadOpacityLoader(false));
        if(payloadData?.requiresRender){
          dispatch(uploadPropertyDocsSuccess());
        }
        if(payloadData?.requiresWholeReRender){
          dispatch(changeFilterSort({...payloadData?.filterSort}));
        }
      })
      .catch((error) => {
        message.error(error?.response?.data?.message || "Error occured while sharing property docs data!");
        dispatch(appStatusChange.loadOpacityLoader(false));
      });
  };
};

const toggleDocumentInTransactionDashboard = ({payloadData}) => {
  return (dispatch, getState) => {
    dispatch(appStatusChange.loadOpacityLoader(true));
    propertyDataApis
      .toggleDocumentInTransactionDashboardRequest({payloadData})
      .then((response) => {
        if (response) {
          message.success(response?.data?.info?.message);
        } 
        dispatch(appStatusChange.loadOpacityLoader(false));
        if(payloadData?.requiresRender){
          dispatch(uploadPropertyDocsSuccess());
        }
      })
      .catch((error) => {
        message.error(error?.response?.data?.message || "Error occured while sharing property docs data!");
        dispatch(appStatusChange.loadOpacityLoader(false));
      });
  };
};

const taskUpdated =()=>{
    return{
        type:PropertyConstants.TASK_UPADTED
    }
}

// TASK actions
const getTasksLoading = () => {
  return {
    type: PropertyConstants.GET_TASKS_DATA_LOADING,
  };
};
const getTasksError = () => {
  return {
    type: PropertyConstants.GET_TASKS_DATA_ERROR,
  };
};

const getTasksSuccess = (response) => {
  return {
    type: PropertyConstants.GET_TASKS_DATA_SUCCESS,
    getTasksData: response.data.info,
  };
};

const getTasks = ({offerId, agentType, sortBy, orderBy, unmounted, source, delegateSide, delegateOwnerId, token, isClientDashboard}) => {
  return (dispatch, getState) => {
    dispatch(getTasksLoading());
    propertyDataApis
      .getTasksRequest({offerId, agentType, sortBy, orderBy, source, delegateSide, delegateOwnerId, token, isClientDashboard})
      .then((response) => {
        if(!unmounted.value){
        dispatch(getTasksSuccess(response));
        }
      })
      .catch((error) => {
        if(!unmounted.value){
        dispatch(getTasksError());
        message.error(error?.response?.data?.message || "Error occured while getting tasks!")
        }
      });
  };
};

// Add Email Methods
const addEmailLoading = () => {
  return {
    type: PropertyConstants.ADD_EMAIL_LOADING,
  };
};

const addEmailError = () => {
  return {
    type: PropertyConstants.ADD_EMAIL_ERROR,
  };
};

const addEmailSuccess = () => {
  return {
    type:transactionLaneConstants.ADD_EMAIL_SUCCESS
  }
}

const addEmail = ({data, successFunc, setCurrentData,setLoading}) => {
return (dispatch, getState) => {
   setLoading(true)
    propertyDataApis
      .addEmailRequest(data)
      .then((res) => {
      if(res?.data?.info?.showClients){
          setCurrentData((prev)=>{
            return {
              ...prev,
              responseData: res?.data?.info
            }
          })
          setLoading(false)
        }
        else{
         message.success("Email added successfully!");
          successFunc({reducerFunc: addEmailSuccess});
        }
      })
      .catch((error) => {
        dispatch(addEmailError());
        message.error(error?.response?.data?.message || "Error occured while adding email!");
      });
  };
};

const setTasksLoading = (payload) => {
  return {
    type: PropertyConstants.SET_TASKS_LOADING,
    getTasksDataLoading:payload
  };
};
const editCustomTask = ({taskId, urlData, offerId, agentType, delegateSide, delegateOwnerId}) => {
  return (dispatch) => {
    dispatch(setTasksLoading(true));
    propertyDataApis
      .editCustomTaskRequest({taskId, urlData, agentType, offerId, delegateSide, delegateOwnerId})
      .then((response) => {
        dispatch(taskUpdated());
        message.success("Updated Successfully!");
      })
      .catch((error) => {
        dispatch(setTasksLoading(false));
        message.error(error?.response?.data?.message || "We encountered an issue while updating this task item. Please try again later!")
      }
      );
  };
};

const updateTasks = ({data, offerId, agentType, source, delegateSide, delegateOwnerId}) => {
  return (dispatch, getState) => {
    dispatch(getTasksLoading());
    propertyDataApis
      .completeUncompleteTaskRequest({data, source, offerId, delegateSide, delegateOwnerId})
      .then((response) => {
        dispatch(taskUpdated());
        message.success("Updated Successfully!");
      })
      .catch((error) => {
        message.error(error?.response?.data?.message || "We encountered an issue while updating this task item. Please try again later!")
      });
  };
};

const addCustomTaskLoading = () => {
  return {
    type: PropertyConstants.ADD_CUSTOM_TASK_LOADING,
  };
};

const addCustomTaskError = () => {
  return {
    type: PropertyConstants.ADD_CUSTOM_TASK_ERROR,
  };
};

const addCustomTask = (urlData) => {
  return (dispatch, getState) => {
    dispatch(addCustomTaskLoading());
    propertyDataApis
      .addCustomTaskRequest(urlData)
      .then((response) => {
        dispatch(taskUpdated());
        message.success("Manual Task Added Successfully!");
      })
      .catch((err) => {
        message.error(err?.response?.data?.message || "Error occured while creating custom task!")
        dispatch(addCustomTaskError());
      });
  };
};

const deleteCustomTask = ({taskId, offerId, agentType, delegateSide, delegateOwnerId}) => {
  return (dispatch, getState) => {
    propertyDataApis
      .deleteCustomTaskRequest({taskId, offerId, agentType, delegateSide, delegateOwnerId})
      .then((response) => {
        dispatch(taskUpdated());
        message.success("Deleted Successfully!");
      })
      .catch((error) =>
        message.error(error?.response?.data?.message || "Error occured while deleting custom task!")
      );
  };
};

const urlShorthandFailure = (error) => {
  return {
    type: PropertyConstants.SHORTHAND_URL_FAILURE,
    urlShorthandLoading: error,
    urlShorthandError: false,
  };
};

const urlShorthandSuccess = (response) => {
  return {
    type: PropertyConstants.SHORTHAND_URL_SUCCESS,
    urlShorthandData: response,
    urlShorthandLoading: false,
  };
};

const urlShorthandLoading = (response) => {
  return {
    type: PropertyConstants.SHORTHAND_URL_LOADING,
    urlShorthandLoading: true,
  };
};

const urlShorthandApiCalling = (data, source, clientToken) => {
  return (dispatch, getState) => {
    dispatch(urlShorthandLoading(true));
    propertyDataApis
      .urlShorthandApi(data, source, clientToken)
      .then((response) => {
        dispatch(urlShorthandSuccess(response.data.info._id));
      })
      .catch((error) => {
        dispatch(urlShorthandFailure(error));
      });
  };
};

// Property Image post actions
const postPropertyImageLoading = () => {
    return {
      type: PropertyConstants.POST_PROPERTY_IMAGE_LOADING,
      PropertyImageDataLoading : true,
    };
  };

  const postPropertyImageError = (error) => {
    return {
      type: PropertyConstants.POST_PROPERTY_IMAGE_ERROR,
      propetyImageDataError: error,
    };
  };

  const postPropertyImageSuccess = (response) => {
    return {
      type: PropertyConstants.POST_PROPERTY_IMAGE_SUCESS,
      PropertyImageData: response.data.info,
    };
  };

  const postPropertyImage = (data, redirectFunc) => {
    return (dispatch, getState) => {
      dispatch(appStatusChange.loadOpacityLoader(true));
      propertyDataApis
        .postPropertyImageApi(data)
        .then((response) => {
          dispatch(appStatusChange.loadOpacityLoader(false));
          message.success("Image uploaded Successfully!");
          redirectFunc();
        })
        .catch((error) => {
          dispatch(appStatusChange.loadOpacityLoader(false));
          message.error(error?.response?.data?.message || "Error occured while uploading property image!")
        });
    };
  };

  // Property Image delete actions
const deletePropertyImageLoading = () => {
    return {
      type: PropertyConstants.DELETE_PROPERTY_IMAGE_LOADING,
    };
  };
  const deletePropertyImageError = (error) => {
    return {
      type: PropertyConstants.DELETE_PROPERTY_IMAGE_ERROR,
    };
  };

  const deletePropertyImageSuccess = (response) => {
    return {
      type: PropertyConstants.DELETE_PROPERTY_IMAGE_SUCESS,
    };
  };

  const deletePropertyImage = (Id, redirectFunc) => {
    return (dispatch, getState) => {
      dispatch(appStatusChange.loadOpacityLoader(true));
      propertyDataApis
        .deletePropertyImageApi(Id)
        .then((response) => {
          dispatch(appStatusChange.loadOpacityLoader(false));
          message.success(response?.data?.info?.message || "Image deleted Successfully!");
          redirectFunc();
        })
        .catch((error) => {
          dispatch(appStatusChange.loadOpacityLoader(false));
          message.error(error?.response?.data?.message || "Error occured while deleting property image!");
        });
    };
  };

const getNeedToknowLoading = (value) => {
  return {
    type: PropertyConstants.NEED_TO_KNOW_LOADING,
    value: value
  };
};

const getNeedToknowError = (error) => {
  return {
    type: PropertyConstants.NEED_TO_KNOW_FAILURE,
    errorMsg: error?.response?.data?.message || "Error while fetching need to know list!",
  };
};

const getNeedToknowSuccess = (response) => {
  return {
    type: PropertyConstants.NEED_TO_KNOW_SUCCESS,
    NeedToKnowData: response.data.info.result || [],
  };
};

const getNeedToknow = ({offerId, unmounted, source, delegateSide, delegateOwnerId}) => {
  return (dispatch) => {
    dispatch(getNeedToknowLoading(true));
    propertyDataApis
      .getNeedToknowRequest({offerId, source, delegateSide, delegateOwnerId})
      .then((response) => {
        if(!unmounted.value){
          dispatch(getNeedToknowSuccess(response));
        }
      })
      .catch((error) => {
        if(!unmounted.value){
          dispatch(getNeedToknowError(error));
          message.error(error?.response?.data?.message || "Error while fetching need to know list!");
        }
      });
  };
};

const updateNeedToKnow = (response) => {
  return {
    type: PropertyConstants.UPDATE_NEED_TO_KNOW,
    updateNeedToKnowData: [],
  };
};

const uploadNeedToknow = (data) => {
  return (dispatch) => {
    dispatch(getNeedToknowLoading(true));
    propertyDataApis
      .uploadNeedToknowRequest(data)
      .then((response) => {
        message.success("Contact Added Successfully!");
        dispatch(updateNeedToKnow());
      })
      .catch((error) => {
        message.error(error?.response?.data?.message || "Error while uploading need to know list data!");
        dispatch(getNeedToknowLoading(false));
      });
  };
};

const removeNeedToknow = ({data}) => {
  return (dispatch) => {
    dispatch(getNeedToknowLoading(true));
    propertyDataApis
      .removeNeedToknowRequest({data})
      .then((response) => {
        message.success("Contact Deleted Successfully!");
        dispatch(updateNeedToKnow());
      })
      .catch((error) => {
        message.error(error?.response?.data?.message || "Error while deleting needToKnow list data!");
        dispatch(getNeedToknowLoading(false));
      });
  };
};

const getConstantsLoading = () => {
  return {
    type: PropertyConstants.GET_CONSTANTS_LOADING,
  };
};

const getConstantsError = (error) => {
  return {
    type: PropertyConstants.GET_CONSTANTS_FAILURE,
    errorMsg: error?.response?.data?.message,
  };
};

const getConstantsSuccess=(res, isMlsId)=>{
  if(isMlsId){
    return {
      type:PropertyConstants.GET_CONSTANTS_SUCCESS,
      getPropertyLicenseData: res.data.info.linkMLSConstants
    }
  }else{
    return{
      type:PropertyConstants.GET_CONSTANTS_SUCCESS,
      getPropertyLicenseData: res.data.info.licenseIDTypeConstants
    }
  }
}

const getMlsConstants =({isMlsId, source, unmounted})=>{
  return(dispatch) => {
    dispatch(getConstantsLoading())
    propertyDataApis.getMlsConstRequest({isMlsId, source})
    .then((response) => {
      if(!unmounted.value){
        dispatch(getConstantsSuccess(response,isMlsId))
      }
    })
    .catch((error) => {
      if(!unmounted.value){
        dispatch(getConstantsError(error))
        message.error(error?.response?.data?.message || "Error occured while getting mlsconstants!");
      }
    })
  }
}

//Linking property to mls methods
const linkMlsLoading = () => {
  return {
    type: PropertyConstants.LINK_MLS_LOADING,
  };
};

const linkMlsError = (error) => {
  return {
    type: PropertyConstants.LINK_MLS_ERROR,
    errorMsg: error?.response?.data?.message || "Error occured while linking mls!",
  };
};

const linkMlsSuccess=(res)=>{
  return {
    type:PropertyConstants.LINK_MLS_SUCCESS,
    linkmlsData:res.data.info.data
  }
}
const linkMlsRequest =(data)=>{
  return(dispatch) => {
    dispatch(linkMlsLoading())
    propertyDataApis.linkMlsApi(data)
    .then((response) => {
      dispatch(linkMlsSuccess(response))
    })
    .catch((error) => {
      dispatch(linkMlsError(error))
      message.error(error?.response?.data?.message || "Error occured for linkmls request!");
    })
  }
}

const deLinkMlsLoading = () => {
  return {
    type: PropertyConstants.DELINK_MLS_LOADING,
  };
};
const deLinkMlsError = () => {
  return {
    type: PropertyConstants.DELINK_MLS_ERROR,
  };
};
const deLinkMlsSuccess=(res)=>{
  return {
    type:PropertyConstants.DELINK_MLS_SUCCESS,
    deLinkingMlsData:res.data.info.data
  }
}

const handleDelinkPropertyModal = (obj, triggerFunc)=>{
  return(dispatch) => {
    dispatch(deLinkMlsLoading())
    propertyDataApis.deLinkMlsApi(obj)
    .then((response) => {
      message.success("Property unlinked successfully!")
      dispatch(deLinkMlsSuccess(response));
      triggerFunc();
    })
    .catch((error) => {
      dispatch(deLinkMlsError())
      message.error(error?.response?.data?.message || "Error occured while delinking mls!");
    })
  }
}

const editSellersLoading = () => {
    return {
      type: PropertyConstants.EDIT_SELLERS_LOADING,
    };
  };

  const editSellersError = () => {
    return {
      type: PropertyConstants.EDIT_SELLERS_ERROR,
    };
  };

  const editSellersSuccess=(response)=>{
    return {
      type:PropertyConstants.EDIT_SELLERS_SUCCESS,
      successStatus:response.status
    }
  }


const editSellers = ({data, filterSort}) => {
    return (dispatch, getState) => {
        dispatch(editSellersLoading())
      propertyDataApis.editSellersRequest(data)
        .then((response) => {
          // message.success(response?.data?.message);
          message.success("Sellers Updated Successfully!");
          dispatch(editSellersSuccess(response));
          dispatch(changeFilterSort({...filterSort}));
        })
        .catch((error) => {
          message.error(error?.response?.data?.message || "Error occured while editing sellers!");
          dispatch(editSellersError());
        });
    };
  };

const offerTableUrlShorthandApiCalling = (data, currentPath) => {
  return (dispatch, getState) => {
    dispatch(appStatusChange.loadOpacityLoader(true));
    propertyDataApis
      .urlShorthandApi(data)
      .then((response) => {
        const tabId = uuidv4();
        document.cookie = `${tabId}=${currentPath}; domain=.resure.realestate; path=/;`;
        window.location.href = `${adminURL}/offer/${response.data.info._id}?tabId=${tabId}`;
      })
      .catch((error) => {
        message.error(error?.response?.data?.message || "Failed to generate urldata!");
        dispatch(appStatusChange.loadOpacityLoader(false));
      });
  };
};

// Deleting draft offers
const deleteDraftOfferLoading = () => {
  return {
    type: PropertyConstants.DELETE_DRAFT_OFFER_LOADING,
  };
};
const deleteDraftOfferError = (error) => {
  return {
    type: PropertyConstants.DELETE_DRAFT_OFFER_FAILURE,
  };
};

const deleteDraftOfferSuccess = (response) => {
  return {
    type: PropertyConstants.DELETE_DRAFT_OFFER_SUCCESS,
  };
};

const deleteDraftOfferFun = ({data,filterSort}) => {
  return (dispatch) => {
    dispatch(deleteDraftOfferLoading());
    propertyDataApis
      .deleteDraftOfferRequest({data})
      .then((response) => {
        dispatch(deleteDraftOfferSuccess(response));
        dispatch(changeFilterSort({...filterSort}));
        message.success(response?.data?.info?.message || "Offer Deleted Successfully!");
      })
      .catch((error) => {
        dispatch(deleteDraftOfferError(error));
        message.error(error?.response?.data?.message || "Error occured while deleting draft offer!");
      });
  };
}

// Delete draft listing
const deleteListing = ({ docId, filterSort = {}, delegateOwnerId, source }) => {
  return (dispatch, getState) => {
    dispatch(deleteDraftOfferLoading());
    propertyDataApis
      .deleteListingRequest({ docId, delegateOwnerId })
      .then((response) => {
        message.success(response?.data?.info?.message || "Deleted Successfully!");
        if (source === "PROPERTY_CLIENT_DOCS") {
          dispatch(uploadPropertyDocsSuccess());
        } else {
          dispatch(changeFilterSort({ ...filterSort }));
        }
      })
      .catch((error) => {
        message.error(error?.response?.data?.message || "Error occured while deleting listing!");
      });
  };
};

//Changing filter sort
 const changeFilterSort = (data, clearForm) => {
  return {
    type: PropertyConstants.CHANGE_FILTER_SORT,
    filterSort: data,
    clearForm
  }
}

const resetFilterSearch = () => {
  return {
    type: PropertyConstants.RESET_FILTER_SEARCH,
  }
}

const porstStatusChangeFunc = ({data, successFunc}) => {
    return (dispatch, getState) => {
      dispatch(appStatusChange.loadOpacityLoader(true));
      propertyDataApis
        .postStatusChange({data})
        .then((response) => {
          message.success(response?.data?.info?.message || "Status Changed Successfully!");
          dispatch(appStatusChange.loadOpacityLoader(false));
          successFunc({highlight: data?.highlight});
        })
        .catch((error) => {
          dispatch(appStatusChange.loadOpacityLoader(false));
          message.error(error?.response?.data?.message || "We encountered an issue while changin the status. Please try again later!");
        });
    }
}

//Changing filter sort
const triggerUploadedPdf = () => {
  return {
    type: PropertyConstants.UPLOAD_DOCUMENT_PDF_SUCCESS
  }
}

const uploadPdfDocumentFunc = ({data, successFunc}) => {
  return (dispatch, getState) => {
    dispatch(appStatusChange.loadOpacityLoader(true));
    propertyDataApis
      .uploadPdfDocument({data})
      .then((response) => {
        message.success(response?.data?.info?.message || "Document Uploaded Successfully!");
        dispatch(appStatusChange.loadOpacityLoader(false));
        if(successFunc){
          successFunc();
        }
        dispatch(triggerUploadedPdf());
      })
      .catch((error) => {
        dispatch(appStatusChange.loadOpacityLoader(false));
        message.error(error?.response?.data?.message || "We encountered an issue while uploading the document. Please try again later!");
      });
  }
}


  const SendMultipleDoc = ({ data, offerId, role, unmounted, source, Role, delegateOwnerId, agents }) => (dispatch) => {
      dispatch(appStatusChange.loadOpacityLoader(true));
      const loadingMessage = message.loading("Sending Document...", 0);
      return propertyDataApis
        .sendMultipleDocRequest(data)
        .then((response) => {
          loadingMessage();
          message.success(response?.data?.info?.status);
          if (agents) {
            dispatch(transactionLaneDataHandle.getTransactionLaneMethod({ offerId, role, unmounted, source, Role, delegateOwnerId }));
            dispatch(transactionLaneDataHandle.triggerIndividualTransactionFetch(true));
          }
          dispatch(appStatusChange.loadOpacityLoader(false));
        })
        .catch((error) => {
          loadingMessage();
          message.error(error?.response?.data?.message || "Error occured while getting property data!");
          dispatch(appStatusChange.loadOpacityLoader(false));
        });
    };

    const resetPropertyDocs = () => {
        return {
          type: PropertyConstants.RESET_PROPERTY_DOCS,
        };
    };
      
    const getAgentDetailsSuccess = (data) => {
      return {
        type: PropertyConstants.GET_AGENT_DETAILS_SUCCESS,
        agentDetailsArray: data && Object.keys(data).length && [data],
      }
    }
    
const getAgentDetailsLoading = (payload) => {

  return {
    type: PropertyConstants.GET_AGENT_DETAILS_LOADING,
    agentDetailsLoading: payload,
  };
};
const setAgentDetailsData = (payload) => {
  return {
    type: PropertyConstants.SET_AGENT_DETAILS_DATA,
    agentDetailsArray: payload,
  };
};

const getAgentDetails = ({ query }) => {
  return (dispatch, getState) => {
    dispatch(getAgentDetailsLoading(true));
    propertyDataApis
      .getAgentDataDetailsRequest({ query })
      .then((response) => {
        if (response.data.info._id) {
          dispatch(getAgentDetailsSuccess(response.data.info));
        } else {
          message.error("No account exists with this email address.");
        }
      })
      .catch((error) => {
        message.error(error?.response?.data?.message || "We encountered an issue in fetching the Agemt Details. Please try after some time.");
      })
      .finally(() => {
        dispatch(getAgentDetailsLoading(false));
      });
  };
};
const editAgentsLoading = (payload) => {
  return {
    type: PropertyConstants.GET_AGENT_DETAILS_LOADING,
    agentDetailsLoading: payload,
  };
};

const editAgents = ({ payloadData, currentAgentDeleted }) => {
  return (dispatch, getState) => {
    if (getState()?.rtdTransactionLane.triggerIndividualTransactionFetch) {
      dispatch(transactionLaneDataHandle.triggerIndividualTransactionFetch(false));
    }
    dispatch(editAgentsLoading(true));
    propertyDataApis
      .editAgentsRequest(payloadData)
      .then((response) => {
        if (response.data?.info.message) {
          message.success(response.data.info.message || "Listing Updated Successfully!")
        }
        if (!currentAgentDeleted) {
          dispatch(transactionLaneDataHandle.triggerIndividualTransactionFetch(true));
        } 
      })
      .catch((error) => {
        message.error(error?.response?.data?.message || "We encountered an issue in fetching the Agemt Details. Please try after some time.");
      })
      .finally(() => {
        dispatch(editAgentsLoading(false));
      });
  };
};

const getIfContactIsDeletedOrArchived = (contactId, history, stateObj) => {
  return () => {
    propertyDataApis.isContactDeletedOrArchived(contactId)
    .then((res) => {
      if(res?.data?.statusCode === 200){
          history?.push(stateObj);
      }else{
        message.error("This contact might be deleted or archived.")
      }
    })
    .catch((error) => {
      message.error(error?.response?.data?.message || "We encountered an issue in fetching the Contact Details as the contact mey be deleted or archived.");
    })
  }
}

const getOwnersInfoLoading = () => {
  return {
    type: PropertyConstants.GET_OWNERS_INFO_LOADING
  }
}

const getOwnersInfoSuccess = (response) => {
  return {
    type: PropertyConstants.GET_OWNERS_INFO_SUCCESS,
    ownersInfo:response
  }
}

const getOwnerInfoError = () => {
  return {
    type: PropertyConstants.GET_OWNERS_INFO_ERROR
  }
}
 
const getOwnersInfo = (ownerId) => {
  return (dispatch) => {
    dispatch(getOwnersInfoLoading())
    propertyDataApis.getOwnersDataApi(ownerId)
    .then((response) => {
      dispatch(getOwnersInfoSuccess(response?.data?.info?.values?.[0]))
    })
    .catch((error) => {
      dispatch(getOwnerInfoError())
      message.error(error?.response?.data?.message || "We encountered an issue while fetching the owners data. Please try again later!");
    })
  }
}

const deletePropertyDocFolder = (folderId) => {
  return (dispatch) => {
    dispatch(appStatusChange.loadOpacityLoader(true));
    propertyDataApis.deletePropertyDocFolder(folderId)
    .then((response) => {
      dispatch(appStatusChange.loadOpacityLoader(false));
      dispatch(uploadPropertyDocsSuccess());
      message.success(response?.data?.info?.message || "Folder Deleted Successfully.");
    })
    .catch((error) => {
      dispatch(appStatusChange.loadOpacityLoader(false));
      message.error(error?.response?.data?.message || "We encountered an issue while deleting the folder. Please try again later!");
    })
  }
}

export const PropertyHandlers = {
    getPropertyData,
    getTasks,
    addEmail,
    setSelectedPropertyData,
    getPropertyDocsFun,
    getPreDoraDocumentsData,
    uploadPropertyDocsFun,
    sharePropertyDocs,
    getNeedToknow,
    uploadNeedToknow,
    removeNeedToknow,
    getMlsConstants,
    linkMlsRequest,
    handleDelinkPropertyModal,
    updateTasks,
    addCustomTask,
    deleteCustomTask,
    urlShorthandApiCalling,
    postPropertyImage,
    deletePropertyImage,
    editSellers,
    offerTableUrlShorthandApiCalling,
    editCustomTask,
    deleteDraftOfferFun,
    deleteListing,
    changeFilterSort,
    resetFilterSearch,
    removePropertyDocsFun,
    SendMultipleDoc,
  porstStatusChangeFunc,
   resetPropertyDocs,
    uploadPdfDocumentFunc,
    deletePropertyContractDocs,
  toggleDocumentInTransactionDashboard,
  getAgentDetails,
  editAgents,
  setAgentDetailsData,
  getIfContactIsDeletedOrArchived,
  getOwnersInfo,
  createFolderFun,
  updatePropertyDocsFolderFun,
  getPropertyDocsFolderFun,
  deletePropertyDocFolder,
  getMoveToFolderFun,
  moveToFolderFun,
  resetFolderOpenState,
  toggleFolderOpenState
}
