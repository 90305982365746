import React from "react";
import { Button, Collapse, Dropdown } from "antd";
import { FolderFilled, FolderOpenOutlined, MoreOutlined } from "@ant-design/icons";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import SubFolderFile from "./SubFolderFile";
import { PropertyHandlers } from "../state/actions";
import { VscNewFolder } from "react-icons/vsc";
import ProperyDocMenuItem from "./ProperyDocMenuItem";

const RecursiveFolder = ({
  item,
  getCoordinateItem,
  index,
  level = 0,
  role,
  onAddSubFolder,
  onDeleteItem,
  handleDrop,
  handleDragOver,
  handleOPenDocs,
  handlePropertyDocs,
  handleShareUnshareFileConfirm,
  handleToggleDashboard,
  handleDeletePropertyDocs,
  isSmallScreen,
  propertyDocsWithType,
  setPropertyDocsWithType,
  folderName,
  setFolderName,
  delegate,
  setCreateFolderModalOpen,
  createFolderModalOpen,
  agentDetails,
  setSelectedFiles,
  selectedFiles,
  hoveredRow,
  setHoveredRow,
  handleSelectFile,
  handleDragStart,
  isInsideCustomerFolder,
  parentFolderId,
  handleCreateAmendExtend,
}) => {
  const dispatch = useDispatch();
  const folderOpenState = useSelector((state) => state.PropertyReducer.folderOpenState, shallowEqual);

  const isOpen = folderOpenState?.[item._id];
  // console.log("folderOpenState", folderOpenState, "item", item._id, "isOpen", isOpen);
  // Toggle folder state
  const handleFolderToggle = (e) => {
    e.stopPropagation();
    dispatch(PropertyHandlers.toggleFolderOpenState(item._id));
  };

  // Render file or folder content
  const renderContent = (ele) => {
    if (ele.storageType?.toLowerCase() === "file") {
      return (
        <div key={ele?._id} style={{ ...(level !== 1 && { borderLeft: "2px solid #aeaeae" }), margin: level === 1 ? "0 0 0 0" : "0 0 0 10px" }}>
          <hr
            style={{
              // width: "100%",
              width: level === 1 ? "calc(100% - 35px)" : "calc(100% - 40px)",
              color: "#BFBFBF",
              margin: level === 1 ? "0 0 0 30px" : "0 0 0 35px",
              border: "none",
              borderTop: "1px solid #BFBFBF",
            }}
          />
          {/* {<hr style={{ width: "calc(100% - 40px)", color: "#BFBFBF", margin: level !== 1 ? "0 0 0 35px" : "0 0 0 25px", border: "none", borderTop: "1px solid #BFBFBF" }} />} */}
          <SubFolderFile
            key={ele?._id}
            eachDoc={ele}
            handleDragStart={handleDragStart}
            isInsideCustomerFolder={isInsideCustomerFolder}
            parentFolderId={parentFolderId}
            level={level}
            getCoordinateItem={getCoordinateItem}
            index={index}
            propertyDocsWithType={propertyDocsWithType}
            setPropertyDocsWithType={setPropertyDocsWithType}
            role={role}
            handleOPenDocs={handleOPenDocs}
            handlePropertyDocs={handlePropertyDocs}
            handleShareUnshareFileConfirm={handleShareUnshareFileConfirm}
            handleToggleDashboard={handleToggleDashboard}
            handleDeletePropertyDocs={handleDeletePropertyDocs}
            isSmallScreen={isSmallScreen}
            folderName={folderName}
            setFolderName={setFolderName}
            handleDragOver={handleDragOver}
            handleDrop={handleDrop}
            delegate={delegate}
            setCreateFolderModalOpen={setCreateFolderModalOpen}
            createFolderModalOpen={createFolderModalOpen}
            agentDetails={agentDetails}
            selectedFiles={selectedFiles}
            setSelectedFiles={setSelectedFiles}
            hoveredRow={hoveredRow}
            setHoveredRow={setHoveredRow}
            handleSelectFile={handleSelectFile}
            handleCreateAmendExtend={handleCreateAmendExtend}
          />
        </div>
      );
    }

    return (
      <div key={ele._id} style={{ marginTop: level < 1 ? "0px" : "-5px", padding: "0px" }}>
        <Collapse bordered={false} expandIcon={({ isActive }) => (isActive ? <FolderOpenOutlined style={{ fontSize: "18px", marginLeft: "-8px", marginRight: "5px" }} /> : <FolderFilled style={{ fontSize: "18px", marginLeft: "-8px", color: "#6b98c7" }} />)} activeKey={isOpen ? [ele._id] : []} style={{ marginLeft: `${level * 15}px` }}>
          <Collapse.Panel
            key={ele._id}
            header={
              <div style={{ display: "flex", alignItems: "center", maxWidth: "360px", marginLeft: isOpen ? "0px" : "-8px" }}>
                <span style={{ fontSize: "18px", textTransform: "capitalize" }}>{ele.name}</span>
                {level < 1 && !ele?.isCustomerFolder && (
                  <Button
                    type="text"
                    icon={
                      <VscNewFolder
                        style={{
                          fontSize: isSmallScreen ? "14px" : "16px",
                          color: "rgb(8,81,145)",
                          marginTop: "6px",
                        }}
                      />
                    }
                    onClick={(e) => {
                      e.stopPropagation();
                      onAddSubFolder(ele._id);
                    }}
                    style={{ color: "#085191" }}
                  />
                )}
              </div>
            }
            onDrop={(e) => handleDrop(e, ele)}
            onDragOver={handleDragOver}
            extra={
              !ele.isCustomerFolder &&
              !ele.nestedItems?.length && (
                <div onClick={(e) => e.stopPropagation()}>
                  <Dropdown overlay={<ProperyDocMenuItem parentDoc={ele} getCoordinateItem={getCoordinateItem} onDeleteItem={onDeleteItem} />} trigger={["hover", "click"]}>
                    <MoreOutlined
                      key="ellipsis"
                      style={{
                        fontSize: "26px",
                        color: "#08c",
                        marginTop: "-28px",
                      }}
                    />
                  </Dropdown>
                </div>
              )
            }
            onClick={handleFolderToggle}
          >
            {isOpen && !ele?.nestedItems?.length ? (
              <div></div>
            ) : (
              ele?.nestedItems?.map((nestedItem, nestedIndex) => (
                <RecursiveFolder
                  key={nestedItem._id}
                  isInsideCustomerFolder={ele?.isCustomerFolder}
                  parentFolderId={ele?._id}
                  handleDragStart={handleDragStart}
                  item={nestedItem}
                  getCoordinateItem={getCoordinateItem}
                  index={nestedIndex}
                  level={level + 1}
                  role={role}
                  onAddSubFolder={onAddSubFolder}
                  onDeleteItem={onDeleteItem}
                  handleDrop={handleDrop}
                  handleDragOver={handleDragOver}
                  handleOPenDocs={handleOPenDocs}
                  handlePropertyDocs={handlePropertyDocs}
                  handleShareUnshareFileConfirm={handleShareUnshareFileConfirm}
                  handleToggleDashboard={handleToggleDashboard}
                  handleDeletePropertyDocs={handleDeletePropertyDocs}
                  isSmallScreen={isSmallScreen}
                  propertyDocsWithType={propertyDocsWithType}
                  setPropertyDocsWithType={setPropertyDocsWithType}
                  folderName={folderName}
                  setFolderName={setFolderName}
                  delegate={delegate}
                  setCreateFolderModalOpen={setCreateFolderModalOpen}
                  createFolderModalOpen={createFolderModalOpen}
                  agentDetails={agentDetails}
                  selectedFiles={selectedFiles}
                  setSelectedFiles={setSelectedFiles}
                  hoveredRow={hoveredRow}
                  setHoveredRow={setHoveredRow}
                  handleSelectFile={handleSelectFile}
                  documents={ele?.nestedItems}
                  handleCreateAmendExtend={handleCreateAmendExtend}
                />
              ))
            )}
          </Collapse.Panel>
        </Collapse>
      </div>
    );
  };

  return renderContent(item);
};

export default RecursiveFolder;
