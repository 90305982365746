import { Button, Input, Modal, message } from "antd";
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import CreateDocs from "../../PropertyComp/components/CreateDocs";
import { EmptyData } from "../../Common/components/EmptyData";
import { toolsAction } from "../state/actions";
import DocumentSetDisplay from "./DocumentSetDisplay";
import { Loading } from "../../Common/components/Loading";
import { useRef } from "react";

const DocumentSets = () => {
  const [isModalOpen, setIsModalOpen] = useState({
    modalOpen: false,
    update: false,
    addToId: "",
  });
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [documentSetName, setDocumentSetName] = useState("");
  const [documentSets, setDocumentSets] = useState([]);
  const [page, setPage] = useState(1);
  // const containerRef = useRef(null);

  const dispatch = useDispatch();

  const { documentSetLoading, documentSetData, isRerender } = useSelector((state) => state.ToolReducers.DocSetReducer);

  let unmounted = {
    value: false,
  };
  let sourceAxios = axios.CancelToken.source();

  useEffect(() => {
    dispatch(toolsAction.getDocSetData({ unmounted, sourceAxios, page }));

    return () => {
      unmounted.value = true;
      sourceAxios.cancel("Cancelling in cleanup");
    };
  }, [dispatch, isRerender, page]);

  const handleSaveDocumentSet = () => {
    if (documentSetName?.trim() === "") {
      message.error("Please enter a name for the document set");
      return;
    }

    if (selectedFiles?.length === 0) {
      message.error("Please select at least one document");
      return;
    }

    let urlData = {
      title: documentSetName,
      documentData: [...documentSets],
      ...(isModalOpen?.update ? {folderId:isModalOpen?.addToId} : {}),
    };

    dispatch(toolsAction.postdocSetData({ data: urlData }));

    setIsModalOpen({...isModalOpen, addToId:"", update:false, modalOpen:false});
    setDocumentSets([]);
    setSelectedFiles([]);
    setDocumentSetName("");
  };

  // const handleScroll = () => {
  //   if (containerRef.current) {
  //     const { scrollTop, scrollHeight, clientHeight } = containerRef.current;

  //     if (scrollTop + clientHeight >= scrollHeight - 50 && !documentSetLoading) {
  //       setPage((prevPage) => prevPage + 1);
  //     }
  //   }
  // };

  return (
    <div style={{ height: "calc(100vh - 8rem)", overflowY: "auto", overflowX: "hidden" }}>
      {isModalOpen?.modalOpen && (
        <Modal
          width={"700px"}
          onCancel={() => {
            setIsModalOpen({...isModalOpen,addToId:"",update:false,modalOpen:false});
            dispatch(toolsAction.resetDocTemplateData());
          }}
          open={isModalOpen?.modalOpen}
          footer={false}
          title={<span style={{ display: "flex", justifyContent: "center", color: "#1990ff", fontSize: "22px", fontWeight: "bold" }}>{isModalOpen?.update ? "Add to" : "Create"} Document Set</span>}
        >
          <div
            style={{
              height: selectedFiles?.length > 0 || isModalOpen?.update ? "70vh" : "65vh",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "-10px",
            }}
          >
            <CreateDocs role={"ALL_DORA_DOCS"} renderFrom={"tools"} selectedFiles={selectedFiles} setSelectedFiles={setSelectedFiles} isEditing={isModalOpen?.update} documentSets={documentSets} setDocumentSets={setDocumentSets} />
            {(documentSets?.length > 0 || isModalOpen?.update) && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  width: "300px",
                  gap: "10px",
                  marginTop: "-50px",
                }}
              >
                <Input placeholder="Enter the document set name" disabled={isModalOpen?.update} value={documentSetName} onChange={(e) => setDocumentSetName(e.target.value)} />
                <Button type="primary" onClick={handleSaveDocumentSet} disabled={!documentSetName?.length || !documentSets?.length}>
                  Save
                </Button>
              </div>
            )}
          </div>
        </Modal>
      )}

      {documentSetLoading ? (
        <Loading />
      ) : (
        <div style={{ padding: "20px 0" }}>
          <Button
            type="primary"
            onClick={() => {
              setIsModalOpen({ ...isModalOpen, modalOpen: true });
              setDocumentSets([]);
              setSelectedFiles([]);
              setDocumentSetName("");
            }}
            style={{ marginBottom: "20px" }}
          >
            Create Document Set
          </Button>
          {documentSetData?.length > 0 ? <DocumentSetDisplay documentSetData={documentSetData} setIsModalOpen={setIsModalOpen} isModalOpen={isModalOpen} setDocumentSets={setDocumentSets} setDocumentSetName={setDocumentSetName} setSelectedFiles={setSelectedFiles} selectedFiles={selectedFiles} /> : <EmptyData />}
        </div>
      )}
    </div>
  );
};

export default DocumentSets;
