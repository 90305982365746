import React, { useEffect, useRef, useState } from "react";
import {
  DeleteTwoTone,
  EditTwoTone,
  PlusCircleFilled,
} from "@ant-design/icons";
import { Button, Form, Input, message, Modal, Popconfirm, Row, Tabs, Tooltip, Typography } from "antd";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { toolsAction } from "../state/actions";
import { EmptyData } from "../../Common/components/EmptyData";
import { Loading } from "../../Common/components/Loading";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import TabPane from "antd/lib/tabs/TabPane";
import { useLocation } from "react-router-dom";
import { DocsClauseModal } from "./DocsClauseModal";
import {CaretRightOutlined,} from "@ant-design/icons"
import "react-quill/dist/quill.snow.css";
import CustumToolbar, { formats,modules } from "./custumToolbar";
import styles from "../styles/Tool.module.css"
import axios from "axios";
const EMAIL_TEMPLATE = () => {

  const { getEmail_TemplateData, getEmail_TemplateLoading, updateData, getEmail_TemplatesError, getEmail_TemplateErrorMsg } = useSelector(
    (state) => state.ToolReducers.DocumentReducer,
    shallowEqual
  );
  const [visible, setVisble] = useState(false);
  const location = useLocation();

  const pathname = location.pathname.split("/").pop();
  const dispatch = useDispatch();
  const [Email_templateCount, setEmail_templateCount] = useState(8);
  const [showModal, setShowModal] = useState(false);
  const [selectedData, setSelectedData] = useState(getEmail_TemplateData[0]);
  const [componantDidmount, setcomponantDidmount] = useState(false);
  const [isPanelCollapsed, setIsPanelCollapsed] = useState(false);
  const [title, setTitle] = useState(" ");
  const [description, setDescription] = useState(" ");
  const [Id,setId]=useState("")
  const inputRef = useRef();
  const handleEdit = (el) => {
    setTitle(el?.title)
    setId(el?._id)
    setSelectedData(el);
    setShowModal(true);
    setTimeout(() => {
        setDescription(el?.name);
    }, 100);
  };

  let handleLoadMore = () => {
    setEmail_templateCount(Email_templateCount + 6);
  };

  let unmounted = {
    value: false
  };
  let source = axios.CancelToken.source();
  useEffect(() => {
    dispatch(toolsAction.getEmail_Template({unmounted, source}));

    return () => {
      unmounted.value = true;
      source.cancel("Cancelling in cleanup");
    }
  }, [updateData]);

  const handleDelete = (id) => {
    dispatch(toolsAction.deleteDocsClause(id, "Email_template"));
  };
  const onFinish = (e) => {
    e.preventDefault()
    if(selectedData?.title.length > 0 && selectedData.name !== "<p><br></p>"){
      dispatch(toolsAction.updateDocsClause(selectedData, "Email_template"));
        setSelectedData(" ")
      setShowModal(false);
      }else{
      message.error("Please fill all the fields")
    }
  };

  const handleTitleChange = (e,id) => {
    const newTitle = e.target.value;
  setTitle(newTitle);
  setId(id)
   setSelectedData({ ...selectedData, title:e.target.value,_id:Id })
  };

  const handleQuillDescriptionChange = (value) => {
    setDescription(value)
   setSelectedData({...selectedData,name:value});
  };
  const onFinish2 = (e) => {
    e.preventDefault();
    let payload = {
      title: title,
      name: description,

      type:
        pathname === "documents"
          ? "DOCUMENT"
          : pathname === "email_template"
          ? "EMAIL_TEMPLATE"
          : "CLAUSE",
    };
    dispatch(toolsAction.addDocAndClause(payload));
    setVisble(false);
  };


  const quillRef = useRef();

  const handleInsertFirstName = () => {
  const quill = quillRef.current.getEditor();
  let cursorPosition
  if( quill.getSelection())
  {
   cursorPosition = quill?.getSelection()?.index;
  }
  //Check if cursorPosition is defined before inserting text
  if (cursorPosition !== undefined) {
    quill.insertText(cursorPosition, "${CUST_F_NAME}");
  }
};

  const handleInsertLastName = () => {
    const quill = quillRef.current.getEditor();
let cursorPosition
  if( quill.getSelection())
  {
   cursorPosition = quill?.getSelection()?.index;
  }
 // Insert the desired text at the cursor position
    quill.insertText(cursorPosition, "${CUST_L_NAME}");

  };

  const handleInsertPropertyAddress = () => {
    const quill = quillRef.current.getEditor();
   let cursorPosition
  if( quill.getSelection())
  {
   cursorPosition = quill?.getSelection()?.index;
  }
  // Insert the desired text at the cursor position
    quill.insertText(cursorPosition, "${PROP_ADDRESS}");

  };

    const handleInsertDate = () => {
    const quill = quillRef.current.getEditor();
   let cursorPosition
  if( quill.getSelection())
  {
   cursorPosition = quill?.getSelection()?.index;
  }
   // Insert the desired text at the cursor position
    quill.insertText(cursorPosition, "${CURR_DATE}");
  };
  const handleInsertTime = () => {
    const quill = quillRef.current.getEditor();
let cursorPosition
  if( quill.getSelection())
  {
   cursorPosition = quill?.getSelection()?.index;
  }
// Insert the desired text at the cursor position
    quill.insertText(cursorPosition, "${CURR_TIME}");

  };
    const handleInsertMiddleName = () => {
    const quill = quillRef.current.getEditor();
    let cursorPosition
    if( quill.getSelection())
     {
     cursorPosition = quill?.getSelection()?.index;
      }
  // Insert the desired text at the cursor position
    quill.insertText(cursorPosition, "${CUST_M_NAME}");

  };

  const handleButtonClick = () => {
    setIsPanelCollapsed(!isPanelCollapsed);
  };



  useEffect(() => {
    if (showModal || visible) {
      const focusTimeout = setTimeout(() => {
        inputRef.current && inputRef.current.focus();
      },100);

      return () => {
        clearTimeout(focusTimeout);
      };
    }
  }, [showModal, visible]);

  useEffect(() => {
    if(showModal===true){
        setcomponantDidmount(true);
    }
    return () => {
      setcomponantDidmount(false);
    }
  }, [showModal])


  return (
    <>
      <DocsClauseModal
        visible={visible}
        setVisble={setVisble}
        pathname={pathname}
  />

      <Tabs
        defaultActiveKey={1}
        activeKey={1}
        // defaultActiveKey="1"
        tabBarExtraContent={
          <Button
            shape="circle"
            style={{
              //   border: "1px solid #085191",
              marginLeft: "-5rem",
              paddingTop: "0px",
              //   color: "#085191",
            }}
            icon={
              <PlusCircleFilled
                style={{
                  fontSize: "30px",
                  color: "#085191",
                }}
                onClick={() => setVisble(true)}
              />
            }
          ></Button>
        }
      >
        <TabPane
          tab={
            <span
              className="mozFontSize"
              style={{
                fontSize: "1.2rem",
              }}
            >
              EMAIL TEMPLATE
            </span>
          }
        >
          <EMAIL_TEMPLATE />
        </TabPane>
      </Tabs>

      <Modal
        visible={showModal}
        title={
          <Typography.Text style={{ fontSize: "25px", marginLeft:"20px", color: "grey" }}>
           Update Email Template
        </Typography.Text>
        }
        onOk={() => {}}
        footer={false}
        onCancel={() => {
          setShowModal(false);
        }}
      >
        <Row justify="center">
          <Input
            ref={inputRef}
            name="title"
            autoFocus={true}
            style={{
              marginBottom: "20px",
              fontWeight:"bold"
            }}
            rows="2"
            cols="60"
            value={title}
            onChange={(e)=>handleTitleChange(e,selectedData?._id)}
          ></Input>
        <button
        type="button"
        style={{backgroundColor:"white",width:"33.3%",border:"1px solid grey",fontWeight:"600"}}
        onClick={handleInsertFirstName}
        >
        First Name
        </button>
        <button
         type="button"
         style={{backgroundColor:"white",width:"33.3%",border:"1px solid grey",fontWeight:"600"}}
         onClick={handleInsertLastName}
         >
         Last Name
         </button>
         <button
        type="button"
       style={{backgroundColor:"white",width:"33.3%",border:"1px solid grey",fontWeight:"600"}}
      onClick={handleInsertMiddleName}
      >
      Middle Name
      </button>
        <button
        type="button"
       style={{backgroundColor:"white",width:"33.3%",border:"1px solid grey",fontWeight:"600"}}
        onClick={handleInsertPropertyAddress}
        >
        Property Address
        </button>

     <button
     type="button"
     style={{backgroundColor:"white",width:"33.3%",border:"1px solid grey",fontWeight:"600"}}
    onClick={handleInsertDate}
    >
    Date
    </button>
    <button
     type="button"
     style={{backgroundColor:"white",width:"33.3%",border:"1px solid grey",fontWeight:"600"}}
    onClick={handleInsertTime}
    >
    Time
    </button>
    {componantDidmount &&
        <div id="createTemp" style={{width:"100%",height:"300px"}}>
           <span style={{display:"flex",justifyContent:"space-between"}}>

            <div><p style={{fontWeight:"bold",marginTop:"5px",color:"grey"}}>Edit Your Template Here.</p></div>
            <Tooltip title="Editor Toolbar" trigger={['hover','click']}>
            <Button
                    style={{border:"none",marginRight:"10px"}}
                    icon={<CaretRightOutlined style={{fontSize:"25px",color:"#085191"}} rotate={isPanelCollapsed ? 90 : 0} />}
                    onClick={handleButtonClick}
                 />
            </Tooltip>
            </span>
            <div style={isPanelCollapsed ? {display:"block"}:{display:"none"}}>
                <CustumToolbar toolbarId={'editTemp'} />
            </div>
            <ReactQuill
                className="editTemplate"
                value={description}
                modules={modules}
                formats={formats}
                style={{ height: "200px", marginBottom: "100px", maxWidth: "500px" }}
                ref={quillRef}
                onChange={handleQuillDescriptionChange}
            />
        </div>
    }

          <Button
            type="primary"
            onClick={onFinish}
            style={{ marginTop: "20px" }}
            size="large"
          >
            Save
          </Button>
        </Row>
      </Modal>
      {getEmail_TemplateLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            marginTop: "70px",
          }}
        >
          <Loading />
        </div>
      ) : getEmail_TemplatesError ? "" : (
        <div
          style={{
           overflowY: "auto",
            overflowX: "hidden",
            backgroundColor: "#fff",
            position: "relative",
            zIndex: "30",
            marginTop: "20px",
          }}
          className={styles.clauselist}
          onScroll={(e) => {
            if (
              Math.round(
                e.target.scrollHeight - Math.round(e.target.scrollTop)
              ) < 800
            ) {
              handleLoadMore();
            }
          }}
        >
          {getEmail_TemplateData?.length > 0 ? (
            <table
              className="licenseTable"
              style={{
                width: "90%",
                marginTop: "-5px",
              }}
            >
              <thead
                style={{
                  position: "sticky",
                  top: -2,
                  left: "0px",
                  zIndex: 100,
                  height: "50px",
                }}
              >
                <tr>
                  <th>Title</th>
                  <th>Description</th>
                  <th>Edit</th>
                  <th>Delete</th>
                </tr>
              </thead>
              <tbody>
                {getEmail_TemplateData?.map((el, i) => (
                  <tr key={el._id} style={{ marginTop: "20px" }}>
                    <td
                      style={{
                        fontWeight: "bolder",
                        width: "20%",
                        textAlign: "center",
                      }}
                    >
                      {el.title}
                    </td>
                    <td
                      style={{
                        padding: "5px",
                        overflowX: "hidden",
                        backgroundColor: "#fff",
                        position: "relative",
                        zIndex: "30",
                        marginTop: "20px",
                        maxWidth: "400px",

                        width: "60%",
                      }}
                      nowrap
                    >
                      <div
                        dangerouslySetInnerHTML={{ __html: el.name }}
                        style={{
                          textOverflow: "ellipsis",
                          overflowY: "auto",
                          maxHeight: "200px",
                        }}
                      />
                    </td>

                    <td
                      onClick={() => handleEdit(el)}
                      style={{
                        color: "#178DFA",
                        fontWeight: "bolder",
                        textAlign: "center",
                        cursor: "pointer",
                        width: "8%",
                      }}
                    >
                      <EditTwoTone />
                    </td>
                    <td
                      style={{
                        color: "#DC143C",
                        textAlign: "center",
                        cursor: "pointer",
                        width: "8%",
                      }}
                    >
                      <Popconfirm
                        title={<p style={{width:"250px"}}>Are you sure you want to delete this Email Template?</p>}
                        onConfirm={() => handleDelete(el._id)}
                        okText="Yes"
                        cancelText="No"
                      >
                        <DeleteTwoTone />
                      </Popconfirm>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "80%",
                textAlign: "center",
              }}
            >
              <EmptyData />
            </div>
          )}
        </div>
      )}
    </>
  );
};
export { EMAIL_TEMPLATE };
