import React, { useState } from "react";
import { Drawer, Form, Button, Col, Row, Input, message, Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { documentAction } from "../state/actions";
import { SuccessForm } from "../../Common/components/SuccessForm";
import { useEffect } from "react";
import styles from "../style/DocumentSection.module.css";
import PhoneInput from "react-phone-input-2";
import { useRef } from "react";
import { capitalizeFirst, capitalizeName } from "../../Common/utils/extraFunctions";
import { SomeThingWentWrong } from "../../Common/components/SomeThingWentWrong";

const tailLayout = {
  wrapperCol: {
    offset: 8,
    span: 16,
  },
};

function CreateCustomerDrawer({ visible, onClose, nameData, handleCreateRender, delegateOwnerId, selectedCustomer, section, checkReopen }) {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const inputRef = useRef()
  const OPTIONS = ["MORTGAGE","TITLE REP","CUSTOMER","BUYER","SELLER","STAGER","PHOTOGRAPHER","PROPERTY INSPECTOR"];

  const [selectedItems, setSelectedItems] = useState([]);

  const filteredOptions = OPTIONS.filter((o) => !selectedItems.includes(o));


  const { createCustomerLoading, createCustomerError } = useSelector(
    (state) => state.documentSection.createCustomerReducer
  );
  let closeDrwaer = () => {
    onReset();
    onClose();
    dispatch(documentAction.clearSuccessDataUserCreate());
  };

  const onFinish = (values) => {
    dispatch(documentAction.createCustomer({data: values, handleCreateRender, delegateOwnerId, section, selectedCustomer}));
    // dispatch(clientAction.updatePhone(values));
  };

  const onReset = () => {
    form.resetFields();
  };

  // useEffect(() => {
  //   if (createCustomerError) {
  //     // message.error(JSON.parse(createCustomerError)?.message);
  //     // dispatch(documentAction.clearCustomerData());
  //   }
  // }, [dispatch, createCustomerError]);

  useEffect(() => {
    if (visible) {
      const focusTimeout = setTimeout(() => {
        inputRef.current && inputRef.current.focus();
      },100);

      return () => {
        clearTimeout(focusTimeout);
      };
    }
  }, [visible]);

  // Prefill for SAFETY_DRIVE section
  useEffect(() => {
    if (section === 'SAFETY_DRIVE' && selectedCustomer) {
      form.setFieldsValue({
        firstName: selectedCustomer?.firstName || '',
        middleName: selectedCustomer?.middleName || '',
        lastName: selectedCustomer?.lastName || '',
      });
    }
  }, [section, selectedCustomer, form]);

  return (
    <Drawer
      width={720}
      onClose={closeDrwaer}
      visible={visible}
      bodyStyle={{ padding: "20px" }}
    >
      <div className={styles.createtitle} style={{ color: "#8C8C8C" }}>
        Create a new {nameData}
      </div>
      {createCustomerLoading ? (
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          className={styles.formcreate}
        >
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="firstName"
                label="First Name"
                className="extraDrawer"
                rules={[
                  { required: true, message: "Please Enter First Name!" },
                ]}
              >
                <Input ref={inputRef} placeholder="Enter First Name" allowClear autoFocus/>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="middleName"
                label="Middle Name"
                className="extraDrawer"
              >
                <Input placeholder="Enter Middle Name" allowClear />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="lastName"
                label="Last Name"
                className="extraDrawer"
                rules={[{ required: true, message: "Please Enter Last Name!" }]}
              >
                <Input placeholder="Enter Last Name" allowClear />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="email"
                label="Email"
                className="extraDrawer"
                hasFeedback
                rules={[
                  {
                    type: "email",
                    message: "Not a valid Email!",
                  },
                  {
                    required: true,
                    message: "Please Enter Email!",
                  },
                  { whitespace: true },
                ]}
              >
                <Input placeholder="Enter Email" allowClear />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                name="phoneNumber"
                label="Phone Number"
                className="extraDrawer"
                // rules={[
                //   ({ getFieldValue }) => ({
                //     validator(_, value) {
                //       if (value?.length) {
                //         if (value?.length === 10) {
                //           return Promise.resolve();
                //         }
                //         return Promise.reject(
                //           new Error("Please Enter 10 digit Phone Number!")
                //         );
                //       } else {
                //         return Promise.resolve();
                //       }
                //     },
                //   }),
                // ]}
              >
                <PhoneInput
                  inputStyle={{
                    width: "100%",
                  }}
                  inputClass="phoneInput"
                  country={"us"}
                  onlyCountries={["us"]}
                  maxLength="10"
                  international={false}
                  disableCountryCode={true}
                  placeholder="Enter Phone Number"
                />
               
             
              </Form.Item>
            </Col>
            <Col span={12}>
            <Form.Item
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                name="roleHashTags"
                label="Tag"
                className="extraDrawer"
              >
              <Select
                    mode="multiple"
                    placeholder={<span style={{fontWeight: "initial"}}>Select Tag</span>}
                    value={selectedItems}
                    onChange={setSelectedItems}
                    style={{ width: '100%' }}
                    options={filteredOptions.map((item) => ({
                      value: item,
                      label: capitalizeFirst(item),
                    }))}
                  />
              </Form.Item>
                  </Col>
          </Row>
          
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item name="notes" label="Notes" className="extraDrawer">
                <Input.TextArea placeholder="Add Notes" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item {...tailLayout}>
                <Button
                  type="primary"
                  style={{ marginRight: "20px" }}
                  htmlType="submit"
                >
                  Save
                </Button>
                <Button htmlType="button" onClick={onReset}>
                  Reset
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      ) : createCustomerError ? (
        <SomeThingWentWrong
          status={"error"}
          title={JSON.parse(createCustomerError)?.message || ""}
          subTitle={""} 
          buttonText={"Back"}
          onBackButtonclick={() => {
            closeDrwaer();
            dispatch(documentAction.clearCustomerData());
          }}
        />
      ) :(
        <SuccessForm
          title={"Contact successfully created!"}
          subTitle={""}
          flag={true}
          backEvent={closeDrwaer}
        />
      )}
    </Drawer>
  );
}

export { CreateCustomerDrawer };
