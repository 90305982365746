import { toolsConstants } from "../action-types";

const initialState = {
  documentSetLoading: false,
  documentSetData: [],
  documentSetError: false,
  isRerender: false,

  docSetTemplateLoading: false,
  docSetTemplateError: false,
  docSetTemplateData: [],

  openDocFromDocumentSet: false,
};

const DocSetReducer = (state = initialState, action) => {
  switch (action.type) {
    case toolsConstants.GET_DOC_SET_LOADING:
      return {
        ...state,
        documentSetLoading: true,
        documentSetError: false,
        documentSetData: [],
      }
    case toolsConstants.GET_DOC_SET_ERROR:
      return {
        ...state,
        documentSetData: [],
        documentSetLoading: false,
        documentSetError: true,
      }
    case toolsConstants.GET_DOC_SET_SUCCESS:
      return {
        ...state,
        documentSetData: action.docData,
        documentSetLoading: false,
        documentSetError: false,
      }
    case toolsConstants.DOC_SET_API_CALL:
      return {
        ...state,
        isRerender: !state.isRerender
      }
    case toolsConstants.GET_TEMPLATE_DOC_SET_LOADING:
      return {
        ...state,
        docSetTemplateLoading:true,
        docSetTemplateError: false,
      }
      case toolsConstants.GET_TEMPLATE_DOC_SET_ERROR:
      return {
        ...state,
        docSetTemplateLoading:false,
        docSetTemplateError: true,
      }
      case toolsConstants.GET_TEMPLATE_DOC_SET_SUCCESS:
      return {
        ...state,
        docSetTemplateLoading:false,
        docSetTemplateData: [...state.docSetTemplateData, ...action.docSetTemplateData?.filter(
          (newItem) =>
            !state.docSetTemplateData?.some(
              (existingItem) => existingItem._id === newItem._id
            )
        )],
        docSetTemplateError: false,
      }
      case toolsConstants.RESET_TEMPLATE_DOC_SET:
        return {
          ...state,
          docSetTemplateLoading:false,
          docSetTemplateData: [],
          docSetTemplateError: false,
        }
      case toolsConstants.OPEN_DOC_FROM_DOC_SET:
        return {
          ...state,
          openDocFromDocumentSet: true
        }
    default:
      return state;
  }
};

export { DocSetReducer };
