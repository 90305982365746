import {
  Avatar,
  Button,
  Col,
  DatePicker,
  Form,
  Image,
  Input,
  Modal,
  Row,
  Switch,
  TimePicker,
  Checkbox
} from "antd";
import React, { useRef, useState,useEffect } from "react";
import styles from "../style/landingPage.module.css";
import home from "./house.jpg";
import {
  CloseCircleOutlined,
  ClockCircleOutlined,
  PlusCircleTwoTone,
  MinusCircleTwoTone,
  PlusCircleOutlined,
  MinusCircleOutlined,
  UserAddOutlined,
} from "@ant-design/icons";
import moment from "moment";
import { useHistory, useLocation } from "react-router-dom";
import { getCategoryDataHandle } from "../../PropertyComp/utils/helper";
import { useSelector } from "react-redux";

const ReminderModal = ({
  showModal,
  handleOk,
  handleCancel,
  defaultValues,
  typeOfCreation,
  reminderTypeNormal,
  currentClickedItem,
  form,
  setTypeOfCreation,
  setDefaultValues,
  // decrementDuration,
  selectedDuration,
  // incrementDuration,
  editCase,
  selectedClient,
  showDeallocateConfirm,
  reminderType,
  handleLinkClientToAvatar,
  section,
  isTaskComplete,
  setIsTaskComplete
}) => {
  const [selectedDate, setSelectedDate] = useState(null);
  const inputRef = useRef();
  const history = useHistory();
  const { pathname } = useLocation();
  let pathnme = pathname?.split("/");
  const { agentAndDelegates } = useSelector((state) => state.account);
  const toggleTaskCompletion = (checked) => {
    setIsTaskComplete(checked)
  };

  const handleDateChange = (date) => {
    setTypeOfCreation(true)
    setSelectedDate(date);
  };
  const decrementDuration = () => {
    setTypeOfCreation(true)
    if (defaultValues.timeInterval > 1 && editCase) {
      if(selectedDate){
        setDefaultValues({
           ...defaultValues,
           scheduleTimeArray:moment(selectedDate),
           timeInterval: defaultValues.timeInterval - 1,
         });
         }else{
         setDefaultValues({
           ...defaultValues,
        timeInterval: defaultValues.timeInterval - 1,
         }); 
       }
    }
    if (defaultValues.timeInterval > 1 && !editCase) {
      setDefaultValues({ timeInterval: defaultValues.timeInterval - 1 });
    }
  };

  const incrementDuration = () => {
    setTypeOfCreation(true)
    if (defaultValues.timeInterval < 16 && editCase) {
      if(selectedDate){
     setDefaultValues({
        ...defaultValues,
        scheduleTimeArray:moment(selectedDate),
        timeInterval: defaultValues.timeInterval + 1,
      });
      }else{
      setDefaultValues({
        ...defaultValues,
     timeInterval: defaultValues.timeInterval + 1,
      }); 
    }

}
    if (defaultValues.timeInterval < 16 && !editCase) {

      setDefaultValues({timeInterval: defaultValues.timeInterval + 1 });
    }
  };

  const handleRedirect = (item) => {
    const {category,categoryId, status}= getCategoryDataHandle({notificatonData:item});
    let persistCalendar = {
      section: "day",
      date: pathnme?.[2],
      agentIds: agentAndDelegates?.filter(ele=>ele.selected)?.map((ele)=>ele._id)
    }
    if(item?.isDelegateAccess){
      history.push({ pathname: "/account/delegates/transactions", state: { category, categoryId, status, ...(section !== "HOME" && {persistCalendar}) } });
    }
    else{
      history.push({ pathname: "/transactions", state: { category, categoryId, status, ...(section !== "HOME" && {persistCalendar})} });
    }
  }

  useEffect(() => {
    if (showModal) {
      setIsTaskComplete(currentClickedItem.completedDateMili !== null && currentClickedItem.completedDateMili !== undefined)
      const focusTimeout = setTimeout(() => {
        inputRef.current && inputRef.current.focus();
      },100);

      return () => {
        clearTimeout(focusTimeout); // Clear the timeout when the modal is closed
      };
    }
  }, [showModal]);

  return (
    <Modal
      visible={showModal}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={false}
      initialValues={defaultValues}
    >
      <div className={styles.createtitle} style={{ color: "#8C8C8C" }}>
        {reminderTypeNormal || currentClickedItem?.type === "SCHEDULE"
          ? "Task"
          : currentClickedItem?.title}
      </div>
      <Form form={form} onFinish={handleOk} layout="vertical">
        {currentClickedItem.type === "CONTRACT_REMINDER" || currentClickedItem.type === "CONTRACT_SCHEDULE" ? (
          <>
            <div
              style={{
                display: "flex",
                marginBottom: "15px",
                justifyContent: "space-between",
              }}
            >
              <div style={{ width: "20%" }}>
                {currentClickedItem?.taggedPersonIds?.[0]?.profileImg
                  ?.original ? (
                  <Avatar
                    size={67}
                    src={
                      currentClickedItem?.taggedPersonIds?.[0]?.profileImg
                        ?.original
                    }
                  />
                ) : currentClickedItem?.taggedPersonIds?.[0]?.firstName ? (
                  <Avatar size={67} style={{ background: "#085191" }}>
                    {(currentClickedItem?.taggedPersonIds?.[0]?.firstName
                      ? currentClickedItem?.taggedPersonIds?.[0].firstName[0]?.toUpperCase()
                      : "") +
                      "" +
                      (currentClickedItem?.taggedPersonIds?.[0]?.lastName
                        ? currentClickedItem?.taggedPersonIds?.[0].lastName[0]?.toUpperCase()
                        : "")}
                  </Avatar>
                ) : (
                  ""
                )}
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  textAlign: "center",
                  cursor:"pointer"
                }}
                onClick={() => handleRedirect(currentClickedItem)}
              >
                <div style={{ marginLeft: "-30px" }}>
                <Image
                    src={currentClickedItem?.propertyImages?.[0]?.imageUrl || home}
                    preview={false}
                    height={"70px"}
                    width={"150px"}
                    alt=""
                    style={{ objectFit: "contain" }}
                  />
                </div>

                <div>
                  <span style={{ color: "#4CAAFF", fontSize: "16px" }}>
                    {currentClickedItem?.fullAddress}
                  </span>
                </div>
              </div>
              <div
                style={{
                  width: "25%",
                  fontSize: "14px",
                  fontWeight: "bold",
                }}
              >
                {/* {defaultValues &&
                  defaultValues.allDates &&
                  defaultValues.allDates.map((dates, idx) => {
                    if (idx === 0) {
                      return (
                        <div
                          style={{
                            textAlign: "right",
                            fontSize: "16px",
                            fontWeight: "bold",
                          }}
                        >
                          {dates}
                        </div>
                      );
                    } else {
                      return (
                        <div
                          style={{
                            textDecoration: "line-through",
                            textDecorationColor: "red",
                          }}
                        >
                          {dates}
                        </div>
                      );
                    }
                  })} */}
                 {currentClickedItem?.startTimestamp && moment(currentClickedItem.startTimestamp).isValid() && moment(currentClickedItem.startTimestamp).format("MM/DD/YYYY")}
              </div>
            </div>

            <Form.Item
              className="lbl"
              name="title"
              label={currentClickedItem.type === "CONTRACT_REMINDER" || currentClickedItem.type === "CONTRACT_SCHEDULE" ? "Task" : "Title"}
              rules={[{ required: true, message: `Please Enter ${currentClickedItem.type === "CONTRACT_REMINDER" || currentClickedItem.type === "CONTRACT_SCHEDULE" ? "Task" : "Title"}!` }]}
            >
              <Input placeholder="" allowClear disabled={true} />
            </Form.Item>
            <Row gutter={16}>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Form.Item
                  className="lbl"
                  name="description"
                  label={currentClickedItem.type === "CONTRACT_REMINDER" || currentClickedItem.type === "CONTRACT_SCHEDULE" ? "Notes:" : "Description:"}
                >
                  <Input.TextArea placeholder={currentClickedItem.type === "CONTRACT_REMINDER" || currentClickedItem.type === "CONTRACT_SCHEDULE" ? "Add notes" : "Add description"} />
                </Form.Item>
              </Col>
            </Row>

            {/* <Row gutter={16}>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Form.Item
                  className="lbl"
                  name="type"
                  label={
                    !typeOfCreation ? (
                      <label>
                        <span style={{ color: "#1890ff" }}>Reminder</span>
                        <span> | Schedule</span>
                      </label>
                    ) : (
                      <label>
                        <span>Reminder | </span>
                        <span style={{ color: "#1890ff" }}>Schedule</span>
                      </label>
                    )
                  }
                >
                  <Switch
                    onChange={(e) => setTypeOfCreation(!typeOfCreation)}
                    checked={typeOfCreation}
                  />
                </Form.Item>
              </Col>
            </Row> */}

            {/* {!typeOfCreation && ( */}
              <Row gutter={16}>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Form.Item
                    className="lbl"
                    name="reminderDate"
                    label="Date"
                    // rules={[{ required: true }]}
                  >
                    <DatePicker
                      allowClear
                      style={{ width: "100%" }}
                      format="MM-DD-YYYY"
                    />
                  </Form.Item>
                </Col>
              </Row>
            {/* )} */}

            {/* {defaultValues?.scheduleTimeArray && ( */}
              <Row gutter={16}>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Form.Item
                    className="lbl"
                    name="scheduleTimeArray"
                    label={<span>Time Due<ClockCircleOutlined style={{marginLeft:"5px",color:"cornflowerblue"}}/></span>}
                  >
                    <TimePicker
                      style={{ width: "100%" }}
                      use12Hours
                      showNow={true}
                      format="h:mm A"
                      minuteStep={15}
                      onChange={handleDateChange}
                    />
                  </Form.Item>
                </Col>
              </Row>
            {/* )} */}

            {/* Mark Task as Complete Section */}
            <Row gutter={16}>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Form.Item
                  name="isTaskComplete"
                  // valuePropName="checked"
                >
                  <Checkbox
                    checked={isTaskComplete} 
                    onChange={(e) => toggleTaskCompletion(e.target.checked)} 
                  >
                  {isTaskComplete ? "Mark as InComplete" : "Mark as Complete"}
                  </Checkbox>
                </Form.Item>
              </Col>
            </Row>

            {moment?.isMoment(form?.getFieldValue("scheduleTimeArray")) && (
              <Row gutter={16}>
                <Col xs={9} sm={24} md={9} lg={9} xl={9}>
                  {defaultValues.timeInterval === 1 ? (
                    <MinusCircleOutlined
                      style={{ float: "right", fontSize: 26, marginTop: 42 }}
                    />
                  ) : (
                    <MinusCircleTwoTone
                      style={{ float: "right", fontSize: 26, marginTop: 42 }}
                      onClick={() => decrementDuration()}
                    />
                  )}
                </Col>
                <Col
                  xs={6}
                  sm={24}
                  md={6}
                  lg={6}
                  xl={6}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Form.Item
                    className="lbl"
                    name="timeInterval"
                    label="Duration"
                  >
                    <h4
                      style={{
                        fontSize: "22px",
                        color: "black",
                        display: "flex",
                        justifyContent: "center",
                        backgroundColor: "lightgray",
                        borderRadius: "6px",
                      }}
                    >
                      {selectedDuration()}
                    </h4>
                  </Form.Item>
                </Col>
                <Col xs={9} sm={24} md={9} lg={9} xl={9}>
                  {defaultValues.timeInterval === 16 ? (
                    <PlusCircleOutlined
                      style={{ float: "left", fontSize: 26, marginTop: 42 }}
                    />
                  ) : (
                    <PlusCircleTwoTone
                      onClick={() => incrementDuration()}
                      style={{ float: "left", fontSize: 26, marginTop: 42 }}
                    />
                  )}
                </Col>
              </Row>
            )}
            <Row
              gutter={16}
              justify="end"
              style={{
                marginTop: "30px",
              }}
            >
              <Col>
                <Button default onClick={() => handleCancel()}>
                  Cancel
                </Button>
              </Col>
              <Col>
                <Form.Item>
                  {/* {defaultValues &&
                  defaultValues.allDates &&
                  defaultValues.allDates.length &&
                  defaultValues.allDates[0] === "Deleted" ? (
                    <Button type="primary" disabled htmlType="submit">
                      {editCase ? "Save" : "Create"}
                    </Button>
                  ) : (
                  )} */}
                      <Button type="primary" htmlType="submit">
                      {editCase ? "Save" : "Create"}
                    </Button>
                </Form.Item>

              </Col>
            </Row>
          </>
        ) : (
          <>
            <Row
              gutter={(24, 24)}
              style={{
                display: "flex",
                justifyContent: "center",
                rowGap: "10px",
              }}
            >
              <>
                {selectedClient?.profileImg?.original ? (
                  <>
                    <Avatar
                      onClick={handleLinkClientToAvatar}
                      size={67}
                      className={styles.useravtar}
                      src={selectedClient.profileImg.original}
                    />
                    {currentClickedItem.type === "BIRTHDAY" ||
                    currentClickedItem.type === "CLIENT_EVENT" ? null : (
                      <div
                        style={{
                          marginLeft: -15,
                        }}
                        onClick={() => showDeallocateConfirm()}
                      >
                        <Avatar
                          size="small"
                          icon={
                            <CloseCircleOutlined style={{ color: "#1890ff" }} />
                          }
                        />
                      </div>
                    )}
                  </>
                ) : selectedClient?.firstName ? (
                  <>
                    <Avatar
                      size={67}
                      style={{ background: "#085191" }}
                      onClick={handleLinkClientToAvatar}
                    >
                      {(selectedClient?.firstName
                        ? selectedClient.firstName[0]?.toUpperCase()
                        : "") +
                        "" +
                        (selectedClient.lastName
                          ? selectedClient.lastName[0]?.toUpperCase()
                          : "")}
                    </Avatar>
                    {currentClickedItem.type === "BIRTHDAY" ||
                    currentClickedItem.type === "CLIENT_EVENT" ? null : (
                      <div
                        style={{
                          marginLeft: -15,
                        }}
                        onClick={() => showDeallocateConfirm()}
                      >
                        <Avatar
                          size="small"
                          icon={
                            <CloseCircleOutlined style={{ color: "#1890ff" }} />
                          }
                        />
                      </div>
                    )}
                  </>
                ) : (
                  <Avatar
                    shape="circle"
                    onClick={handleLinkClientToAvatar}
                    size={67}
                    style={{
                      background: "#085191",
                      fontSize: "24px",
                      display: "flex",
                      alignItems: "center",
                      height: "65px",
                    }}
                  >
                    <UserAddOutlined
                      style={{
                        fontSize: "24px",
                        fill: "#085191",
                        color: "white",
                      }}
                    />
                  </Avatar>
                )}
              </>
            </Row>

            <Row gutter={16}>
              <Col
                xs={24}
                sm={24}
                md={24}
                lg={24}
                xl={24}
                style={{ marginTop: "2rem" }}
              >
                <Form.Item
                  className="lbl"
                  name="title"
                  label="Title"
                  rules={[{ required: true, message: "Please Enter Title!" }]}
                >
                  <Input
                  ref={inputRef}
                    placeholder="Enter Title"
                    allowClear
                    autoFocus
                    disabled={
                      reminderType === "EVENT" ||
                      selectedClient?.type === "BIRTHDAY"
                    }
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Form.Item
                  className="lbl"
                  name="description"
                  label="Description"
                  rules={[
                    { required: true, message: "Please Enter description!" },
                  ]}
                >
                  <Input.TextArea
                    placeholder="Add description"
                    disabled={
                      reminderType === "EVENT" ||
                      selectedClient?.type === "BIRTHDAY"
                    }
                  />
                </Form.Item>
              </Col>
            </Row>

            {/* {!typeOfCreation && ( */}
              <Row gutter={16}>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Form.Item
                    className="lbl"
                    name="reminderDate"
                    label="Date"
                    rules={[{ required: true }]}
                  >
                    <DatePicker
                      allowClear
                      style={{ width: "100%" }}
                      format="MM-DD-YYYY"
                    />
                  </Form.Item>
                </Col>
              </Row>
            {/* )} */}

            {/* {typeOfCreation && ( */}
              <Row gutter={16}>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Form.Item
                    className="lbl"
                    name="scheduleTimeArray"
                    label={<span>Time Due<ClockCircleOutlined style={{marginLeft:"5px",color:"cornflowerblue"}}/></span>}
                  >
                   <TimePicker
                      style={{ width: "100%" }}
                      placeholder="Select Time Due"
                      use12Hours
                      showNow={true}
                      format="h:mm A"
                      minuteStep={15}
                      onChange={handleDateChange}
                    />
                  </Form.Item>
                </Col>
              </Row>
            {/* )} */}

            {moment?.isMoment(form?.getFieldValue("scheduleTimeArray")) && (
              <Row gutter={16}>
                <Col xs={9} sm={24} md={9} lg={9} xl={9}>
                  {defaultValues.timeInterval === 1 ? (
                    <MinusCircleOutlined
                      style={{ float: "right", fontSize: 26, marginTop: 42 }}
                    />
                  ) : (
                    <MinusCircleTwoTone
                      style={{ float: "right", fontSize: 26, marginTop: 42 }}
                      onClick={() => decrementDuration()}
                    />
                  )}
                </Col>
                <Col
                  xs={6}
                  sm={24}
                  md={6}
                  lg={6}
                  xl={6}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Form.Item
                    className="lbl"
                    name="timeInterval"
                    label="Duration"
                  >
                    <h4
                      style={{
                        fontSize: "22px",
                        color: "black",
                        display: "flex",
                        justifyContent: "center",
                        backgroundColor: "lightgray",
                        borderRadius: "6px",
                      }}
                    >
                      {selectedDuration()}
                    </h4>
                  </Form.Item>
                </Col>
                <Col xs={9} sm={24} md={9} lg={9} xl={9}>
                  {defaultValues.timeInterval === 16 ? (
                    <PlusCircleOutlined
                      style={{ float: "left", fontSize: 26, marginTop: 42 }}
                    />
                  ) : (
                    <PlusCircleTwoTone
                      onClick={() => incrementDuration()}
                      style={{ float: "left", fontSize: 26, marginTop: 42 }}
                    />
                  )}
                </Col>
              </Row>
            )}

            <Row gutter={16} justify="end">
              <Col>
                <Button default onClick={() => handleCancel()}>
                  Cancel
                </Button>
              </Col>
              <Col>
                <Form.Item>
                  <Button type="primary" htmlType="submit">
                    {editCase ? "Save" : "Create"}
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </>
        )}
      </Form>
    </Modal>
  );
};

export default ReminderModal;
