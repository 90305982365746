import { Col, Form, message, Modal, Select, Spin } from "antd";
import { Option } from "antd/lib/mentions";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const DocSet = ({ handleOnfocusChange, setOptionalIds, optionalIds, delegate, fieldEmpty }) => {
  const history = useHistory();
  const [showModal, setShowModal] = useState(false);
  const { documentSetData, documentSetLoading } = useSelector((state) => state.ToolReducers.DocSetReducer);
  const isDisabled = delegate && !fieldEmpty?.delegateBuyer?.id;
  const handleSelectClick = () => {
    if (delegate) {
      if (isDisabled) {
        message.error("Please select a buyer agent before selecting a document set.");
      }
      return;
    }
    if (!documentSetLoading && (!documentSetData || documentSetData?.length === 0)) {
      setShowModal(true);
    }
  };

  const handleModalCancel = () => {
    setShowModal(false);
  };

  const handleModalOk = () => {
    setShowModal(false);
    history.push("/tools/document_sets");
  };

  const handleSelectChange = (value) => {
    setOptionalIds({ ...optionalIds, documentSetId: value });
  };

  return (
    <Col xs={24} sm={24} md={24} lg={12} xl={12}>
      <Form.Item
        className="lbl"
        name="documentSet"
        label="Document Set"
        // onFocus={() => handleOnfocusChange({ documentSet: true })}
      >
        <Select showSearch placeholder="Document Set(Optional)" optionFilterProp="children" onChange={handleSelectChange} filterOption={(input, option) => option?.children?.toLowerCase()?.indexOf(input.toLowerCase()) >= 0} allowClear onClick={handleSelectClick} notFoundContent="">
          {documentSetLoading ? (
            <div style={{ minHeight: "20vh", display: "flex", textAlign: "center", justifyContent: "center", alignItems: "center" }}>
              <Spin size="medium" />
            </div>
          ) : (
            !isDisabled &&
            documentSetData?.map((ele, index) => (
              <Option key={index} value={ele?._id}>
                {ele?.name}
              </Option>
            ))
          )}
        </Select>
      </Form.Item>

      {showModal && (
        <Modal title={<div style={{ fontWeight: "bold", textAlign: "center", color: "#178DFA" }}>NO DOCUMENT SET FOUND</div>} open={showModal} onCancel={handleModalCancel} onOk={handleModalOk} okText="CREATE DOCUMENT SET" cancelText="CANCEL">
          <p>
            Looks like you don't have any document sets in your account. Please go to <span style={{ fontWeight: "bold" }}>Tools</span>, then click on <span style={{ fontWeight: "bold" }}>Documents Sets</span> menu. On the upper left hand corner click on the <span style={{ fontWeight: "bold" }}>Create Document Set</span> button.
          </p>
        </Modal>
      )}
    </Col>
  );
};

export default DocSet;
