import React from "react";
import CreatedDoraDocsModalContent from "./CreatedDoraDocsModalContent";
import { Card, Col, Modal, Row, Typography } from "antd";
import { MoreOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
const { Text, Title } = Typography;
const CreateDoraDocsModal = ({ isDoraDocsModalVisible, setIsDoraDocsModalVisible, selectedData, handleUploadNew, handleCreateNew, element = {} }) => {
  const handleCancel = () => {
    setIsDoraDocsModalVisible(false);
  };

  return (
    <Modal visible={isDoraDocsModalVisible} onCancel={handleCancel} style={{ minHeight: "50vh" }} footer={[]}>
      {selectedData?.propertyYearBuilt > 1977 && (element.contractType === "LBPOS" || element.contractType === "LBPD") && (
        <p style={{ fontWeight: "500", color: "#2976be" }}>
          Note : <text style={{ fontWeight: "500", color: "darkgray" }}>Document Not Required ! This property was built after 1977, exempting the need for this document.</text>{" "}
        </p>
      )}
      {element.contractType === "LCAES" ? (
        <Card style={{ background: "#f6f8fa", borderRadius: "8px", padding: "16px" }}>
          <Row align="middle">
            <Col flex="auto">
              <Title level={5} style={{ color: "#2976be", marginBottom: "8px" }}>
                <ExclamationCircleOutlined style={{ fontSize: "20px", color: "orange", marginRight: "10px" }} /> {element.name || "Listing Contract Amend/Extend"}
              </Title>
              <Text style={{ color: "gray" }}>
                Please select the three-dot menu (
                <MoreOutlined style={{ fontSize: "14px", color: "#08c", paddingTop: "10px", fontWeight: "800" }} />) of the existing document you would like to create an Amend/Extend.
              </Text>
            </Col>
          </Row>
        </Card>
      ) : (
        <CreatedDoraDocsModalContent handleUploadNew={handleUploadNew} handleCreateNew={handleCreateNew} />
      )}
    </Modal>
  );
};

export default CreateDoraDocsModal;
