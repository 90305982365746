import axios from "axios";
import dotEnv from "dotenv";
dotEnv.config();

const apiURL = process.env.REACT_APP_BASE_URL;

const getUploadFile = (data) => {
  let accessTokan = localStorage.getItem("userAuth");
  var params = new FormData();
  params.append("fileBase64", data);
  params.append("folderOf", "PROPERTY_DOCS_PDF");

  return axios.post(`${apiURL}/common/uploadFile`, params, {
    headers: {
      "Content-Type": "multipart/form-data",
      accept: "application/json",
      authorization: `bearer ${accessTokan}`,
    },
  });
};

const uploadERTSPDF = ({file, transactionData, createErts, delegateOwnerId, documentSetId}) => {
    let accessToken = localStorage.getItem("userAuth");
    const formData = new FormData();

    formData.append("file", file);
    formData.append("createErts", createErts);
    if(delegateOwnerId){
        formData.append("delegateOwnerId", delegateOwnerId);
    }
    if(documentSetId){
      formData.append("documentSetId", documentSetId);
    }
    // Append any additional data as needed
    if (transactionData) {
        formData.append("transactionData", JSON.stringify(transactionData));
    }

    return axios.post(`${apiURL}/importErts`, formData, {
        headers: {
            accept: "application/json",
            authorization: `Bearer ${accessToken}`,
            "Content-Type": "multipart/form-data", // This header is optional; axios will set it automatically
        },
    });
};

const checkIsMarkEibnerBrokerageRequest = ({ sourceAxios, delegateOwnerId }) => {
  return axios.get(`${apiURL}/checkAccessOfMarkEibnerBrokerage`, {
    params: delegateOwnerId ? { delegateOwnerId } : {},
    cancelToken: sourceAxios?.token,
    headers: {
      accept: "application/json",
      "Content-Type": "application/x-www-form-urlencoded",
      authorization: `Bearer ${localStorage.getItem("userAuth")}`,
    },
  });
};



export const ERTSDataApi = { getUploadFile, uploadERTSPDF, checkIsMarkEibnerBrokerageRequest};
