import { combineReducers } from "redux";
import { TemplateReducer } from "./Reducers/TemplateReducer";
import { DocumentReducer } from "./Reducers/DocumentReducer";
import {emailDripReducer} from "./Reducers/EmailDripReducer";
import { DocSetReducer } from "./Reducers/DocSetReducer";

const ToolReducers = combineReducers({
  TemplateReducer,
  DocumentReducer,
  emailDripReducer,
  DocSetReducer,
});
export { ToolReducers };
