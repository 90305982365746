import { encryptUrlParams } from "../../Common/utils/extraFunctions";

export const buildRedirectPath = ({ dest, role, urlId, offerId, adminURL, delegateOwnerId }) => {
  switch (dest) {
    case "RTD":
      return role === "sellerAgent" ? `/transactions/sDashboard/${urlId}` : `/transactions/bDashboard/${urlId}`;
    case "TC_RTD":
      const encryptedOwnerId = encryptUrlParams(delegateOwnerId);
      if (!encryptedOwnerId) {
        return `/account/delegates/transactions`;
      }
      return role === "sellerAgent" ? `/account/delegates/transactions/sDashboard/${urlId}?ref=${encryptedOwnerId}` : `/account/delegates/transactions/bDashboard/${urlId}?ref=${encryptedOwnerId}`;
    case "offerTable":
    case "OMD":
    case "TC_OMD":
      return role === "sellerAgent" ? `${adminURL}/offer/${urlId}${offerId ? `?offer=${offerId}` : ""}` : "";
    case "PRE_RTD":
    case "TC_PRE_RTD":
      return `${adminURL}/offer/${urlId}/rtd/${offerId}`;
    case "BUG_REPORT":
      return `/bugsReport`;
    case "transaction":
    case "TRANSACTION":
      return `/transactions`;
    case "TC_TRANSACTION":
      return `/account/delegates/transactions`;
    case "client":
    case "CONTACTS":
      return `/contacts`;
    default:
      return "/";
  }
};

export const handleValidSession = ({ dest, redirectpathname, history, searchQuery }) => {
  if (["offerTable", "PRE_RTD", "OMD", "TC_PRE_RTD", "TC_OMD"].includes(dest)) {
    window.location.href = redirectpathname || "/";
  } else {
    history.push({ pathname: redirectpathname || "/", search:["TC_RTD", "RTD",].includes(dest) ? "" : searchQuery });
  }
};
