import { Button, Collapse, Modal, Input, Tabs, Tooltip, List, Card } from "antd";
import React, { useState } from "react";
import { DeleteTwoTone, ExclamationCircleOutlined, CaretRightOutlined, PlusCircleFilled, PlusOutlined, SearchOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import TabPane from "antd/lib/tabs/TabPane";
import { generateInitialsFromObj } from "../../Common/utils/extraFunctions";
import { documentAction } from "../state/actions";
import { useMediaQuery } from "react-responsive";
import ManalAddClient from "./ManalAddClient";
import EachCorpSigner from "./EachCorpSigner";
import styles from "../style/EditSellerBuyerCorp.module.css";
import { EmptyData } from "../../Common/components/EmptyData";
const { confirm } = Modal;
const { Panel } = Collapse;

const ManualEditCorpOrClient = ({ sourceType, formVisibility, setFormVisibility }) => {
  const dispatch = useDispatch();
  const isMobile = useMediaQuery({ maxWidth: 480 });

  let { clientsArray, currentlySelectedCorpData, entityName } = useSelector((state) => state.documentSection.CorpReducer);

  const [showClientListModal, setShowClientListModal] = useState(false);

  const handleAddSigners = (element) => {
    setShowClientListModal(true);
    setFormVisibility((prevState) => ({
      ...prevState,
      addSigners: true, // Toggle the 'signers' value
    }));
    dispatch(documentAction.setCorpState({ currentlySelectedCorpData: element }));
  };

  let handleCancel = () => {
    setShowClientListModal(false);
  };

  const handleEntityNameChange = (e) => {
    dispatch(documentAction.setCorpState({ entityName: e.target.value }));
  };

  const handleCorpAdd = (type) => {
    dispatch(documentAction.setCorpState({ entityName: "" }));
    let corpInfo = {
      fullName: entityName?.trim(),
      isCorp: true,
      key: uuidv4(),
      signers: [],
    };
    let newData = [...(clientsArray || []), corpInfo];
    handleDataChange({ Buyers: newData });
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleCorpAdd();
    }
  };
  const handleDeleteClientOrCorp = (element, type, item) => {
    if (clientsArray) {
      if (type === "CORP") {
        handleCorpDelete(element);
        return;
      } else if (type === "SIGNERS") {
        let newDataIndex = clientsArray?.findIndex((el) => el.key === element.key);
        if (newDataIndex !== -1) {
          clientsArray[newDataIndex].signers = clientsArray[newDataIndex]?.signers?.filter((signer) => {
            if (item.key) {
              return signer.key !== item.key;
            } else {
              return signer.personId !== item.personId;
            }
          });
        }
      } else {
        clientsArray = clientsArray?.filter((el) => {
          if (element.personId) {
            return el.personId !== element.personId;
          } else {
            return el.key !== element.key;
          }
        });
      }
      handleDataChange({ Buyers: clientsArray });
    }
  };
  const handleCorpDelete = (element = {}) => {
    confirm({
      title: `Are you sure you want to remove ${element.fullName} from this form?`,
      okText: "Yes",
      cancelText: "No",
      icon: <ExclamationCircleOutlined />,
      content: "Note: This will also remove all the signers associated with it.",
      onOk() {
        let newData = clientsArray?.filter((el) => el.key !== element.key);
        handleDataChange({ Buyers: newData });
      },
      onCancel() {},
    });
  };
  let handleDataChange = (updatedClients) => {
    dispatch(documentAction.setpdfImportState(updatedClients));
  };
  const corpClients = clientsArray?.filter((ele) => ele.isCorp) || [];
  return (
    <>
      {showClientListModal && (
        <Modal width={600} style={{ height: "600px" }} visible={showClientListModal} title={`Add Signers ${currentlySelectedCorpData.fullName ? "for " + currentlySelectedCorpData.fullName : ""}`} onOk={handleCancel} onCancel={handleCancel} footer={null}>
          <ManalAddClient sourceType={sourceType} source={"CORP"} currentlySelectedData={currentlySelectedCorpData} clientsArray={clientsArray} handleDataChange={handleDataChange} addSigners={formVisibility?.addSigners} />
        </Modal>
      )}
      <div className={styles.contentDiv}>
        <div>
          <div className={styles.offerImportpersonheading}>
            <text>Add Person</text>
          </div>

          <ManalAddClient sourceType={sourceType} source={"persons"} setFormVisibility={setFormVisibility} clientsArray={clientsArray} handleDataChange={handleDataChange} addPersons={formVisibility?.addPersons} />
        </div>
        <div className={styles.offerImportcorpheading} style={{ marginTop: "10px" }}>
          <text>Corporation/Trust/Other</text>
         
        </div>

        {formVisibility?.addCorps && (
          <div style={{ display: "flex", gap: "10px", width: "100%", marginTop: "10px " }}>
            <Input placeholder="Enter Corporation/Trust Name" onKeyPress={(e) => handleKeyPress(e)} name="trustee" value={entityName || ""} onChange={(e) => handleEntityNameChange(e)} />
            <Button type="primary" onClick={(e) => handleCorpAdd("corporation")} disabled={!entityName}>
              Add
            </Button>
          </div>
        )}
        {corpClients && corpClients.length > 0 ? (
          clientsArray
            .filter((ele) => ele.isCorp)
            .map((ele, ind) => (
              <div key={ind} style={{ marginTop: "10px" }}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    marginTop: "10px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      gap: "20px",
                      width: "100%",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "18px",
                        textTransform: "capitalize",
                        maxWidth: isMobile ? "160px" : "auto",
                        minWidth: isMobile ? "160px" : "70%",
                        wordBreak: "break-word", // Ensures long words wrap to the next line
                        whiteSpace: "normal", // Allows wrapping of text
                      }}
                    >
                      {ele.fullName}
                    </span>
                    <div style={{ display: "flex" }}>
                      <div style={{ marginRight: "15px" }}>
                        <Tooltip title="Add Signers">
                          <PlusCircleFilled
                            onClick={(e) => {
                              e.stopPropagation();
                              handleAddSigners(ele);
                            }}
                            style={{
                              fontSize: "20px",
                              fill: "#085191",
                              color: "#085191",
                              marginTop: "6px",
                            }}
                          />
                        </Tooltip>
                      </div>

                      <div
                        onClick={(e) => {
                          e.stopPropagation();
                          handleDeleteClientOrCorp(ele, "CORP");
                        }}
                      >
                        <Tooltip title="Delete Corporation/Trust">
                          <DeleteTwoTone
                            style={{
                              fontSize: "medium",
                              marginTop: "8px",
                            }}
                          />
                        </Tooltip>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Display Signers */}
                {(ele.signers || []).length > 0 ? (
                  <List
                    dataSource={ele.signers || []}
                    renderItem={(item) => {
                      const initials = generateInitialsFromObj(item);
                      return <EachCorpSigner key={item?._id} item={item} ele={ele} initials={initials} isMobile={isMobile} handleDelete={handleDeleteClientOrCorp} clientsArray={clientsArray} handleDataChange={handleDataChange} />;
                    }}
                  />
                ) : (
                  <EmptyData />
                )}
              </div>
            ))
        ) : (
          <Card
            bordered
            style={{
              display: "flex", // Enables Flexbox
              flexDirection: "column", // Stack items vertically
              justifyContent: "center", // Centers content vertically
              alignItems: "center", // Centers content horizontally
              textAlign: "center",
              background: "#e6f7ff",
              borderRadius: "8px",
              height: "150px",
              marginTop: "10px",
              cursor: "pointer",
            }}
            onClick={() => {
              setFormVisibility((prevState) => ({
                ...prevState,
                addCorps: !prevState.addCorps, // Toggle the 'signers' value
              }));
            }}
          >
            <div
              style={{
                display: "flex", // Enables Flexbox
                flexDirection: "column", // Stack items vertically
              }}
            >
              <PlusOutlined style={{ fontSize: "24px", color: "#1890ff" }} />
              <div style={{ marginTop: "8px", color: "#1890ff", fontSize: "18px" }}>Add Corporation/Trust/Other</div>
            </div>
          </Card>
        )}
      </div>
    </>
  );
};

export default ManualEditCorpOrClient;
