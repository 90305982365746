import { message } from "antd";
import { landingPageConstants } from "./action-types";
import { landingPageApi } from "../utils/api";
import moment from "moment";

const getRemindersListSuccess = (response) => {
  return {
    type: landingPageConstants.GET_REMINDERS_LIST_SUCCESS,
    payload: response,
  };
};

const getRemindersListError = (error) => {
  return {
    type: landingPageConstants.GET_REMINDERS_LIST_FAILURE,
    payload: error,
  };
};

const getRemindersLoading = () => {
  return {
    type: landingPageConstants.GET_REMINDERS_LIST_LOADING,
  };
};

const getRemindersList = ({timestamp, unmounted, sourceAxios}) => {
  return (dispatch, getState) => {
    dispatch(getRemindersLoading());
    landingPageApi
      .getRemindersListRequest({timestamp, sourceAxios, allAgents: getState()?.account?.agentAndDelegates})
      .then((response) => {
        if(!unmounted.value){
          const newData = response?.data?.info;
          dispatch(getRemindersListSuccess(newData));
        }
      })
      .catch((error) => {
        if(!unmounted.value){
          dispatch(getRemindersListError(error));
        }
      });
  };
};

const getRemindersandScheduleLoading = (status) => {
  return {
    type: landingPageConstants.GET_REMINDER_AND_SCHEDULE_LOADING,
    payload: status,
  };
};
const getRemindersandScheduleSuccess = (response) => {
  return {
    type: landingPageConstants.GET_REMINDER_AND_SCHEDULE_SUCCESS,
    payload: response,
  };
};
const getRemindersandScheduleError = (status) => {
  return {
    type: landingPageConstants.GET_REMINDER_AND_SCHEDULE_FAILURE,
    payload: status,
  };
};
const getRemindersandSchedule = (value,isDashboard) => {
  return (dispatch, getState) => {
    dispatch(getRemindersandScheduleLoading(true));
    landingPageApi
      .getReminderAndschedule(value,isDashboard)
      .then((response) => {
       const newData = response.data.info.data
       dispatch(getRemindersandScheduleSuccess(newData));
        dispatch(getRemindersandScheduleLoading(false));
      })
      .catch((error) => {
        dispatch(getRemindersandScheduleError(error));
        dispatch(getRemindersandScheduleLoading(false));
      });
  };
};

const createReminderSuccess = (response) => {
  return {
    type: landingPageConstants.CREATE_REMINDER_SUCCESS,
    payload: response,
  };
};

const createRemindersError = (error) => {
  return {
    type: landingPageConstants.CREATE_REMINDER_FAILURE,
    payload: error,
  };
};

const createReminder = (data) => {
  return (dispatch, getState) => {
    landingPageApi
      .createReminderRequest(data)
      .then((response) => {
        const newData = response?.data?.info;
        dispatch(createReminderSuccess(newData));
        if (data.dashboardType === "REMINDER") {
          dispatch(renderReminderSuccess());
        } else if (data.dashboardType === "SCHEDULE") {
          dispatch(renderScheduleSuccess());
        }
        message.success("Created Successfully!");
      })
      .catch((error) => {
        dispatch(createRemindersError(error));
        message.error(error.response.data.message || "We encountered an issue creating reminder. Please try again later!");
      });
  };
};

const getReminderByIdSuccess = (response) => {
  return {
    type: landingPageConstants.GET_REMINDERS_BY_ID_SUCCESS,
    payload: response,
  };
};

const getReminderByIdError = (error) => {
  return {
    type: landingPageConstants.GET_REMINDERS_BY_ID_FAILURE,
    payload: error,
  };
};

const getReminderById = (id) => {
  return (dispatch, getState) => {
    landingPageApi
      .getReminderByIdRequest(id)
      .then((response) => {
        const newData = response?.data?.info;
        dispatch(getReminderByIdSuccess(newData));
      })
      .catch((error) => {
        dispatch(getReminderByIdError(error));
      });
  };
};

const updateReminderSuccess = (response) => {
  return {
    type: landingPageConstants.UPDATE_REMINDER_SUCCESS,
    payload: response,
  };
};

const updateReminderError = (error) => {
  return {
    type: landingPageConstants.UPDATE_REMINDER_FAILURE,
    payload: error,
  };
};

const updateReminder = (id, data, timestamp, source) => {
  return (dispatch, getState) => {
    landingPageApi
      .updateReminderRequest(id, data)
      .then((response) => {
        const newData = response?.data?.info;
        if (data.dashboardType === "REMINDER") {
          dispatch(renderReminderSuccess());
        } else if (data.dashboardType === "SCHEDULE" && source === "Reminder") {
          dispatch(renderScheduleSuccess());
          dispatch(renderReminderSuccess());
        } else if (data.dashboardType === "SCHEDULE" && !source) {
          dispatch(renderScheduleSuccess());
        } else if (data.dashboardType === "CONTRACT_REMINDER") {
          dispatch(renderReminderSuccess());
        } else if (data.dashboardType === "CONTRACT_SCHEDULE") {
          dispatch(renderScheduleSuccess());
          dispatch(renderReminderSuccess());
        }
        message.success("Updated Successfully!");
      })
      .catch((error) => {
        dispatch(updateReminderError(error));
        message.error(
          error?.response?.data?.message || "We encountered an issue while updating the reminder. Please try again later!"
        );
      });
  };
};

const renderScheduleSuccess = () => {
  return {
    type: landingPageConstants.RENDER_SCHEDULE,
  };
};

const renderReminderSuccess = () => {
  return {
    type: landingPageConstants.RENDER_REMINDER,
  };
};

const deleteReminderByIdError = (error) => {
  return {
    type: landingPageConstants.DELETE_REMINDERS_BY_ID_FAILURE,
    payload: error,
  };
};

const deleteReminderById = (id, type, typeOfCreation) => {
  return (dispatch, getState) => {
    landingPageApi
      .deleteReminderByIdRequest(id, type)
      .then((response) => {
        if (!typeOfCreation) {
          dispatch(renderReminderSuccess());
        } else {
          dispatch(renderScheduleSuccess());
        }
        message.success("Deleted Successfully!");
      })
      .catch((error) => {
        message.error(
          error?.response?.data?.message || "We encountered an issue while deleting the task. Please try again later!"
        );
        dispatch(deleteReminderByIdError(error));
      });
  };
};

const getScheduleListSuccess = (response) => {
  return {
    type: landingPageConstants.GET_SCHEDULE_LIST_SUCCESS,
    payload: response,
  };
};

const getScheduleListError = (error) => {
  return {
    type: landingPageConstants.GET_SCHEDULE_LIST_FAILURE,
    payload: error,
  };
};

const getScheduleListLoading = () => {
  return {
    type: landingPageConstants.GET_SCHEDULE_LIST_LOADING,
  };
};

const getScheduleList = ({timestamp, unmounted, source}) => {
  return (dispatch, getState) => {
    dispatch(getScheduleListLoading());
    landingPageApi
      .getScheduleListRequest({timestamp, source, allAgents: getState()?.account?.agentAndDelegates})
      .then((response) => {
        if(!unmounted.value){
          const newData = response?.data?.info;
          dispatch(getScheduleListSuccess(newData));
        }
      })
      .catch((error) => {
        if(!unmounted.value){
          dispatch(getScheduleListError(error));
        }
      });
  };
};

const resetNotesState = (data) => {
  return {
    type: landingPageConstants.RESET_NOTES_STATE,
  };
};
const setNotesPanelLoading = (data) => {
  return {
    type: landingPageConstants.NOTES_PANEL_LOADING,
    notesPanelLoading: data,
  };
};
const setNotesSavingStatus = (response) => {
  return {
    type: landingPageConstants.NOTES_SAVING_STATUS,
    payload: response,
  };
};


const saveNotesInCalendar = ({data, props, getCall}) => {
  return (dispatch) => {
    dispatch(setNotesSavingStatus(true));
    landingPageApi
      .saveNotesInCalendarRequest(data)
      .then((response) => {
        dispatch(setNotesSavingStatus(false));
          if (getCall) {
            const data = {
              notesFor: (props?.source === "CALANDER") ? "CALANDAR" : "TRANSACTION",
              ...(props?.detailsData ? {...props?.detailsData} : {})
            }
            dispatch(landingPageAction.getSavedNotes({timestamp: props?.startTimestamp, queryData:data}));
          }
      })
      .catch((error) => {
        message.error(error?.response?.data?.message || "We encountered an error while saving Notes. Please try after some time!");
        dispatch(setNotesSavingStatus(false));
      });
  };
};

const getSavedNotesSuccess = (response) => {
  return {
    type: landingPageConstants.GET_SAVED_NOTES_SUCCESS,
    payload: response,
  };
};

const getSavedNotes = ({ timestamp, queryData, unmounted, sourceAxios }) => {    
  return (dispatch) => {
    dispatch(setNotesPanelLoading(true));
    landingPageApi
      .getSavedNotesRequest({timestamp,queryData, unmounted, sourceAxios})
      .then((response) => {
        if (!unmounted?.value) {
          dispatch(getSavedNotesSuccess(response?.data?.info));
          dispatch(setNotesPanelLoading(false));
        }
      })
      .catch((error) => {
        if (!unmounted?.value) {
          message.error(error?.response?.data?.message || "We encountered an error while getting Notes. Please try after some time!");
          dispatch(setNotesPanelLoading(false));
        }
      });
  };
};

// Newsletter actions

const getNewsletterLoading = () => {
  return {
    type: landingPageConstants.GET_NEWSLETTER_LOADING,
  };
};

const getNewsletterPageDataSuccess = (response)=>{
  return {
    type: landingPageConstants.GET_NEWSLETTER_PAGE_DATA_SUCCESS,
    payload: response,
  };
}

const getNewsletterPageDataError = (error)=>{
  return {
    type: landingPageConstants.GET_NEWSLETTER_PAGE_DATA_FAILURE,
    payload: error,
  };
}

const getNewsletterPageData = ()=>{
  return (dispatch, getState) => {
    dispatch(getNewsletterLoading());
    landingPageApi
      .getNewsletterData()
      .then((response) => {
        const newData = response?.data?.info.data;
        dispatch(getNewsletterPageDataSuccess(newData));
      })
      .catch((error) => {
        dispatch(getNewsletterPageDataError(error));
      });
  }
}


  const renderCompSucess = ({data}) => {
    return {
      type: landingPageConstants.RENDER_SUPPORT_COMPONANT_SUCESS,
      data,
    };
  };

  const renderCompError = (response,id) => {
    return {
      type: landingPageConstants.RENDER_SUPPORT_COMPONANT_FAILURE,
    //   payload:{data:response.data.info.data,id}

    };
  };

  const renderSupportComponant = (data) => {
    // renderCompLoading();
return (dispatch) => {
    dispatch(renderCompSucess({data}))
    // .catch((error) => {
    //   dispatch(renderCompError(error));
    // });
};
};

export const landingPageAction = {
  getRemindersList,
  createReminder,
  getReminderById,
  updateReminder,
  deleteReminderById,
  getRemindersandSchedule,
  getScheduleList,
  saveNotesInCalendar,
  getSavedNotes,
  getNewsletterPageDataSuccess,
  getNewsletterPageDataError,
  getNewsletterPageData,
  getNewsletterLoading,
  renderSupportComponant,
  setNotesPanelLoading,
  resetNotesState,
  renderReminderSuccess
};
