import { checkForDeleteClientDoc } from "../../Client/utils/helper";
import { buyerCustomerDocsContractTypes, conterOfferContractTypes, createOfferContractTypes, isSellingNotExecutedFunc } from "../../Common/commondata/commonData";
import { combineBrokersFirmName } from "../../Common/utils/extraFunctions";
import { getFullNamesFromArray } from "../../DocumentSection/utils/helper";

export const getCategoryDataHandle = ({ notificatonData }) => {
  if (notificatonData) {
    let { type, openFor, offerId, propertyId, _id } = notificatonData || {};
    let category, categoryId, status;
    if (openFor === "BUYER_AGENT") {
      category = "BUYER";
      categoryId = offerId;
      status = type === "OFFER_TERMINATED" ? "TERMINATED" : "";
    } else {
      category = "SELLER";
      categoryId = propertyId ? propertyId : _id;
    }

    return { category, categoryId, status };
  } else {
    return { category: null, categoryId: null, status: null };
  }
};

// export const isDisclosureDocPresent = (propertyUploadDocs) => {
//   return propertyUploadDocs && propertyUploadDocs.some(element =>
//     element.offerDocument &&
//     !element?.offerId &&
//     !["ERTS", "LCAES", "FRASCONA_ERTS"].includes(element.contractType)
//   );
// }

const mapSigners = (coClient, role) =>
  coClient.signers?.map((signer) => ({
    ...signer,
    corpKey: coClient.key,
    openFor: role,
    isCorp: true,
  })) || [];

export const mapClientsForSendEmail = ({ clients, role, agents }) => {
  const mergedClients = [...(clients ? clients : []), ...(agents ? agents : [])];
  return (
    mergedClients?.map((coClient) => ({
      ...coClient,
      openFor: coClient.isAgent ? coClient.openFor : role,
      isReadOnly: false,
      isAddedByUser: false,
      ...(coClient.isCorp
        ? {
            signers: mapSigners(coClient, role),
            isCorp: true,
          }
        : { isCorp: false }),
    })) || []
  );
};

export const getBuildDataForCustomerdocs = ({ clientDocId, contractType, contractId, currentAgentDetails, delegate, transactionData = {}, item, officeDetails, docSecondItem }) => {
  let BuyerAgents = [],
    SellerAgents = [];
  let Buyers = [];
  let Sellers = [];
  const isBuyerCustomerDoc = buyerCustomerDocsContractTypes.includes(contractType);
  if (isBuyerCustomerDoc) {
    Buyers = item?.isProperty ? transactionData?.Sellers : transactionData?.Buyers;
    BuyerAgents = item?.isProperty ? transactionData?.SellerAgents : transactionData?.BuyerAgents;
  } else {
    Sellers = item?.isProperty ? transactionData?.Sellers : transactionData?.Buyers;
    SellerAgents = item?.isProperty ? transactionData?.SellerAgents : transactionData?.BuyerAgents;
  }

  let buildData = {
    urlData: {
      ...(isBuyerCustomerDoc && Buyers?.length ? { Buyers } : Sellers?.length ? { Sellers } : {}),
      ...(BuyerAgents?.length > 0 ? { BuyerAgents, BuyerBrokersList: getFullNamesFromArray(BuyerAgents), BuyerBrokersFirmList: combineBrokersFirmName(BuyerAgents) } : {}),
      ...(SellerAgents?.length > 0 ? { SellerAgents, SellerBrokersList: getFullNamesFromArray(SellerAgents), SellerBrokersFirmList: combineBrokersFirmName(SellerAgents) } : {}),

      ...(docSecondItem?.contractType === "LCAE" ? { LcaeCheckedListingContract: "ERTBListingContract" } : docSecondItem?.contractType === "LCAES_ERTL" ? { LcaeCheckedListingContract: "ERTLListingContract" } : docSecondItem?.contractType === "LCAES_ETCT" ? { LcaeCheckedListingContract: "ExclusiveTenantListingContract" } : {}),

      ...(docSecondItem?.contractType === "LCAE" ? { LcaeInitatingParty: "LcaeInitatingPartyBuyer" } : docSecondItem?.contractType === "LCAES_ERTL" ? { LcaeInitatingParty: "LcaeInitatingPartyLandlord" } : docSecondItem?.contractType === "LCAES_ETCT" ? { LcaeInitatingParty: "LcaeInitatingPartyTenant" } : {}),
      officeDetails,
      ...(docSecondItem?.contractType ? { ...transactionData } : {}),
    },
    openFor: isBuyerCustomerDoc ? "BUYER_AGENT" : "SELLER_AGENT",
    RTDInitiator: isBuyerCustomerDoc ? "BUYER" : "SELLER",
    ...(delegate ? { delegateOwnerId: currentAgentDetails?._id } : {}),
    ...(docSecondItem?.contractType && clientDocId ? { clientDocId } : {}),
    ...(docSecondItem?.contractType === "LCAES" ? { propertyId: item?.propertyId } : {}),
    openForPropertyDoc: isBuyerCustomerDoc ? "BUYER_AGENT" : "SELLER_AGENT",
    contractId: contractId,
    contractType: contractType,
    token: localStorage.getItem("userAuth"),
    builtForSection: "DOCUMENT",
    builtForId: currentAgentDetails?._id,
    key: currentAgentDetails?._id,
    signable: true,
  };
  return buildData;
};

export const canPropertyDocItemBeDeleted = ({ eachDoc, getCoordinateItem }) => {
  if (!eachDoc || eachDoc?.storageType==="FOLDER") {
    return false;
  }
  if (eachDoc?.isCustomerDoc && !checkForDeleteClientDoc(eachDoc)) {
    return false;
  }
  const isSellingNotExecuted = isSellingNotExecutedFunc({ item: getCoordinateItem });
  if (["ERTS", "FRASCONA_ERTS"].includes(eachDoc?.contractType) && getCoordinateItem?.isProperty && isSellingNotExecuted) {
    return false;
  }
  const isbuyingNotExecuted = getCoordinateItem?.status === "ARCHIVED" || getCoordinateItem?.status === "CLOSED" || getCoordinateItem?.status === "ACCEPTED" || getCoordinateItem?.status === "TERMINATED";
  if (createOfferContractTypes.includes(eachDoc?.contractType) && isbuyingNotExecuted) {
    return false;
  }
  if (conterOfferContractTypes.includes(eachDoc?.contractType) && eachDoc?.buyerSignStatus === "SUBMITTED" && eachDoc?.sellerSignStatus === "SUBMITTED") {
    return false;
  }
  return true;
};

export const hasPropertyDocsMenuItems = ({ eachDoc, getCoordinateItem }) => {
  return eachDoc?.doc_second?.length > 0 || canPropertyDocItemBeDeleted({ eachDoc, getCoordinateItem });
};
