/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Form, Modal, Select, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { templateAction } from "../state/actions";

const FilterTemplatesModal = ({ templateState, setTemplateState, handleResetButtonClicked }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const [initialContractType, setInitialContractType] = useState(templateState?.selectedContractType || null);
  const { getContractDocumentariesData, getcontractDocumentariesLoading } = useSelector((state) => state.template);

  useEffect(() => {
    dispatch(templateAction.getContractDocumentaries());
  }, []);

  useEffect(() => {
    if (templateState?.selectedContractType && getContractDocumentariesData?.length) {
      const selectedDoc = getContractDocumentariesData.find((doc) => doc.contractType === templateState.selectedContractType);
      if (selectedDoc) {
        form.setFieldsValue({ contractType: selectedDoc.contractType });
      }
    }
  }, [templateState?.selectedContractType, getContractDocumentariesData]);

  useEffect(() => {
    if (templateState?.applyFilterModal) {
      setInitialContractType(templateState.selectedContractType || null);
    }
  }, [templateState?.applyFilterModal]);
  const handleModalCancel = () => {
    setTemplateState((prevState) => ({ ...prevState, applyFilterModal: false }));
  };
  const handleApplyButtonClick = () => {
    if (!templateState.selectedContractType) {
      return;
    }
    setTemplateState((prevState) => ({ ...prevState, applyFilterModal: false, anyFilterApplied: true }));
    dispatch(
      templateAction.gettemplateData({
        contractType: templateState.selectedContractType,
        search: templateState.searchQuery,
      })
    );
  };

  return (
    <Modal footer={null} title={<div className="commonModalTitle">Filter Templates</div>} visible={templateState?.applyFilterModal} onCancel={handleModalCancel}>
      {getcontractDocumentariesLoading ? (
        <div style={{ minHeight: "20vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
          <Spin size="medium" />
        </div>
      ) : (
        <Form form={form} layout="vertical">
          <Form.Item className="lbl" label="Document Type" name="contractType">
            <Select style={{ width: "100%" }} placeholder="Select Document Type" showSearch filterOption={(input, option) => option?.children?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0} allowClear onChange={(value) => setTemplateState((prevState) => ({ ...prevState, selectedContractType: value }))} value={templateState?.selectedContractType}>
              {getContractDocumentariesData?.map((ele) => (
                <Select.Option key={ele?._id} value={ele.contractType}>
                  {ele.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <div style={{ marginTop: "16px", textAlign: "right" }}>
            <hr />
            <Button disabled={!templateState?.anyFilterApplied} onClick={handleResetButtonClicked}>
              Reset
            </Button>
            <Button style={{ marginLeft: "10px" }} type="primary" onClick={handleApplyButtonClick} disabled={templateState?.selectedContractType === initialContractType}>
              Apply
            </Button>
          </div>
        </Form>
      )}
    </Modal>
  );
};

export default FilterTemplatesModal;
