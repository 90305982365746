/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Layout, Col, Row, Button, Form, Input } from "antd";
import { FilterOutlined, FilterFilled, SearchOutlined } from "@ant-design/icons";
import _ from "lodash";
import { EachCard } from "./components/EachCard";
import { templateAction } from "./state/actions";
import styles from "./styles/Template.module.css";
import { EmptyData } from "../Common/components/EmptyData";
import { Loading } from "../Common/components/Loading";
import CreateTemplateModal from "./components/CreateTemplateModal";
import FilterTemplatesModal from "./components/FilterTemplatesModal";
const { Content } = Layout;
const templateInitialState = {
  createTemplateModal: false,
  applyFilterModal: false,
  anyFilterApplied: false,
  selectedContractType: null,
  searchQuery: "",
};
function Template({ activeTab }) {
  const [templateState, setTemplateState] = useState({
    ...templateInitialState,
  });

  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const { templateData, templateLoading } = useSelector((state) => state.template);

  useEffect(() => {
    if (activeTab === "1" && !templateLoading) {
      dispatch(templateAction.gettemplateData({}));
    }
    if (activeTab !== "1") {
      setTemplateState({ ...templateInitialState });
      form?.resetFields();
    }
  }, [activeTab]);

  const handleResetButtonClicked = () => {
    dispatch(templateAction.gettemplateData({}));
    setTemplateState({
      ...templateInitialState,
    });
    form.resetFields();
  };

  // Debounced search function
  const debouncedFunction = _.debounce((value) => {
    setTemplateState((prevState) => ({ ...prevState, searchQuery: value }));
    dispatch(templateAction.gettemplateData({ search: value, contractType: templateState?.selectedContractType }));
  }, 1000);

  const handleSearchChange = (event) => {
    const value = event?.target?.value;
    debouncedFunction(value);
  };

  const handleFilterButtonClicked = () => {
    setTemplateState((prevState) => ({ ...prevState, applyFilterModal: true }));
  };

  return (
    <>
      {templateState?.createTemplateModal && <CreateTemplateModal templateState={templateState} setTemplateState={setTemplateState} />}
      {templateState?.applyFilterModal && <FilterTemplatesModal templateState={templateState} setTemplateState={setTemplateState} handleResetButtonClicked={handleResetButtonClicked} />}
      <Layout style={{ width: "100%", height: "100%", padding: "0px" }}>
        <Content>
          <Row style={{ display: "flex" }}>
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={24}
              xl={24}
              style={{
                paddingLeft: "6px",
                paddingRight: "6px",
              }}
            >
              {/* Template Navigation */}
              <div className={styles.templateNav} style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Button onClick={() => setTemplateState({ ...templateState, createTemplateModal: true })} type="primary" className={styles.templatebutton}>
                    Create Template
                  </Button>
                </div>

                <div style={{ display: "flex", alignItems: "center", gap: "1rem", marginRight: "1rem" }}>
                  {/* Search Input */}
                  <Form form={form} style={{ display: "flex", alignItems: "center", margin: "0" }}>
                    <Form.Item name="search" style={{ marginBottom: "0px" }}>
                      <Input allowClear placeholder="search here..." suffix={<SearchOutlined />} style={{ maxWidth: "400px" }} onChange={handleSearchChange} />
                    </Form.Item>
                  </Form>

                  {/* Filter Icon */}
                  {templateState?.anyFilterApplied ? (
                    <FilterFilled
                      onClick={handleFilterButtonClicked}
                      style={{
                        fontSize: "25px",
                        cursor: "pointer",
                      }}
                    />
                  ) : (
                    <FilterOutlined
                      onClick={handleFilterButtonClicked}
                      style={{
                        fontSize: "25px",
                        cursor: "pointer",
                      }}
                    />
                  )}

                  {/* Reset Button */}
                  <Button className={styles.templatebutton} disabled={!templateState?.anyFilterApplied} onClick={handleResetButtonClicked}>
                    Reset
                  </Button>
                </div>
              </div>

              {templateLoading ? (
                <div style={{ margin: "200px 500px" }}>
                  <Loading />
                </div>
              ) : (
                <div
                  style={{
                    padding: "3px 3px 0 3px",
                    width: "100%",
                    borderRadius: "8px",
                    backgroundColor: "#fff",
                    transition: "0.3s",
                    height: "calc(100vh - 11.6rem)",
                    msOverflowY: "auto",
                    display: templateLoading ? "none" : "contents",
                  }}
                >
                  {templateData?.length > 0 ? <EachCard data={templateData} /> : <EmptyData />}
                </div>
              )}
            </Col>
          </Row>
        </Content>
      </Layout>
    </>
  );
}

export { Template };
