import React, { useState } from "react";
import { Checkbox, Dropdown, Tooltip } from "antd";
import { FileImageOutlined, FileTextOutlined, MoreOutlined } from "@ant-design/icons";
import moment from "moment";
import { FaRegFilePdf } from "react-icons/fa";
import { conterOfferContractTypes, createOfferContractTypes } from "../../Common/commondata/commonData";
import ProperyDocMenuItem from "./ProperyDocMenuItem";
import { hasPropertyDocsMenuItems } from "../utils/helper";

const PropertyDocFile = ({ ele, role, item, handlePropertyDocs, handleShareUnshareFileConfirm, handleToggleDashboard, handleDeletePropertyDocs, isSmallScreen, handleDragStart, selectedFiles, setSelectedFiles, handleCreateAmendExtend }) => {
  const [hoveredRow, setHoveredRow] = useState(null);

  const handleSelectFile = (fileId, contractId) => {
    if (contractId) {
      let updatedOfferDocs = selectedFiles?.offerDocs?.filter((el) => el?.offerDocId === fileId)?.length ? selectedFiles?.offerDocs?.filter((el) => el?.offerDocId !== fileId) : [...selectedFiles?.offerDocs, { offerDocId: fileId, selectedParentId: null }];
      setSelectedFiles({ ...selectedFiles, offerDocs: updatedOfferDocs });
    } else {
      let updatedPropDocs = selectedFiles?.propertyDocs?.filter((el) => el?.propertyDocId === fileId)?.length ? selectedFiles?.propertyDocs?.filter((el) => el?.propertyDocId !== fileId) : [...selectedFiles?.propertyDocs, { propertyDocId: fileId, selectedParentId: null }];
      setSelectedFiles({ ...selectedFiles, propertyDocs: updatedPropDocs });
    }
  };

  return (
    <>
      <tr key={ele?._id} draggable onDragStart={(e) => handleDragStart(e, ele)}>
        <td
         onClick={() => handlePropertyDocs(ele)}
          style={{
            fontWeight: "bolder",
            color: "blue",
            minWidth: "250px",
            width: "65%",
            cursor: "pointer",
            border: "none",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              fontSize: isSmallScreen ? "13px" : "16px",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <div style={{ width: "26px", height: "26px" }} onMouseEnter={() => setHoveredRow(ele?._id)} onMouseLeave={() => setHoveredRow(null)}>
                {hoveredRow === ele?._id || selectedFiles?.propertyDocs?.filter((el) => el?.propertyDocId === ele?._id)?.length || selectedFiles?.offerDocs?.filter((el) => el?.offerDocId === ele?._id)?.length ? (
                  <Checkbox
                    checked={ele?.contractId ? selectedFiles?.offerDocs?.filter((el) => el?.offerDocId === ele?._id)?.length : selectedFiles?.propertyDocs?.filter((el) => el?.propertyDocId === ele?._id)?.length}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleSelectFile(ele?._id, ele?.contractId ? true : false);
                    }}
                    style={{ marginRight: "4px" }}
                  />
                ) : (
                  <>
                    {ele.fileType === "IMAGE" ? (
                      <FileImageOutlined
                        style={{
                          color: "#ecd540",
                          marginTop: "5px",
                          marginRight: "5px",
                          fontSize: "18px",
                        }}
                      />
                    ) : ele.fileType === "PDF" || ele.isPdf ? (
                      <FaRegFilePdf
                        style={{
                          color: "red",
                          marginTop: "5px",
                          marginRight: "5px",
                          fontSize: "18px",
                        }}
                      />
                    ) : ele.offerDocument && ele.contractType === "LCAES" ? (
                      <FileTextOutlined
                        style={{
                          color: "#74b3ed",
                          marginRight: "5px",
                          fontSize: "18px",
                        }}
                      />
                    ) : (
                      <FileTextOutlined
                        style={{
                          color: "#74b3ed",
                          marginRight: "5px",
                          fontSize: "18px",
                        }}
                      />
                    )}
                  </>
                )}
              </div>
              <span>
                {ele.offerDocument ? ele.documentName : ele.fileName || ""}
                {ele.offerDocument && !ele?.offerId && !["ERTS", "LCAES", "FRASCONA_ERTS"].includes(ele.contractType) && <span style={{ color: "grey", fontSize: "12px" }}>{" (Without Buyers)"}</span>}
              </span>
            </div>
            {ele?.transactionData && (
              <div
                style={{
                  color: "grey",
                  fontSize: "11px",
                  marginLeft: "24px",
                }}
              >
                {["ERTS", "FRASCONA_ERTS"].includes(ele.contractType) && ele.transactionData && ele.transactionData.ListingPeriodBeginDate && <span>{moment(ele.transactionData.ListingPeriodBeginDate).format("MM/DD/YYYY")}</span>}
                {["ERTS", "FRASCONA_ERTS"].includes(ele.contractType) && ele.transactionData && ele.transactionData.ListingPeriodEndByDate && <> - {moment(ele.transactionData.ListingPeriodEndByDate).format("MM/DD/YYYY")}</>}
              </div>
            )}
            <div
              style={{
                color: "grey",
                fontSize: "11px",
                marginLeft: "20px",
              }}
            >
              {ele.fileType ? `Uploaded by: ${ele.personId?.fullName}` : ""}
            </div>
          </div>
        </td>

        <td
          style={{
            color: "black",
            fontWeight: "500",
            textAlign: "center",
            minWidth: "50px",
            width: "10%",
            whiteSpace: "pre-wrap",
            border: "none",
          }}
        >
          <div>{moment(ele.createdDateMili).format("MM/DD/YYYY")}</div>
          <div>{moment(ele.createdDateMili).format("hh:mm A")}</div>
        </td>

        <td
          style={{
            fontWeight: "bolder",
            textAlign: "center",
            minWidth: "70px",
            width: "10%",
            color: "#085191",
            border: "none",
          }}
        >
          {!ele?.contractId &&
            (role === "SELLER" && item?.offerId ? (
              <Checkbox
                checked={ele?.isShared}
                onChange={(e) => {
                  handleShareUnshareFileConfirm(ele, e.target.checked);
                }}
                style={{
                  color: ele.isShared ? "white" : "black",
                  borderRadius: "5px",
                  cursor: "pointer",
                }}
              />
            ) : role === "BUYER" && ele?.showSeller ? (
              <Checkbox
                checked={ele?.isShared}
                onChange={(e) => {
                  handleShareUnshareFileConfirm(ele, e.target.checked);
                }}
                style={{
                  color: ele?.isShared ? "white" : "black",
                  borderRadius: "5px",
                  cursor: "pointer",
                }}
              />
            ) : (
              <Tooltip title={role === "SELLER" && !item?.offerId ? "Cannot share: Accepted offer does not exist." : role === "BUYER" && !ele?.showSeller ? "Please send this offer to seller agent to enable sharing." : ""} placement="right" style={{ width: "50px" }}>
                <Checkbox
                  checked={false}
                  disabled={true}
                  style={{
                    color: "black",
                    borderRadius: "5px",
                    cursor: "not-allowed",
                  }}
                />
              </Tooltip>
            ))}
        </td>

        <td
          style={{
            textAlign: "center",
            minWidth: "100px",
            width: "15%",
            border: "none",
          }}
        >
          {ele?.offerId && ele?.offerDocument && ele?.contractId && !["ERTS", "NET_SHEET", "FRASCONA_ERTS"].includes(ele?.contractType) && (
            <Checkbox
              checked={ele?.isCreatedInRtd}
              onChange={(e) => handleToggleDashboard(ele, e.target.checked)}
              disabled={(createOfferContractTypes?.includes(ele?.contractType) && item?.status === "ACCEPTED") || (conterOfferContractTypes?.includes(ele?.contractType) && item?.propertyStatus === "ACCEPTED")}
              style={{
                color: "black",
                borderRadius: "5px",
                cursor: "pointer",
              }}
            />
          )}
        </td>

        <td
          style={{
            textAlign: "center",
            minWidth: "70px",
            width: "10%",
            color: "#085191",
            border: "none",
          }}
        >
          {hasPropertyDocsMenuItems({ eachDoc: ele, getCoordinateItem: item }) ? (
            <Dropdown overlay={<ProperyDocMenuItem parentDoc={ele} eachDoc={ele} getCoordinateItem={item} handleCreateAmendExtend={handleCreateAmendExtend} handleDeletePropertyDocs={handleDeletePropertyDocs} />} trigger={["hover", "click"]}>
              <MoreOutlined
                key="ellipsis"
                style={{
                  fontSize: "26px",
                  color: "#08c",
                  marginRight: "-15px",
                  paddingTop: "5px",
                }}
              />
            </Dropdown>
          ) : (
            <div />
          )}
        </td>
      </tr>

      <tr style={{ border: "1px solid #d3d3d3", height: 0 }}>
        <td colSpan={5} style={{ border: "1px solid #d3d3d3", margin: 0, padding: 0 }}></td>
      </tr>
      {ele?.matchedDocuments?.map((matchedDoc) => (
        <>
          <tr key={matchedDoc?._id} style={{ borderTop: "1px solid #c2c2c2", borderLeft: "none", borderRight: "none" }}>
            <td
              style={{
                fontWeight: "bolder",
                color: "blue",
                minWidth: "250px",
                width: "65%",
                cursor: "pointer",
                paddingLeft: `${23}px`,
                border: "none",
              }}
              onClick={() => handlePropertyDocs(matchedDoc)}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <FileTextOutlined
                  style={{
                    color: "#74b3ed",
                    marginRight: "5px",
                    fontSize: "18px",
                  }}
                />
                <span>{matchedDoc?.documentName || matchedDoc?.fileName}</span>
              </div>
              {matchedDoc.transactionData?.ListingPeriodEndByDate && (
                <div
                  style={{
                    color: "grey",
                    fontSize: "11px",
                    marginLeft: "5px",
                  }}
                >
                  <span style={{ marginLeft: "19px" }}>
                    {<span>Ending Date </span>}
                    {<span> - {moment(matchedDoc.transactionData.ListingPeriodEndByDate).format("MM/DD/YYYY")}</span>}
                  </span>
                </div>
              )}
            </td>

            <td style={{ color: "black", fontWeight: "500", textAlign: "center", minWidth: "50px", width: "170px", whiteSpace: "nowrap", border: "none" }}>
              <div>{moment(matchedDoc?.createdDateMili).format("MM/DD/YYYY")}</div>
              <div>{moment(matchedDoc?.createdDateMili).format("hh:mm A")}</div>
            </td>
            <td style={{ textAlign: "center", minWidth: "70px", width: "10%", border: "none" }}>
              <div></div>
            </td>
            <td
              style={{
                textAlign: "center",
                minWidth: "100px",
                width: "15%",
                border: "none",
              }}
            >
              <div></div>
            </td>
            <td style={{ textAlign: "center", minWidth: "70px", width: "10%", border: "none" }}>
            {hasPropertyDocsMenuItems({eachDoc:matchedDoc, getCoordinateItem:item }) ? (
                <Dropdown overlay={<ProperyDocMenuItem parentDoc={ele} eachDoc={matchedDoc} getCoordinateItem={item} handleCreateAmendExtend={handleCreateAmendExtend} handleDeletePropertyDocs={handleDeletePropertyDocs} />} trigger={["hover", "click"]}>
                  <MoreOutlined
                    key="ellipsis"
                    style={{
                      fontSize: "26px",
                      color: "#08c",
                      marginRight: "-15px",
                      paddingTop: "5px",
                    }}
                  />
                </Dropdown>
              ) : (
                <div />
              )}
            </td>
          </tr>
        </>
      ))}
    </>
  );
};

export default PropertyDocFile;
