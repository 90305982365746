/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import styled from "styled-components";
// import SendTitleCompany from "./SendTitleCompany"
import { Col, Modal, Row, Button, Collapse, message, Spin, Tabs, Input, Form, Grid, } from "antd";
import {
    FolderFilled, FolderOpenOutlined, FileZipOutlined, CaretRightOutlined, CaretDownOutlined,
    ExclamationCircleOutlined, PlusCircleFilled, SearchOutlined, DeleteOutlined
} from "@ant-design/icons";
import { IoIosCreate } from "react-icons/io";
import { MdOutlineUploadFile, } from "react-icons/md";
import { TitleCompanyDetails } from "./TitleCompanyDetails";
import { useDispatch, useSelector } from "react-redux";
import { transactionLaneDataHandle } from "../state/actions";
import { useLocation } from "react-router-dom";
import styles from "../style/document.module.css";
import _ from "lodash";
import TitleCompanyDocument from "./TitleCompanyDocument";
import axios from "axios";
import { checkIfDisabled, createOfferContractTypes, customerDocsContTractTypes, disabledTransactionText } from "../../Common/commondata/commonData";
import { filteredTransactionData, removePersonObject } from "../../PropertyComp/components/helper";
import UploadPdfDoc from "../../PropertyComp/components/UploadPdfDoc";
import { formatPhoneNumber, getCityFromFullAddress, getCoAgentsIds, getStreetAddress } from "../../Common/utils/extraFunctions";
import { getFullNamesFromArray } from "../../DocumentSection/utils/helper";
import AddCloserModal from "./AddCloserModal";
import { getBuildDataForCustomerdocs } from "../../PropertyComp/utils/helper";

const { Panel } = Collapse;
const { useBreakpoint } = Grid

const StyledTree = styled.div`
  line-height: 1.9;
`;
const StyledFile = styled.div`
  padding-left: px;
  display: flex;
  align-items: center;
  span {
    margin-left: 0px;
  }
`;
const StyledFolder = styled.div`
  padding-left: 0px;
  .folder--label {
    display: flex;
    align-items: center;
    span {
      margin-left: 0px;
    }
`;
const Collapsible = styled.div`
  height: ${(p) => (p.isOpen ? "0" : "auto")};
  overflow: hidden;
`;

const File = ({ doraDocItem, selectedData, seller, getTransactionLaneData, insideFolder, section }) => {
    let delegate = (section === "DELEGATE");
    const { getDoraDocumentsData } = useSelector((state) => state.rtdTransactionLane);
    let dispatch = useDispatch();

    const currentAgentDetails = selectedData?.currentAgentId;

    const [modalVisible, setModalVisible] = useState({
        isModalVisible: false,
        isEmailModalVisible: false,
        isEmailPresent: false,
        isAllEmailsModalOpen: false,
    });
    const [allData, setAllData] = useState({
        uploadPdfDoc: false,
        file: "",
        ...(delegate
            ? {
                delegateSide: seller === "SELLER" ? "SELLER" : "BUYER",
                delegateOwnerId: currentAgentDetails?._id,
            }
            : {}),
    });


    const location = useLocation();

    const handleCancel = () => {
        setModalVisible({ ...modalVisible, isModalVisible: false })
    };

    const { getTitleCompanySelectedData } = useSelector((state) => state.rtdTransactionLane);
    const urlShortHand = (ele) => {
      selectedData = filteredTransactionData({ ele, selectedData });
      const officeDetails = {
        officeLogo: currentAgentDetails?.brokerageData?.logo || "",
        name: currentAgentDetails?.brokerageData?.name?.trim() || "",
        address: currentAgentDetails?.brokerageData?.address || "",
        agentId: currentAgentDetails?._id,
      };
      if (customerDocsContTractTypes.includes(ele?.contractType)) {
        const buildData = getBuildDataForCustomerdocs({ contractType: ele?.contractType, contractId: ele.contractId, currentAgentDetails, transactionData: selectedData?.newTransactionData, selectedData, delegate, item: { isProperty: seller === "SELLER" }, officeDetails });
        dispatch(transactionLaneDataHandle.generateUrlIdAndRedirect({ buildData, currentPath: location?.pathname }));
        return;
      }
      let buildData = {
        builtForId: currentAgentDetails?._id,
        key: currentAgentDetails?._id,
        builtForSection: "DOCUMENT",
        signable: true,
        openFor: seller === "SELLER" ? "SELLER_AGENT" : "BUYER_AGENT",
        contractId: ele.contractId,
        contractType: ele.contractType,
        offerId: selectedData?.offerId,
        propertyId: selectedData?.propertyId,
        token: localStorage.getItem("userAuth"),
        RTDInitiator: seller === "SELLER" ? "SELLER" : "BUYER",
        buyerAgentIds: getCoAgentsIds(selectedData?.buyerAgentIds),
        sellerAgentIds: getCoAgentsIds(selectedData?.sellerAgentIds),
        ...(delegate ? { delegateOwnerId: currentAgentDetails?._id } : {}),
        urlData: {
          ...(selectedData.newTransactionData ? { ...selectedData.newTransactionData } : {}),
          ...(ele?.prefillValues || {}),
          Buyers: removePersonObject(selectedData?.buyerIds),
          Sellers: removePersonObject(selectedData?.sellerIds),
          ...((ele?.contractType === "CI" || ele?.contractType === "TO") && getTitleCompanySelectedData
            ? {
                TitleCompanyLogo: getTitleCompanySelectedData.titleCompanyLogo || "",
                TitleCompanyName: getTitleCompanySelectedData.titleCompanyName || "",
                TitleOfficeName: getTitleCompanySelectedData.titleOfficeName || "",
                TitleOfficeEmail: getTitleCompanySelectedData.titleOfficeEmail || "",
                TitleOfficeAddress: getTitleCompanySelectedData.titleOfficeAddress || "",
                TitleOfficePhoneNo: getTitleCompanySelectedData.titleOfficePhoneNo || "",
                TitleOfficeFaxNo: getTitleCompanySelectedData.titleOfficeFaxNo || "",
                TitleCloserName: getTitleCompanySelectedData.titleCloserName || "",
                TitleCloserEmail: getTitleCompanySelectedData.titleCloserEmail || "",
                SalesRepName: getTitleCompanySelectedData.salesRepName || "",
                SalesRepEmail: getTitleCompanySelectedData.salesRepEmail || "",
              }
            : {}),
          ...(ele?.contractType === "SA"
            ? {
                BrokerName: currentAgentDetails?.fullName || "",
                BrokerEmailAddress: currentAgentDetails?.email || "",
                BrokerFirmName: currentAgentDetails?.brokerageData?.name || "",
                BrokerFirmAddress: currentAgentDetails?.brokerageData?.address || "",
                BrokerPhoneNumber: formatPhoneNumber(currentAgentDetails?.mobilePhoneNumber?.phoneNumber),
                BrokerFaxNumber: formatPhoneNumber(currentAgentDetails?.brokerageData?.faxNumber),
              }
            : {}),
          officeDetails,
        },

        ...((ele?.contractType === "CI" || ele?.contractType === "TO") && getTitleCompanySelectedData
          ? {
              titleCompanyDetails: {
                inBuilt: getTitleCompanySelectedData.inBuilt,
                selectedBy: getTitleCompanySelectedData.selectedBy,
                ...(getTitleCompanySelectedData.titleOfficeId ? { titleOfficeId: getTitleCompanySelectedData.titleOfficeId } : {}),
                ...(getTitleCompanySelectedData.salesRepId ? { salesRepId: getTitleCompanySelectedData.salesRepId } : {}),
                ...(getTitleCompanySelectedData.titleCloserId ? { titleCloserId: getTitleCompanySelectedData.titleCloserId } : {}),
              },
            }
          : {}),
      };
      dispatch(transactionLaneDataHandle.generateUrlIdAndRedirect({ buildData, currentPath: location?.pathname, delegateOwnerId: delegate && currentAgentDetails?._id }));
    };
    let docInRtd = false;
    getTransactionLaneData?.forEach((el) => {
        if (el.contractType === doraDocItem.contractType) {
            docInRtd = true;
        }
    });

    const handleCreateNew = () => {

        if (((doraDocItem?.contractType === "TO") || (doraDocItem?.contractType === "CI")) && (!getTitleCompanySelectedData?.selectedBy)) {
            message.error("Please select/add a Title Company to proceed with the following document!");
            return;
        }
        if ((doraDocItem?.contractType === "INSP_RESOL") && (getTransactionLaneData?.filter((el) => el?.contractType === "INSP_OBJ")?.length <= 0)) {
            Modal.confirm({
                title: "Warning! An inspection objection document should exist before creating an inspection resolution document! Are you sure you want to proceed?",
                okText: "Yes",
                cancelText: "No",
                icon: <ExclamationCircleOutlined />,
                onOk: () => {
                    urlShortHand(doraDocItem)
                },
                onCancel: () => {
                    handleCancel()
                }
            })
        } else {
            urlShortHand(doraDocItem)
        }

    }


    useEffect(() => {
        if (seller === "BUYER") {
            let data = selectedData?.newTransactionData?.Buyers?.filter((el) => el.personId === null);
            if (data?.length > 0) {
                setModalVisible({ ...modalVisible, isEmailPresent: false })
            } else {
                setModalVisible({ ...modalVisible, isEmailPresent: true })
            }
        } else {
            let data = selectedData?.newTransactionData?.Sellers?.filter((el) => el.personId === null);
            if (data?.length > 0) {
                setModalVisible({ ...modalVisible, isEmailPresent: false })
            } else {
                setModalVisible({ ...modalVisible, isEmailPresent: true })
            }
        }
    }, [selectedData]);
    const showConfirmATAEModal = ({ message }) => {
        Modal.confirm({
            title: message,
            content: "",
            okText: "Yes",
            cancelText: "No",
            icon: <ExclamationCircleOutlined />,
            onOk() {
                setModalVisible({ ...modalVisible, isModalVisible: true })
            },
            onCancel() { },
        });
    }

    const hasActiveCBSR = getTransactionLaneData?.some(el => createOfferContractTypes.includes(el.contractType));
    const handleDocNameClick = ({ doraDocItem }) => {
        // let documentName = "";
        let contractType = "";
        getDoraDocumentsData?.forEach(doc => {
            if (createOfferContractTypes.includes(doc.contractType)) {
                //   documentName = doc.name;
                contractType = doc.contractType;
            }
        });
        if (!hasActiveCBSR) {
            message.error(`To add another document, you must have an active ${contractType} in the Transaction Dashboard.`)
        }

        if (["MH_ATAE"].includes(doraDocItem.contractType)) {
            let foundMHOffer = getTransactionLaneData?.some(el => el.contractType === "CBS_MH");
            if (!foundMHOffer) {
                let message = `This transaction document is for Manufactured Home. Are you sure you don't want to use an Amend Extend document instead?`
                showConfirmATAEModal({ message });
                return;
            }
        } else if (["ATAE"].includes(doraDocItem.contractType)) {
            let foundMHOffer = getTransactionLaneData?.some(el => el.contractType === "CBS_MH");
            if (foundMHOffer) {
                let message = `This transaction document is not for Manufactured Home. Are you sure you don't want to use a Manufactured Home Amend/Extend document instead?`
                showConfirmATAEModal({ message })
                return
            }
        }
        setModalVisible({ ...modalVisible, isModalVisible: true })
    }

    const checkForDisable = checkIfDisabled({ openFor: seller, actualPropertyStatus: selectedData?.actualPropertyStatus, buyingStatus: selectedData?.buyingStatus, offerStatus: selectedData?.status, enableArray: doraDocItem?.enableDuringDisableStatus });

    const handleUploadNew = () => {
        setAllData({
            ...allData,
            uploadPdfDoc: true,
        })
    }

    const handleModalCancel = () => {
        setAllData({
            ...allData,
            uploadPdfDoc: false
        })
    }

    return (
        <StyledFile 
            style={{
                pointerEvents: doraDocItem?.contractType === "FRASCONA_ERTS" ? "none" : "auto",
                opacity: doraDocItem?.contractType === "FRASCONA_ERTS" ? 0.6 : 1,
            }}
        >
            <span
                style={{
                    fontSize: "18px",
                    fontWeight: 500,
                    color: docInRtd && "rgb(110 153 214)" || "rgb(176, 176, 176)",
                    width: "100%",
                    marginTop: "4px",
                    marginLeft: insideFolder ? "21px" : "auto",
                    paddingLeft: 0,
                    pointerEvents: createOfferContractTypes.includes(doraDocItem?.contractType) ? "none" : "pointer",
                    cursor: createOfferContractTypes.includes(doraDocItem?.contractType) ? "default" : "pointer",
                }}
            >
                <FileZipOutlined style={{ fontSize: "20px" }} />
                <span
                    className={styles.documentsName}
                    {...(checkForDisable?.disabled ? { onClick: disabledTransactionText } : { onClick: () => handleDocNameClick({ doraDocItem }) })}
                >
                    {doraDocItem?.name}
                </span>
            </span>
            <UploadPdfDoc selectedData={{ ...selectedData, ...doraDocItem, RTDInitiator: seller }} allData={allData} setAllData={setAllData} handleModalCancel={handleModalCancel} open={allData?.uploadPdfDoc}  getCoordinateItem={{ isProperty: seller === "SELLER" }}  />
            <Modal visible={hasActiveCBSR && modalVisible.isModalVisible} onCancel={handleCancel} footer={[]}>
                {selectedData?.propertyYearBuilt > 1977 && (doraDocItem.contractType === "LBPOS" || doraDocItem.contractType === "LBPD") && (
                    <p style={{ fontWeight: "500", color: "#2976be" }}>
                        Note : <text style={{ fontWeight: "500", color: "darkgray" }}>Document Not Required ! This property was built after 1977, exempting the need for this document.</text>{" "}
                    </p>
                )}
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-start",
                        gap: "5px",
                        width: "51%",
                        margin: "auto",
                        paddingLeft: "30px",
                    }}
                >
                    <p
                        style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                            fontSize: "20px",
                            color: "#2976be",
                            cursor: "pointer",
                        }}
                        onClick={handleCreateNew}
                    >
                        <IoIosCreate size={25} /> Create New
                    </p>
                    <p
                        style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                            fontSize: "20px",
                            color: "#2976be",
                            cursor: "pointer",
                        }}
                        onClick={handleUploadNew}
                    >
                        <MdOutlineUploadFile size={25} /> Upload PDF
                    </p>
                </div>
            </Modal>
        </StyledFile>
    );
};

// its an folder structure component
const Folder = ({ name, children, selectedData, fiters }) => {
    const [isOpen, setIsOpen] = useState(fiters?.search ? true : false);
    const handleToggle = (e) => {
        e.preventDefault();
        setIsOpen(!isOpen);
    };

    const archivedOrTerminated = selectedData?.status === "TERMINATED"
    return (
        <StyledFolder>
            <div
                className="folder--label"
                onClick={handleToggle}
                style={{
                    cursor: "pointer",
                    pointerEvents: archivedOrTerminated ? "none" : "pointer",
                    alignItems: 'baseline',
                }}
            >
                {isOpen ? (
                    <>
                        <CaretDownOutlined
                            style={{
                                fontSize: "1.3rem",
                                marginRight: "5px",
                                color: archivedOrTerminated ? "#b0b0b0" : "rgb(107,152,199)",
                            }}
                        />{" "}
                        <FolderOpenOutlined
                            style={{ fontSize: "20px", color: "rgb(107,152,199)" }}
                        />
                    </>
                ) : (
                    <>
                        <CaretRightOutlined style={{ fontSize: "1.3rem", color: "#8C8C8C" }} />
                        <FolderFilled
                            style={{
                                fontSize: "20px",
                                color: archivedOrTerminated ? "#b0b0b0" : "rgb(107,152,199)",
                                // marginLeft: "14px",
                            }}
                        />
                    </>
                )}
                <span
                    style={{
                        fontSize: "19px",
                        fontWeight: 600,
                        color: "#8C8C8C",
                        marginTop: "4px",
                        paddingLeft: "4px",
                    }}
                >
                    {name}
                </span>
            </div>
            <Collapsible isOpen={!isOpen}>{children}</Collapsible>
        </StyledFolder>
    );
};

const Tree = ({ children }) => {
    return <StyledTree>{children}</StyledTree>;
};

Tree.File = File;
Tree.Folder = Folder;

export default function DoraDocument({ seller, showDrawer, section }) {
    const delegate = (section === "DELEGATE");
    const { getTransactionLaneData, getIndividualTransactionData, getDoraDocumentsData, getDoraDocumentsLoading, getDoraDocumentsError, getTitleCompanySelectedData, getTitleCompanyDataLoading, getTitleCompanyDataError, refreshTitleCompany } = useSelector((state) => state.rtdTransactionLane);
    const { getUserDetailsData } = useSelector((state) => state.account);
    const [activeKey, setActiveKey] = useState("1")
    const dispatch = useDispatch();
    const [showcompanymodal, setShowcompanymodal] = useState(false);
    const [sendCompanyModal, setSendCompanyModal] = useState(false);
    const [fiters, setFilters] = useState({ search: "" });
    const [form] = Form.useForm();
    const screens = useBreakpoint();;
    const [add, setAdd] = useState({
        open: false,
        section: ""
    });
  
    const [companyModalStep, setCompanyModalStep] = useState({
        step: 0,
        inBuilt: true,
        selectedBy: (seller === "SELLER") ? "SELLER_AGENT" : "BUYER_AGENT",
        titleCompany: "",
        titleOffice: "",
        titleCloser: "",
        salesRep: ""
    });

    const handleAdd = (value) => {
        setAdd({
            open: !add?.open,
            section: value
        });
    }

    const onFinish = (values) => {
        const processObj = {
            ...(values?.name ? { name: values?.name } : {}),
            ...(values?.email ? { email: values?.email } : {}),
            ...(values?.phoneNumber ? { phoneNumber: values?.phoneNumber } : {}),
        }
        const affiliateObj = {
            ...(values?.name ? { name: values?.name } : {}),
            ...(values?.email ? { email: values?.email } : {}),
            ...(values?.phoneNumber ? { phoneNumber: values?.phoneNumber } : {}),
            ...(values?.role ? { role: values?.role } : {}),
        }
        const payload = {
            selectedBy: seller === "BUYER" ? "BUYER_AGENT" : "SELLER_AGENT",
            offerId: getIndividualTransactionData?.offerId || null,
        }
        let processorArr = [...getTitleCompanySelectedData?.processors, processObj]
        let affiliateArr = getTitleCompanySelectedData?.affiliateDetails?.affiliates ? [...getTitleCompanySelectedData?.affiliateDetails?.affiliates, affiliateObj] : [affiliateObj]
        if (add?.section === "Processor") {
            dispatch(transactionLaneDataHandle.addTitleOffice({ payload, delegateSide, delegateOwnerId: delegateSide && getIndividualTransactionData?.currentAgentId?._id, processorArr, processObj }))
        } else {
            dispatch(transactionLaneDataHandle.addTitleOffice({ payload, delegateSide, delegateOwnerId: delegateSide && getIndividualTransactionData?.currentAgentId?._id, affiliateArr, affiliateObj }))
        }
        handleAdd("");
    }

    const delegateSide = delegate && seller;


    let unmounted = {
        value: false
    };
    let source = axios.CancelToken.source();
    useEffect(() => {
        if (showDrawer) {
            let data = {
                listType: seller,
                offerId: getIndividualTransactionData.offerId,
            };
            dispatch(transactionLaneDataHandle.getDoraDocumentsData({ data, fiters, source, unmounted, brokerageId: getUserDetailsData?.brokerageData?.brokerageId?._id }));

            return () => {
                unmounted.value = true;
                source.cancel("Cancelling in cleanup");
            }
        }
    }, [showDrawer, fiters])

    useEffect(() => {
        if (showDrawer && ((getIndividualTransactionData?.status === "ACCEPTED") || (getIndividualTransactionData?.status === "TERMINATED") || (getIndividualTransactionData?.status === "DRAFT") || (getIndividualTransactionData?.status === "SUBMITTED"))) {
            dispatch(transactionLaneDataHandle.getTitleCompanyData({ offerId: getIndividualTransactionData.offerId, source, unmounted, delegateSide }));

            return () => {
                unmounted.value = true;
                source.cancel("Cancelling in cleanup");
            }
        }
    }, [showDrawer, refreshTitleCompany])


    const handleOpenCompanyModal = () => {
        setCompanyModalStep({
            step: 0,
            inBuilt: true,
            selectedBy: (seller === "SELLER") ? "SELLER_AGENT" : "BUYER_AGENT",
            titleCompany: "",
            titleOffice: "",
            titleCloser: "",
            salesRep: ""
        })
        setShowcompanymodal(true);
    };
    const handleInnerClick = (e) => {
        e.stopPropagation(); // Prevent the click event from propagating to the outer component
        setSendCompanyModal(true);
    };

    function totratlast() {
        let arr = _.cloneDeep(getDoraDocumentsData);
        for (let i = 0; i < arr.length; i++) {
            if (arr[i].contractType === "TO") {
                arr.push(...arr.splice(i, 1));
            }
            if (arr[i].contractType === "TR") {
                arr.push(...arr.splice(i, 1));
            }
        }
        return arr;
    }

    /* <--delete title company data---> */
    const { confirm } = Modal;
    const handeldeleteTitleCompanydata = (val) => {
        confirm({
            title: `Are you sure, you want to clear ${val === "affiliate" ? "all data from Other" : "Title Company"} ?`,
            content: val === "affiliate" ? "" : "Note: Clearing the title company will clear title company information from the Title Order and Closing Instructions form. If Closing Instructions has been signed and not sent to other broker, signatures will be reset. If Closing Instructions has been signed and has been sent to other broker the title company won't be cleared in those document.",
            okText: "Yes",
            cancelText: "No",
            icon: <ExclamationCircleOutlined />,
            onOk() {
                if (val === "affiliate") {
                    dispatch(transactionLaneDataHandle.deleteTitleCompanyData({ offerId: getIndividualTransactionData?.offerId, delegateSide, clearAffliates: true }))
                } else {
                    dispatch(transactionLaneDataHandle.deleteTitleCompanyData({ offerId: getIndividualTransactionData?.offerId, delegateSide, clearAffliates: false }))
                }
            },
            onCancel() { },
        });
    }

    const handleOpen = () => {
        handleOpenCompanyModal()
    }

    const handleChange = (value) => {
        setActiveKey(value);
    }

    const handleAddProcessor = () => {
        handleAdd("Processor")
    }

    const handleAddAffilatesModalOpen = () => {
        handleAdd("Affiliate")
    }

    const handleDelete = (item, source) => {
        Modal.confirm({
            title: `Are you sure you want to delete ${item?.name}?`,
            okText: "Yes",
            cancelText: "No",
            icon: <ExclamationCircleOutlined />,
            onOk() {
                const payload = {
                    selectedBy: seller === "BUYER" ? "BUYER_AGENT" : "SELLER_AGENT",
                    offerId: getIndividualTransactionData?.offerId || null,
                    inBuilt: true
                };
                if (source === "Affiliate") {
                    const affiliates = getTitleCompanySelectedData?.affiliateDetails?.affiliates || [];
                    const updatedAffiliates = affiliates.filter((el) => el?._id !== item?._id);

                    dispatch(transactionLaneDataHandle.addTitleOffice({
                        payload,
                        delegateSide,
                        delegateOwnerId: delegateSide && getIndividualTransactionData?.currentAgentId?._id,
                        affiliateArr: updatedAffiliates,
                        category: "deleteAffiliate",
                        data:item
                    }));
                } else {
                    const processors = getTitleCompanySelectedData?.processors || [];
                    const updatedProcessors = processors.filter((el) => el?._id !== item?._id);

                    dispatch(transactionLaneDataHandle.addTitleOffice({
                        payload,
                        delegateSide,
                        delegateOwnerId: delegateSide && getIndividualTransactionData?.currentAgentId?._id,
                        processorArr: updatedProcessors,
                        category: "deleteProcessor",
                        data: item
                    }));
                }
            },
            onCancel() { }
        })
    };


    const checkForDisable = checkIfDisabled({ openFor: seller, actualPropertyStatus: getIndividualTransactionData?.actualPropertyStatus, buyingStatus: getIndividualTransactionData?.buyingStatus, offerStatus: getIndividualTransactionData?.status });

    const handleClear = ({ renderWithClear }) => {
        form.resetFields();
        setFilters({
            search: ""
        })
    }

    //debounce logic to call search api
    const debouncedFunction = _.debounce((value) => {
        setFilters({
            search: value
        })
    }, 1000);

    const handleSearch = (event) => {
        const value = event.target.value;
        debouncedFunction(value);
    };


    return (
        <div className={styles.formsAndAffiliatesOuterCont}>
            <Tabs
                onChange={handleChange}
                type="card"
                defaultActiveKey="1"
            >
                <Tabs.TabPane
                    tab={<span className={styles.TabPaneTitle}>Forms</span>}
                    key="1"
                    animated
                >

                    <div className={styles.documentCont}>{
                        <Row>
                            <Row style={{ width: "100%", flexWrap: "nowrap", justifyContent: "center", gap: 5 }}>
                                <Col className={styles.searchInputBoxCol}>
                                    <div className={styles.searchInputBox}>
                                        <Form form={form} initialValues={{}}>
                                            <Form.Item name="search">
                                                <Input
                                                    allowClear
                                                    placeholder="Search here..."
                                                    suffix={<SearchOutlined />}
                                                    onChange={handleSearch}
                                                    size="middle"
                                                />
                                            </Form.Item>
                                        </Form>
                                    </div>
                                </Col>
                                <Col>
                                    <div
                                        className={styles.sortBox}
                                    >
                                        <Button
                                            style={{
                                                background: "#3a74b6",
                                                color: "whitesmoke",
                                                fontWeight: "bold",
                                                border: "none",
                                                padding: "0 10px"
                                            }}
                                            onClick={handleClear}
                                        >
                                            <div>Reset</div>
                                        </Button>
                                    </div>
                                </Col>
                            </Row>
                            {
                                getDoraDocumentsLoading ?
                                    <div style={{ height: "100%", width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                        <Spin size="large" />
                                    </div> :
                                    (getDoraDocumentsError ? "" :
                                        <Row className={styles.documentScroll}>
                                            <Col
                                                span={24}
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "flex-start",
                                                    overflowY: "auto",
                                                    paddingLeft: "10px",
                                                }}
                                            >
                                                <Tree>
                                                    {totratlast()?.length ?
                                                        totratlast().map((item) => {
                                                            if (item.doc_second.length > 0) {
                                                                return (
                                                                    <Tree.Folder key={item._id} name={item.name} style={{ cursor: "pointer" }} getTransactionLaneData={getTransactionLaneData} fiters={fiters}>
                                                                        {item.doc_second.map((element) => {
                                                                            return <Tree.File key={element._id} doraDocItem={element} selectedData={getIndividualTransactionData} seller={seller} getTransactionLaneData={getTransactionLaneData} insideFolder={true} section={section} />;
                                                                        })}
                                                                    </Tree.Folder>
                                                                );
                                                            } else {
                                                                return <Tree.File key={item._id} doraDocItem={item} selectedData={getIndividualTransactionData} seller={seller} getTransactionLaneData={getTransactionLaneData} insideFolder={false} section={section} />;
                                                            }
                                                        }) : ""}
                                                </Tree>
                                            </Col>
                                        </Row>
                                    )
                            }

                        </Row>
                    }
                    </div>


                </Tabs.TabPane>
                <Tabs.TabPane
                    tab={<span className={styles.TabPaneTitle}>Affiliates</span>}
                    key="2"
                    animated
                >
                    <Collapse
                        ghost
                        expandIcon={({ isActive }) => <div style={{ verticalAlign: "middle" }}><CaretRightOutlined rotate={isActive ? 90 : 0} style={{ fontSize: "1.3rem" }} /></div>}
                        onChange={handleChange}
                        activeKey={activeKey}
                    >

                        {((getIndividualTransactionData?.status === "ACCEPTED") || (getIndividualTransactionData?.status === "TERMINATED") || (getIndividualTransactionData?.status === "DRAFT") || (getIndividualTransactionData?.status === "SUBMITTED")) &&
                            <Panel
                                style={{ marginTop: "10px", border: "1px solid #cccccc", borderRadius: "10px" }}
                                key="2"
                                expandIcon={({ isActive }) => <div style={{ verticalAlign: "middle" }}><CaretRightOutlined rotate={isActive ? 90 : 0} style={{ fontSize: "1.3rem" }} /></div>}
                                header={
                                    <div style={{ width: "100%", display: "flex", justifyContent: "space-between" }} onClick={(e) => e.stopPropagation()}>
                                        <span
                                            style={{
                                                fontSize: "20px",
                                                fontWeight: "bold",
                                                marginLeft: ".3rem",
                                                color: "#096dd9",
                                            }}
                                        >
                                            Title Company
                                        </span>
                                        {(activeKey === "2" || (Array.isArray(activeKey) && activeKey.length === 1 && activeKey.includes("2")) || Array.isArray(activeKey) && activeKey.length === 2) && <span>
                                            <PlusCircleFilled
                                                {...(checkForDisable?.disabled ? { onClick: disabledTransactionText } : { onClick: handleOpen })}
                                                style={{ fontSize: "25px", color: "#085190" }}
                                            />
                                        </span>}
                                    </div>
                                }
                            >
                                <div style={{
                                    minHeight: "200px",
                                    maxHeight:
                                        activeKey === "2" || (Array.isArray(activeKey) && activeKey.length === 1 && activeKey.includes("2"))
                                            ? "calc(60vh - 4rem)"
                                            : Array.isArray(activeKey) && activeKey.length === 2
                                                ? (screens.xl || screens.xxl) ? "calc(60vh - 16rem)" : (screens.md || screens.lg) ? "calc(60vh - 13.5rem)" : (screens.sm || screens.xs) ? "calc(60vh - 14rem)" : "calc(60vh - 12rem)"
                                                : "none",
                                    overflowY: "auto"
                                }}>
                                    {
                                        getTitleCompanyDataLoading ? <div style={{ minHeight: "200px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                            <Spin size="large" />
                                        </div> : (
                                            getTitleCompanyDataError ? "" : (
                                                getTitleCompanySelectedData && getTitleCompanySelectedData?.titleCompanyName && (
                                                    <div style={{ padding: "6px 18px 18px", display: "flex", flexDirection: 'column', color: "gray" }} >
                                                        <div
                                                            style={{
                                                                margin: "auto"
                                                            }}
                                                        >
                                                            {
                                                                getTitleCompanySelectedData?.titleCompanyLogo &&
                                                                <img
                                                                    src={getTitleCompanySelectedData?.titleCompanyLogo}
                                                                    style={{
                                                                        height: "60px",
                                                                        width: "80px",
                                                                        objectFit: "contain",
                                                                        maxWidth: "100%"
                                                                    }}
                                                                    alt=""
                                                                />
                                                            }
                                                        </div>
                                                        <div className={styles.titleCompanyDetailCont}>
                                                            <table className={styles.titleCompanyDetailTable}>
                                                                {
                                                                    getTitleCompanySelectedData?.titleCompanyName &&
                                                                    <tr>
                                                                        <td className={styles.label}>Company</td>
                                                                        <td className={styles.value}>{getTitleCompanySelectedData?.titleCompanyName}</td>
                                                                    </tr>
                                                                }
                                                                {
                                                                    getTitleCompanySelectedData?.titleOfficeName &&
                                                                    <tr>
                                                                        <td className={styles.label}>Office</td>
                                                                        <td className={styles.value}>{getTitleCompanySelectedData?.titleOfficeName}</td>
                                                                    </tr>
                                                                }
                                                                {
                                                                    getTitleCompanySelectedData?.titleOfficeAddress &&
                                                                    <tr>
                                                                        <td className={styles.label}>Office Address</td>
                                                                        <td className={styles.value}>{getTitleCompanySelectedData?.titleOfficeAddress}</td>
                                                                    </tr>
                                                                }
                                                                {
                                                                    getTitleCompanySelectedData?.titleOfficeEmail &&
                                                                    <tr>
                                                                        <td className={styles.label}>Office Email</td>
                                                                        <td className={styles.value}>{getTitleCompanySelectedData?.titleOfficeEmail}</td>
                                                                    </tr>
                                                                }
                                                                {
                                                                    getTitleCompanySelectedData?.titleOfficePhoneNo &&
                                                                    <tr>
                                                                        <td className={styles.label}>Office Phone</td>
                                                                        <td className={styles.value}>{getTitleCompanySelectedData?.titleOfficePhoneNo}</td>
                                                                    </tr>
                                                                }
                                                                {
                                                                    getTitleCompanySelectedData?.titleOfficeFaxNo &&
                                                                    <tr>
                                                                        <td className={styles.label}>Office Fax No.</td>
                                                                        <td className={styles.value}>{getTitleCompanySelectedData?.titleOfficeFaxNo}</td>
                                                                    </tr>
                                                                }

                                                                {
                                                                    getTitleCompanySelectedData?.salesRepName &&
                                                                    <tr>
                                                                        <td className={styles.label}>Sales Rep</td>
                                                                        <td className={styles.value}>{getTitleCompanySelectedData?.salesRepName ? getTitleCompanySelectedData?.salesRepName : ""}</td>
                                                                    </tr>
                                                                }
                                                                {
                                                                    getTitleCompanySelectedData?.salesRepEmail &&
                                                                    <tr>
                                                                        <td className={styles.label}>Sales Rep Email</td>
                                                                        <td className={styles.value}>{getTitleCompanySelectedData?.salesRepEmail ? getTitleCompanySelectedData?.salesRepEmail : ""}</td>
                                                                    </tr>
                                                                }
                                                                {
                                                                    getTitleCompanySelectedData?.salesRepPhone &&
                                                                    <tr>
                                                                        <td className={styles.label}>Sales Rep Phone</td>
                                                                        <td className={styles.value}>{getTitleCompanySelectedData?.salesRepPhone ? getTitleCompanySelectedData?.salesRepPhone : ""}</td>
                                                                    </tr>
                                                                }
                                                                {
                                                                    getTitleCompanySelectedData?.titleCloserName &&
                                                                    <tr>
                                                                        <td className={styles.label}>Title Closer</td>
                                                                        <td className={styles.value}>{getTitleCompanySelectedData?.titleCloserName}</td>
                                                                    </tr>
                                                                }
                                                                {
                                                                    getTitleCompanySelectedData?.titleCloserEmail &&
                                                                    <tr>
                                                                        <td className={styles.label}>Title Closer Email</td>
                                                                        <td className={styles.value}>{getTitleCompanySelectedData?.titleCloserEmail}</td>
                                                                    </tr>
                                                                }
                                                                {
                                                                    getTitleCompanySelectedData?.titleCloserPhone &&
                                                                    <tr>
                                                                        <td className={styles.label}>Title Closer Phone</td>
                                                                        <td className={styles.value}>{getTitleCompanySelectedData?.titleCloserPhone}</td>
                                                                    </tr>
                                                                }
                                                            </table>
                                                            <div>
                                                                <span style={{ color: "#2875be", fontWeight: "bold", fontSize: "20px", margin: "auto" }}>Processors
                                                                    <PlusCircleFilled
                                                                        style={{
                                                                            fontSize: "18px",
                                                                            color: getTitleCompanySelectedData &&
                                                                                getTitleCompanySelectedData.titleCompanyName &&
                                                                                (companyModalStep.selectedBy !== getTitleCompanySelectedData?.selectedBy)
                                                                                ? "#c2c2c2"
                                                                                : "#085190",
                                                                            margin: "10px 0 0 5px",
                                                                            cursor: getTitleCompanySelectedData &&
                                                                                getTitleCompanySelectedData.titleCompanyName &&
                                                                                (companyModalStep.selectedBy !== getTitleCompanySelectedData?.selectedBy)
                                                                                ? "not-allowed"
                                                                                : "pointer"
                                                                        }}
                                                                        onClick={
                                                                            getTitleCompanySelectedData &&
                                                                                getTitleCompanySelectedData.titleCompanyName &&
                                                                                (companyModalStep.selectedBy !== getTitleCompanySelectedData?.selectedBy)
                                                                                ? undefined
                                                                                : handleAddProcessor
                                                                        }
                                                                    />
                                                                </span>
                                                                {getTitleCompanySelectedData?.processors?.length > 0 && <div>
                                                                    {getTitleCompanySelectedData?.processors?.map((el, ind) => {
                                                                        return <div style={{ display: "flex", gap: "5px", padding: "6px 0", borderBottom: ind !== getTitleCompanySelectedData?.processors?.length - 1 ? "1px solid #a3a3a3" : "" }}>
                                                                            <div
                                                                                style={{
                                                                                    display: "grid",
                                                                                    gridTemplateColumns: "80px auto",
                                                                                    rowGap: "6px",
                                                                                    width: "100%",
                                                                                }}
                                                                            >
                                                                                <span style={{ fontWeight: "600", color: "#2976be" }}>Name</span>
                                                                                <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                                                    <span>{el?.name}</span>
                                                                                    <span>
                                                                                        <DeleteOutlined
                                                                                            style={{
                                                                                                color: getTitleCompanySelectedData &&
                                                                                                    getTitleCompanySelectedData.titleCompanyName &&
                                                                                                    (companyModalStep.selectedBy !== getTitleCompanySelectedData?.selectedBy)
                                                                                                    ? "#c2c2c2"
                                                                                                    : "#2976be",
                                                                                                fontSize: "15px",
                                                                                                cursor: getTitleCompanySelectedData &&
                                                                                                    getTitleCompanySelectedData.titleCompanyName &&
                                                                                                    (companyModalStep.selectedBy !== getTitleCompanySelectedData?.selectedBy)
                                                                                                    ? "not-allowed"
                                                                                                    : "pointer"
                                                                                            }}
                                                                                            onClick={
                                                                                                getTitleCompanySelectedData &&
                                                                                                    getTitleCompanySelectedData.titleCompanyName &&
                                                                                                    (companyModalStep.selectedBy !== getTitleCompanySelectedData?.selectedBy)
                                                                                                    ? undefined
                                                                                                    : () => handleDelete(el, "Processor")
                                                                                            }
                                                                                        />
                                                                                    </span>
                                                                                </div>

                                                                                <span style={{ fontWeight: "600", color: "#2976be" }}>Email</span>
                                                                                <span style={{
                                                                                    whiteSpace: "nowrap",
                                                                                    overflow: "hidden",
                                                                                    textOverflow: "ellipsis",
                                                                                    display: "block",
                                                                                }}>{el?.email}</span>

                                                                                {el?.phoneNumber && (
                                                                                    <>
                                                                                        <span style={{ fontWeight: "600", color: "#2976be" }}>Phone</span>
                                                                                        <span>
                                                                                            {formatPhoneNumber(el?.phoneNumber)}
                                                                                        </span>
                                                                                    </>
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                    })}
                                                                </div>}
                                                            </div>
                                                        </div>
                                                        <div style={{ textAlign: "center", maxWidth: "100%", marginTop: "10px", borderRadius: "8px", }}>
                                                            <Button
                                                                type="primary"
                                                                disabled={((seller === "SELLER" && getTitleCompanySelectedData?.selectedBy === "SELLER_AGENT") || (seller === "BUYER" && getTitleCompanySelectedData?.selectedBy === "BUYER_AGENT")) ? false : true}
                                                                {...(checkForDisable?.disabled ? { onClick: disabledTransactionText } : { onClick: handeldeleteTitleCompanydata })}
                                                            >
                                                                Clear
                                                            </Button>
                                                            <Button
                                                                type="primary"
                                                                style={{ marginLeft: "5px" }}
                                                                disabled={!getTitleCompanySelectedData?.titleCloserEmail && !getTitleCompanySelectedData?.titleOfficeEmail && !getTitleCompanySelectedData?.salesRepEmail}
                                                                onClick={handleInnerClick}
                                                            >
                                                                Send
                                                            </Button>
                                                        </div>
                                                    </div>
                                                )
                                            )
                                        )
                                    }
                                </div>
                            </Panel>
                        }
                    </Collapse>

                    <Collapse ghost
                        expandIcon={({ isActive }) => <div style={{ verticalAlign: "middle" }}><CaretRightOutlined rotate={isActive ? 90 : 0} style={{ fontSize: "1.3rem" }} /></div>}
                        onChange={handleChange}
                        activeKey={activeKey}
                    >
                        <Panel
                            style={{ marginTop: "10px", border: "1px solid #cccccc", borderRadius: "10px" }}
                            key="3"
                            expandIcon={({ isActive }) => <div style={{ verticalAlign: "middle" }}><CaretRightOutlined rotate={isActive ? 90 : 0} style={{ fontSize: "1.3rem" }} /></div>}
                            header={
                                <div style={{ width: "100%", display: "flex", justifyContent: "space-between" }} onClick={(e) => e.stopPropagation()}>
                                    <span
                                        style={{
                                            fontSize: "20px",
                                            fontWeight: "bold",
                                            marginLeft: ".3rem",
                                            color: "#096dd9",
                                        }}
                                    >
                                        Other
                                    </span>
                                    <span>
                                    <PlusCircleFilled
                                                {...(checkForDisable?.disabled ? { onClick: disabledTransactionText } : { onClick: handleAddAffilatesModalOpen })}
                                                style={{ fontSize: "25px", color: "#085190" }}
                                            />
                                    </span>
                                </div>
                            }
                        >
                            <div style={{
                                maxHeight: activeKey === "2" || (Array.isArray(activeKey) && activeKey.length === 1 && activeKey.includes("3"))
                                    ? "calc(60vh - 2rem)"
                                    : Array.isArray(activeKey) && activeKey.length === 2
                                        ? (screens.xl || screens.xxl) ? "calc(60vh - 18rem)" : (screens.md || screens.lg) ? "calc(60vh - 15rem)" : (screens.sm || screens.xs) ? "calc(60vh - 17rem)" : "calc(60vh - 15rem)"
                                        : "none", overflowY: "auto", padding: "6px 18px 18px"
                            }}>
                                {
                                    getTitleCompanyDataLoading ? <div style={{ minHeight: "200px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                        <Spin size="large" />
                                    </div> : getTitleCompanyDataError ? "" : getTitleCompanySelectedData && getTitleCompanySelectedData?.affiliateDetails && getTitleCompanySelectedData?.affiliateDetails?.affiliates?.length > 0 && <>
                                        <div>
                                            {getTitleCompanySelectedData?.affiliateDetails && getTitleCompanySelectedData?.affiliateDetails?.affiliates?.length > 0 && <div>
                                                {getTitleCompanySelectedData?.affiliateDetails?.affiliates?.map((el, ind) => {
                                                    return <div style={{ display: "flex", gap: "5px", padding: "5px 0", color: "#808080", fontWeight: "500", borderBottom: ind !== getTitleCompanySelectedData?.affiliateDetails?.affiliates?.length - 1 ? "1px solid #a3a3a3" : "" }}>
                                                        <div
                                                            style={{
                                                                display: "grid",
                                                                gridTemplateColumns: "80px auto",
                                                                rowGap: "6px",
                                                                width: "100%",
                                                            }}
                                                        >
                                                            <span style={{ fontWeight: "600", color: "#2976be" }}>Name</span>
                                                            <div style={{ display: "flex", justifyContent: "space-between" }}><span>{el?.name}</span>
                                                                {getTitleCompanySelectedData?.affiliateDetails?.affiliates?.length > 1 && <span>
                                                                    <DeleteOutlined
                                                                        style={{
                                                                            color: getTitleCompanySelectedData && getTitleCompanySelectedData?.affiliateDetails && getTitleCompanySelectedData?.affiliateDetails?.selectedBy !== companyModalStep.selectedBy
                                                                                ? "#d9d9d9"
                                                                                : "#2976be",
                                                                            fontSize: "15px",
                                                                            cursor: getTitleCompanySelectedData && getTitleCompanySelectedData?.affiliateDetails && getTitleCompanySelectedData?.affiliateDetails?.selectedBy !== companyModalStep.selectedBy
                                                                                ? "not-allowed"
                                                                                : "pointer"
                                                                        }}
                                                                        onClick={
                                                                            getTitleCompanySelectedData && getTitleCompanySelectedData?.affiliateDetails && getTitleCompanySelectedData?.affiliateDetails?.selectedBy !== companyModalStep.selectedBy
                                                                                ? undefined
                                                                                : () => handleDelete(el, "Affiliate")
                                                                        }
                                                                    />
                                                                </span>}
                                                            </div>

                                                            <span style={{ fontWeight: "600", color: "#2976be" }}>Email</span>
                                                            <span style={{
                                                                whiteSpace: "nowrap",
                                                                overflow: "hidden",
                                                                textOverflow: "ellipsis",
                                                                display: "block",
                                                            }}>{el?.email}</span>

                                                            {el?.role && (
                                                                <>
                                                                    <span style={{ fontWeight: "600", color: "#2976be" }}>Role/Title</span>
                                                                    <span>
                                                                        {el?.role}
                                                                    </span>
                                                                </>
                                                            )}

                                                            {el?.phoneNumber && (
                                                                <>
                                                                    <span style={{ fontWeight: "600", color: "#2976be" }}>Phone</span>
                                                                    <span>
                                                                        {formatPhoneNumber(el?.phoneNumber)}
                                                                    </span>
                                                                </>
                                                            )}
                                                        </div>
                                                    </div>
                                                })}
                                            </div>}
                                        </div>

                                        <div style={{ textAlign: "center", maxWidth: "100%", margin: "10px 0", borderRadius: "8px", }}>
                                            <Button
                                                type="primary"
                                                disabled={((seller === "SELLER" && getTitleCompanySelectedData?.affiliateDetails?.selectedBy === "SELLER_AGENT") || (seller === "BUYER" && getTitleCompanySelectedData?.affiliateDetails?.selectedBy === "BUYER_AGENT")) ? false : true}
                                                {...(checkForDisable?.disabled ? { onClick: disabledTransactionText } : { onClick: () => handeldeleteTitleCompanydata("affiliate") })}
                                            >
                                                Clear
                                            </Button>
                                            <Button
                                                type="primary"
                                                style={{ marginLeft: "5px" }}
                                                disabled={!getTitleCompanySelectedData?.affiliateDetails && !getTitleCompanySelectedData?.affiliateDetails?.affiliates?.length}
                                                onClick={handleInnerClick}
                                            >
                                                Send
                                            </Button>
                                        </div>
                                    </>}
                            </div>
                        </Panel>

                    </Collapse>

                </Tabs.TabPane>
            </Tabs>

            {
                showcompanymodal && <TitleCompanyDetails
                    section={section}
                    setShowcompanymodal={setShowcompanymodal}
                    showcompanymodal={showcompanymodal}
                    selectedStatus={seller}
                    companyModalStep={companyModalStep}
                    setCompanyModalStep={setCompanyModalStep}
                />
            }
            {
                sendCompanyModal && <TitleCompanyDocument selectedStatus={seller} selectedData={getIndividualTransactionData} setSendCompanyModal={setSendCompanyModal} section={section} />
            }

            {add?.open && <AddCloserModal add={add} setAdd={setAdd} handleAdd={handleAdd} onFinish={onFinish} companyModalStep={companyModalStep} setCompanyModalStep={setCompanyModalStep} selectedStatus={seller} />}
        </div>
    );
}
