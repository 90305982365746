import React from 'react'
import { useDispatch } from "react-redux";
import { PropertyHandlers } from "../state/actions";
import PropertyDocFile from './PropertyDocFile';
import PropertyDocFolder from './PropertyDocFolder';
import { message } from 'antd';


const PropertyDocsTable = ({ propertyDocs, role, item, handleOPenDocs, handlePropertyDocs, handleShareUnshareFileConfirm, handleToggleDashboard, handleDeletePropertyDocs, isSmallScreen, propertyDocsWithType, setPropertyDocsWithType, folderName, setFolderName, delegate, setCreateFolderModalOpen, createFolderModalOpen, agentDetails, selectedFiles, setSelectedFiles, handleCreateAmendExtend }) => {
    const dispatch = useDispatch();


    const handleDragStart = (event, file,) => {
        event.dataTransfer.setData("file", JSON.stringify(file));
    };

    const handleDrop = (event, folder) => {
        event.preventDefault();
        event.stopPropagation(); 
        if (folder.isCustomerFolder) {
            return message.error("Cannot add files to customer folder.");
        }
        // Retrieve the dragged file data
        const draggedFileData = event.dataTransfer.getData("file");
        const draggedFile = JSON.parse(draggedFileData);
        let folderData = {
            ...(draggedFile?.hasOwnProperty('isShared') ? {propertyDocsIds: [{propertyDocId : draggedFile?._id, selectedParentId: draggedFile?.parentFolderId || null}]} : {offerDocsIds: [{offerDocId : draggedFile?._id, selectedParentId: draggedFile?.parentFolderId || null}]}),
            folderId: folder?._id,
            storageType: "FILE",
            folderAction: "ADD",
            openFor: item?.isProperty || item?.isErts ? "SELLER_AGENT" : "BUYER_AGENT",
        };
        dispatch(PropertyHandlers.updatePropertyDocsFolderFun({ folderData }));
    };

    const handleDragOver = (event) => {
        event.preventDefault();
        event.stopPropagation(); 
    };

    return (
        <table className="licenseTable" style={{ width: "100%", borderTop: 0 }}>
            <thead style={{ position: "sticky", top: 0, zIndex: 1, background: "white" }}>
                <tr style={{ position: "sticky", top: 0, zIndex: 2 }}>
                    <th>Files/Folders</th>
                    <th>Creation Date</th>
                    <th>Shared Folder</th>
                    <th>Show in dashboard</th>
                    <th>Options</th>
                </tr>
            </thead>
            <tbody>
                {propertyDocs?.map((ele, index) => {
                    if (ele?.storageType === "FILE" && !ele?.folderId) {
                        return <PropertyDocFile
                            ele={ele}
                            documents={propertyDocs}
                            propertyDocsWithType={propertyDocsWithType}
                            setPropertyDocsWithType={setPropertyDocsWithType}
                            role={role}
                            item={item}
                            handleOPenDocs={handleOPenDocs}
                            handlePropertyDocs={handlePropertyDocs}
                            handleShareUnshareFileConfirm={handleShareUnshareFileConfirm}
                            handleToggleDashboard={handleToggleDashboard}
                            handleDeletePropertyDocs={handleDeletePropertyDocs}
                            isSmallScreen={isSmallScreen}
                            folderName={folderName}
                            setFolderName={setFolderName}
                            handleDragStart={handleDragStart}
                            selectedFiles={selectedFiles}
                            setSelectedFiles={setSelectedFiles}
                            source={"Normal"}
                            handleCreateAmendExtend={handleCreateAmendExtend}
                        />

                    } else if (ele?.storageType === "FOLDER") {
                        return <PropertyDocFolder
                            ele={ele}
                            documents={propertyDocs}
                            propertyDocsWithType={propertyDocsWithType}
                            setPropertyDocsWithType={setPropertyDocsWithType}
                            role={role}
                            item={item}
                            handleOPenDocs={handleOPenDocs}
                            handlePropertyDocs={handlePropertyDocs}
                            handleShareUnshareFileConfirm={handleShareUnshareFileConfirm}
                            handleToggleDashboard={handleToggleDashboard}
                            handleDeletePropertyDocs={handleDeletePropertyDocs}
                            isSmallScreen={isSmallScreen}
                            folderName={folderName}
                            setFolderName={setFolderName}
                            handleDragOver={handleDragOver}
                            handleDrop={handleDrop}
                            delegate={delegate}
                            setCreateFolderModalOpen={setCreateFolderModalOpen}
                            createFolderModalOpen={createFolderModalOpen}
                            agentDetails={agentDetails}
                            selectedFiles={selectedFiles}
                            setSelectedFiles={setSelectedFiles}
                            handleDragStart={handleDragStart}
                            handleCreateAmendExtend={handleCreateAmendExtend}
                        />
                    }
                    return null
                })
                }
            </tbody>
        </table>
    );
};

export default PropertyDocsTable
