export const clientScreenConstants = {
    CLIENT_SCREEN_LOADING: "CLIENT_SCREEN_LOADING",
    CLIENT_SCREEN_ERROR: "CLIENT_SCREEN_ERROR",
    CLIENT_SCREEN_SUCCESS: "CLIENT_SCREEN_SUCCESS",

    EMAIL_FILES_LOADING: " EMAIL_FILES_LOADING",
    EMAIL_FILES_ERROR: " EMAIL_FILES_ERROR",
    EMAIL_FILES_SUCCESS: " EMAIL_FILES_SUCCESS",

    UPLOAD_DOCUMENT_LOADING: "UPLOAD_DOCUMENT_LOADING",
    UPLOAD_DOCUMENT_SUCCESS: "UPLOAD_DOCUMENT_SUCCESS",
    UPLOAD_DOCUMENT_FAILURE: "UPLOAD_DOCUMENT_FAILURE",

    CLIENT_SCREEN_DELETED_DOC_SUCCESS: "CLIENT_SCREEN_DELETED_DOC_SUCCESS",

    GET_SHARED_CALENDAR_SUCCESS: "GET_SHARED_CALENDAR_SUCCESS",
    GET_SHARED_CALENDAR_ERROR: "GET_SHARED_CALENDAR_ERROR",
    GET_SHARED_CALENDAR_LOADING: "GET_SHARED_CALENDAR_LOADING",
    LINK_NOT_EXISTS: "LINK_NOT_EXISTS",
    SET_AUTH_KEY_FOR_READONLY_CLIENT: "SET_AUTH_KEY_FOR_READONLY_CLIENT",

  };