import React from "react";
import { Menu } from "antd";
import { FileAddOutlined, DeleteTwoTone, SendOutlined } from "@ant-design/icons";
import { checkForDeleteClientDoc } from "../utils/helper";

const ClientDocsMenuItem = ({ createdDocItem, parentClientdocItem, createNewDocument, handleViewAmendExtend, handleDeleteConfirm, setCurrentDocData, setNestedModalOpen, source }) => {
  let canDelete = checkForDeleteClientDoc(createdDocItem);
  return (
    <Menu size="large">
      {!["ERTL", "ETCT"].includes(parentClientdocItem.contractType) && parentClientdocItem?.doc_second?.length > 0 && source === "PARENT_DOC" ? (
        <Menu.Item
          key="CreateLCAE"
          onClick={(event) => {
            createNewDocument({ createdDocItem, docSecondItem: parentClientdocItem?.doc_second?.[0] });
          }}
        >
          <FileAddOutlined
            style={{
              fontSize: 16,
              color: "#0088cc",
            }}
          />

          <span style={{ color: "04396B" }}> Create Amend/Extend</span>
        </Menu.Item>
      ):
      ["ERTL", "ETCT"].includes(parentClientdocItem.contractType) && parentClientdocItem?.doc_second?.length > 0  && source === "PARENT_DOC" ? 
        <Menu.Item
          key="CreateLCAE"
          onClick={(event) => {
            createNewDocument({ createdDocItem, docSecondItem: parentClientdocItem?.doc_second?.[0] });
          }}
        >
          <FileAddOutlined
            style={{
              fontSize: 16,
              color: "#0088cc",
            }}
          />

          <span style={{ color: "04396B" }}> Create Amend/Extend</span>
        </Menu.Item>
        : <></>
      }

      {canDelete && (
        <Menu.Item
          key="deleteDoc"
          onClick={() => {
            handleDeleteConfirm(createdDocItem?._id, "document");
          }}
        >
          <DeleteTwoTone
            style={{
              fontSize: 16,
            }}
          />
          <span style={{ color: "04396B" }}> Delete Document</span>
        </Menu.Item>
      )}
      <Menu.Item
        key="sendEmailToClients"
        onClick={(event) => {
          setCurrentDocData(createdDocItem);
          setNestedModalOpen({
            isEmailModalVisible: true,
            sendMultipleDoc:true,
            isclearTransactionLaneData:false
            
          });
        }}
      >
        <SendOutlined
          style={{
            fontSize: 16,
            color: "#0088cc",
          }}
        />
        <span style={{ color: "04396B" }}> Send Document</span>
      </Menu.Item>
    </Menu>
  );
};

export default ClientDocsMenuItem;
