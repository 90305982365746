import React from 'react';
import { Avatar, Form, Typography, Input, Select, Image, Popconfirm, Button, } from 'antd';
import { MailOutlined, PhoneOutlined, CloseCircleOutlined, InboxOutlined, MessageOutlined,  } from '@ant-design/icons';
import Dragger from 'antd/lib/upload/Dragger';
import { useState } from 'react';
import { useRef } from 'react';
import { useEffect } from 'react';
import { bugReportHandle } from '../../BugReport/state/actions';
import { useDispatch, useSelector } from 'react-redux';
import { landingPageAction } from '../state/actions';
import { useMediaQuery } from 'react-responsive';
import { roleAccessFunc } from '../../Common/utils/extraFunctions';

const SupportPage = () => {
    const Text = Typography;
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const imageFormdataRef = useRef([]);
    const [role,setRole] = useState(false);
    const [clientID,setClientID] = useState("")
    const [bufferData, setbufferData] = useState([]);
    const { userName,} = useSelector((state) => state.bugReport);
    const { getUserDetailsData,roleAccessed } = useSelector((state) => state.account);
    const inputRef=useRef(null)
    const isMobile = useMediaQuery({maxWidth:900})

    const formItemLayout = {
        labelCol: {
          xs: {
            span: 24,
          },
          sm: {
            span: 24,
          },
        },
        wrapperCol: {
          xs: {
            span: 24,
          },
          sm: {
            span: 24,
          },
        },
      };
      const tailFormItemLayout = {
        wrapperCol: {
            xs: {
              span: 24,
              offset: 0,
            },
            sm: {
              span: 24,
              offset: 0,
            },
          },
      };

      const priorityOptions = [
        {value:"LOW",label:"Low"},
        {value:"MEDIUM",label:"Medium"},
        {value:"HIGH",label:"High"},
        {value:"CRITICAL",label:"Critical"},
      ]

      const handleSupport =(values)=>{
        const updatedValue = (roleAccessFunc(roleAccessed, "BASIC_BUGS_SUPPORT_ACCESS")) || role ? {...values,assignedTo:clientID} : values
        let imageBuffers = imageFormdataRef.current;
        dispatch(bugReportHandle.uploadImageforBugNew(imageBuffers, "notBase64", updatedValue, userName,"SUPPORT",true,"fromHome"));
        dispatch(landingPageAction.renderSupportComponant('CONTACT'));
        imageFormdataRef.current = [];
        setbufferData([]);
        form?.resetFields();
    }

    function deleteImg(index){
        let filterData = bufferData.filter((el,i)=>(i!==index));
        setbufferData(filterData);
        const imageFormdataCopy = [...imageFormdataRef.current]; // Create a shallow copy of the array
        imageFormdataCopy.splice(index, 1); // Remove one element at the specified index
        imageFormdataRef.current = imageFormdataCopy;
        }

        function bytesToSize(bytes) {
            let sizes = ["Bytes", "KB", "MB", "GB", "TB"];
            if (bytes === 0) return "0 Byte";
            let i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
            return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
          }

        let handleCustome = (data) => {
            const reader = new FileReader();
            reader.readAsDataURL(data.file);
            reader.onload = () => {
              const newFile = {
                name: data.file.name,
                size: bytesToSize(data.file.size),
                file: reader.result,
                origin: "notBase64",
              };
              setbufferData((prevFiles) =>{
                if (prevFiles.length) {
                    return [...prevFiles, newFile];
                  } else {
                    return [newFile];
                  }
            });
            };
            reader.onerror = (error) => {
              return error;
            };
            imageFormdataRef.current = [...imageFormdataRef.current,data.file]
          };

        let handleFilesSelected = (event) => {
            const selectedFiles = Array.from(event.target.files);
            selectedFiles.forEach((file) => {
              const reader = new FileReader();
              reader.readAsDataURL(file);
              reader.onload = () => {
                const newFile = {
                  name: file.name,
                  size: bytesToSize(file.size),
                  file: reader.result,
                  origin: "notBase64",
                };
                setbufferData((prevFiles) => [...prevFiles, newFile]);
              };
              reader.onerror = (error) => {
                return error;
              };
              imageFormdataRef.current = [...imageFormdataRef.current,file]
            });
          };

    let handleAddFile = () => {
        const input = document.createElement("input");
        input.type = "file";
        input.accept = "image/*";
        input.multiple = true;
        input.addEventListener("change", handleFilesSelected);
        input.click();
      };

      function beforeUpload(file) {
        const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
        if (!isJpgOrPng) {
          throw new Error("You can only upload JPG/PNG file!");
        }
        const isLt5M = file.size / 1024 / 1024 < 20;
        if (!isLt5M) {
          throw new Error("Image must be smaller than 20MB!");
        }
        return isJpgOrPng && isLt5M;
      }


      useEffect(() => {
        for(let key in getUserDetailsData?.roles){
          if(getUserDetailsData?.roles[key] === "RESURE_SUPPORT"){
            setRole(true);
            break;
          }else{
            setRole(false);
          }
        }
      },[getUserDetailsData?.roles])

      useEffect(() => {
        let handlePasteEvent = (event) => {
          let count = 0;
          var items = (event.clipboardData || event.originalEvent.clipboardData)
            .items;
          for (let index in items) {
            var item = items[index];
            if (item.kind === "file" && count === 0) {
              var blob = item.getAsFile();
              var reader = new FileReader();
              const ide = Math.floor(10000 + Math.random() * 90000);
              const imageName = "pastedImage" + ide;
              var updatedBlob = new File([blob], imageName, { type: blob.type });
              let checkImage = beforeUpload(updatedBlob);
              if (checkImage) {
                reader.onload = () => {
                  const newFile = {
                    name: updatedBlob.name,
                    size: bytesToSize(blob.size),
                    file: reader.result,
                    origin: "notBase64",
                  };
                  setbufferData((prevFiles) =>{

                    if (prevFiles.length) {
                        return [...prevFiles, newFile];
                      } else {
                        return [newFile];
                      }
                });
                };
                imageFormdataRef.current = [...imageFormdataRef.current,updatedBlob]
              }
              reader.readAsDataURL(blob);
              count = +count + 1;
            }
          }
        };
        let getModal = document;
        getModal.addEventListener("paste", handlePasteEvent);
        return () => {
          getModal.removeEventListener("paste", handlePasteEvent);
        };
      }, []);

      useEffect(() => {
        if (inputRef.current) {
          const focusTimeout = setTimeout(() => {
            inputRef.current && inputRef.current.focus();
          },100);

          return () => {
            clearTimeout(focusTimeout);
          };
        }
      }, []);

  return (
    <div
      style={{
        alignItems: 'center',
        width:"100%",
        margin:"auto",
      }}
    >
       <div
        style={{
          color: "grey",
          fontSize: "18px",
          textAlign: "center",
          fontWeight: "bold",
          margin: "auto",
          width: "auto",
          position: "sticky",
          top: "0",
          zIndex: "100",
          background: "#fff",
          padding:"10px",
          borderBottom:"1px solid grey"
        }}
      >
        CONTACT
      </div>
     <div
      style={{
        width: isMobile ? "90%" : '60%',
        display: 'flex',
        justifyContent: 'space-between',
        margin: 'auto',
        paddingTop:"30px",
      }}
    >
      <div style={{ textAlign: 'center' }}>
        <Avatar
          size={60}
          style={{
            backgroundColor: '#0077D8',
          }}
          icon={<MailOutlined />}
        />
        <div>
          <strong style={{fontSize:"15px"}}>Email</strong>
        </div>
        <Text style={{color:"grey",fontSize:"14px"}}>support@resure.realestate</Text>
      </div>
      <div style={{ textAlign: 'center' }}>
        <Avatar
          size={60}
          style={{
            backgroundColor: '#0077D8',
          }}
          icon={<MessageOutlined />}
        />
        <div>
          <strong style={{fontSize:"15px"}}>For support please text us at</strong>
        </div>
        <Text style={{color:"grey",fontSize:"14px"}}>(833) 806 - 0086</Text>
      </div>
    </div>
    <div>
    <p style={{fontWeight:"bold", fontSize:"20px",color:"grey",textAlign:"center",marginTop:"35px"}}>How can we help?</p>
    </div>
    <div style={{
        width: isMobile ? "90%" : '60%',
        margin:"auto",
        padding:"10px",
        boxSizing:"border-box",
        }} id="uploadDiv">
    <Form
      {...formItemLayout}
      form={form}
      name="support"
      onFinish={handleSupport}
      scrollToFirstError
    >
      <Form.Item
        name="title"
        label="Title"
        rules={[
          {
            required: true,
            message: "Input Ticket title!",
          },
        ]}
      >
        <Input ref={inputRef} autoFocus />
      </Form.Item>

      <Form.Item
        name="content"
        label="Description"
        rules={[
          {
            required: true,
            message: "Please input ticket Description",
          },
        ]}
      >
        <Input.TextArea style={{ height: "200px" }} showCount maxLength={800} />
      </Form.Item>

      <Form.Item name="priority" label="Priority">
          <Select
            placeholder="Select Priority"
            showArrow
            mode="single"
            options={priorityOptions}
            size="medium"
          />
        </Form.Item>


      <Form.Item label="Upload Images">
        {bufferData.length > 0 ? (
          <Form.Item>
            <div
            //   className={styles.selectedImgBox}
              style={{
                display: "flex",
                maxWidth: "600px",
                overflow: "auto",
                maxHeight: "200px",
                boxShadow:"rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px",
              }}
            >
              {bufferData.map((file, index) => (
                <div key={'index'} style={{ margin: "10px" }}>
                  <Image
                    width={100}
                    src={file.file}
                    preview={{
                      visible: false,
                      mask: (
                        <div
                          style={{
                            cursor: "pointer",
                            position: "absolute",
                            zIndex: 21,
                            top: "0px",
                            right: "0px",
                          }}
                        >
                          <div
                            onClick={(event) => {
                              event.stopPropagation();
                            }}
                          >
                            <Popconfirm
                              title="Are you sure you want to delete this image?"
                              onConfirm={() => {
                                deleteImg(index);
                              }}
                              okText="Yes"
                              cancelText="No"
                            >
                              <CloseCircleOutlined
                                style={{ fontSize: "30px" }}
                              />
                            </Popconfirm>
                          </div>
                        </div>
                      ),
                    }}
                  />
                  <br />
                  <Text type="secondary">{file.name}</Text>
                  <br />
                  <Text type="secondary">Size: {file.size}</Text>
                </div>
              ))}
            </div>
            {bufferData.length < 5 && (
              <Form.Item>
                <Button
                  type="primary"
                  style={{ marginTop: "5px" }}
                  onClick={handleAddFile}
                >
                  Add More Images
                </Button>
              </Form.Item>
             )}
          </Form.Item>
        ) : (
          <Dragger
            accept="image/*"
            showUploadList={false}
            customRequest={handleCustome}
          >
            <div style={{ height: "130px" }}>
              <p className="ant-upload-drag-icon">
                <InboxOutlined style={{ position: "relative", top: "-25px" }} />
              </p>
              <p
                className="ant-upload-text"
                style={{ position: "relative", top: "-55px" }}
              >
                Click or drag file to this area to upload
              </p>
              <p
                className="ant-upload-hint"
                style={{ position: "relative", top: "-55px" }}
              >
                Support for multiple image uploads. Strictly prohibit from
                uploading company data or other banned files
              </p>
            </div>
          </Dragger>
        )}
      </Form.Item>

      <Form.Item {...tailFormItemLayout}>
        <Button type="primary" style={{ width: "100%", }} htmlType="submit">
          Save
        </Button>
      </Form.Item>
    </Form>
  </div>

    </div>
  );
};

export { SupportPage };
