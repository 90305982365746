/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Typography, Button, Col, Row } from "antd";
import moment from "moment";
import AvatarImage from "./NotesImageUpload";
import { landingPageAction } from "../state/actions";
import { clientAction } from "../../Client/state/actions";
import EditorToolbar, { formats } from "./EditorToolbar";
import { modules } from "./EditorToolbar";
import { CaretRightOutlined } from "@ant-design/icons";
import "react-quill/dist/quill.snow.css";
import ReactQuill from "react-quill";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { getCurrentTimeStamp } from "../utils/utilFunctions";
import ImagePreviewModal from "./ImagePreviewModal";
import { Loading } from "../../Common/components/Loading";
import { processBase64Images } from "../../Client/utils/helper";

const { Title } = Typography;

const Notes = (props) => {
  let { source } = props || {};
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const hasChangedNotes = useRef(false);
  const imageId = useRef(null);
  const timer = useRef(null);
  const notesText = useRef(null);
  const currentTimeStamp = useRef(null);
  const [notesState, setNotesState] = useState({
    showImagePreview: false,
    imgPreviewUrl: null,
    isPanelCollapsed: false,
  });
  const [componantDidmount, setcomponantDidmount] = useState(false);
  const  [loading,setLoading] = useState(false)
  const { savedNotes, notesPanelLoading, notesSavingStatus, getAwsUrl } = useSelector((state) => state.landingPageReducer.notesReducer);
  
  let unmounted = {
    value: false,
  };
  let sourceAxios = axios.CancelToken.source();
  useEffect(() => {
    if (props?.noteDrawervisible || source === "CALANDER") {
      currentTimeStamp.current = getCurrentTimeStamp(pathname)
      if (source === "CALANDER") {
        let queryData = { notesFor: "CALANDAR" };
        dispatch(landingPageAction.getSavedNotes({ timestamp:currentTimeStamp.current, queryData, unmounted, sourceAxios }));
      } else if (source === "TRANSACTION") {
        let queryData = {
          notesFor: "TRANSACTION",
          accessibleFor: props.detailsData?.accessibleFor,
          ...props.detailsData,
        };
        dispatch(landingPageAction.getSavedNotes({ timestamp: moment(new Date().setHours(9)).valueOf(), queryData, unmounted, sourceAxios }));
      }
      notesText.current = "";
      hasChangedNotes.current = false;
      clearTimeout(timer.current);
    }
    return () => {
      unmounted.value = true;
      sourceAxios.cancel("Cancelling in cleanup");
      clearTimeout(timer.current);
    };
  }, [pathname, props?.noteDrawervisible]);

  useEffect(() => {
    if (savedNotes && !notesPanelLoading) {
      notesText.current = savedNotes;
    } else if (!notesPanelLoading) {
      notesText.current = "";
    }
    return () => {
      notesText.current = "";
    };
  }, [savedNotes, pathname]);

  useEffect(() => {
    setcomponantDidmount(true);
    return () => {
      setcomponantDidmount(false);
    };
  }, []);

  const handleInputNotes = useCallback(({ content, delta, source }) => {
    if (source === "user") {
      // const isPaste = delta.ops?.some((op) => op.insert && op.insert.length > 1);
      const hasInsertedImages = delta.ops.some((op) => op.insert && op.insert.image);
      clearTimeout(timer.current);
      notesText.current = content;
      hasChangedNotes.current = true;
      timer.current = setTimeout(() => {
        saveNotes({ notes: content, hasInsertedImages });
      }, 2500);
    }
  }, []);

  const handleBlur = () => {
    clearTimeout(timer.current);
    if (hasChangedNotes.current) {
      saveNotes({notes:notesText.current});
    }
  };

  const handleSaveButtonClick = () => {
    clearTimeout(timer.current);
    saveNotes({notes:notesText.current});
  };

  const saveNotes = async ({ notes, hasInsertedImages }) => {
    if (hasInsertedImages) {
      setLoading(true);
      notes = await processBase64Images(notes, hasInsertedImages);
    }
    let data = {};
    if (source === "TRANSACTION") {
      data = {
        notes,
        notesFor: "TRANSACTION",
        ...(props?.detailsData && { ...props?.detailsData }),
      };
    } else {
      data = {
        notes,
        notesFor: "CALANDAR",
        startTimestamp: currentTimeStamp.current,
      };
    }
    dispatch(landingPageAction.saveNotesInCalendar({ data, props }));
    hasChangedNotes.current = false;
    clearTimeout(timer.current);
    notesText.current = notes;
    setLoading(false);
  };


  function UploadImageForNotes(fileData) {
    let id = getUniqueIdForNotesImage(fileData);
    props = {
      ...props,
      startTimestamp:currentTimeStamp.current,
    };
    dispatch(clientAction.postImage({ fileData, id, notesText: notesText?.current, props, functionalityFor: "imageUpload" }));
    dispatch(landingPageAction.setNotesPanelLoading(true));
  }

  function handleImageClick(e) {
    const id = e.target?.alt;
    const src = e.target?.src;
    const width = e.target?.width;
    imageId.current = id;
    if (width === 25) {
      setNotesState((prevState) => ({ ...prevState, imgPreviewUrl: src, showImagePreview: true }));
    }
  }

  // adding event on image for showing in modal
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      const quilEditor = document.getElementById("quillEditorDiv");
      if (quilEditor) {
        quilEditor.addEventListener("click", handleImageClick);
      }
    }, 1000);

    return () => {
      clearTimeout(timeoutId);
      const quilEditor = document.getElementById("quillEditorDiv");
      if (quilEditor) {
        quilEditor.removeEventListener("click", handleImageClick);
      }
    };
  }, [notesPanelLoading]);

  const handleCaretButtonClick = () => {
    setNotesState((prevState) => ({ ...prevState, isPanelCollapsed: !prevState.isPanelCollapsed }));
  };

  const getUniqueIdForNotesImage = (blob) => {
    if (!blob || !blob.lastModifiedDate) {
      return null;
    }
    let alt = blob.lastModifiedDate.toString();
    alt = alt.replace(/\s/g, "");
    return alt;
  };

  // Logic for Changing an Image of Notes when we edit
  const EditImageOFNotes = (fileData) => {
    let idAndImage = imageId.current + "@" + notesState.imgPreviewUrl;
    props = {
      ...props,
      startTimestamp: currentTimeStamp.current,
    };
    dispatch(clientAction.postImage({ fileData, id: idAndImage, notesText: notesText.current, props, functionalityFor: "editImage" }));
    dispatch(landingPageAction.setNotesPanelLoading(true));
    setNotesState((prevState) => ({ ...prevState, showImagePreview: false }));
  };

  const handleDeleteImage = () => {
    clearTimeout(timer.current);
    let data = {};
    let ide = imageId.current;
    let pattern = new RegExp(`(<img[^>]*alt="${ide}"[^>]*>)`, "g");
    let newText = notesText.current?.replace(pattern, "");
    notesText.current = newText;
    if (props?.source === "TRANSACTION") {
      data = {
        notes: newText,
        notesFor: "TRANSACTION",
        ...props?.detailsData,
      };
    } else {
      data = {
        notes: newText,
        notesFor: "CALANDAR",
        startTimestamp:currentTimeStamp.current,
      };
    }
    dispatch(landingPageAction.saveNotesInCalendar({data, props}));
    setNotesState((prevState) => ({ ...prevState, showImagePreview: false }));
  };
  return (
    <>
      {/* Modal will open when clicked on any image in Notes */}
      {notesState?.showImagePreview && <ImagePreviewModal UploadImageForNotes={UploadImageForNotes} EditImageOFNotes={EditImageOFNotes} handleDeleteImage={handleDeleteImage} notesState={notesState} setNotesState={setNotesState} />}
      {(notesPanelLoading || loading || !componantDidmount) && (
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(255, 255, 255, 0.5)", 
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 10, 
          }}
        >
          <Loading />
        </div>
      )}

        <div style={{ paddingBottom: "15px", display: "flex" }} id="NotesContainer">
          <div style={{ width: "100%" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                position: "sticky",
                top: "0",
                backgroundColor: "#fff",
                left: "0",
                width: "100%",
                zIndex: "9",
                padding: "10px 8px 0px 8px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <div>
                  <Title level={3}>{source !== "TRANSACTION" ? "Notes" : "Transaction Notes"} </Title>{" "}
                </div>

                <div style={{ display: "flex", gap: "10px" }}>
                  <Button style={{ border: "none", marginRight: "10px" }} icon={<CaretRightOutlined style={{ fontSize: "25px", color: "#085191" }} rotate={notesState.isPanelCollapsed ? 90 : 0} />} onClick={handleCaretButtonClick} />
                  <AvatarImage id={null} UploadImageForNotes={UploadImageForNotes} EditImageOFNotes={EditImageOFNotes} />
                  <>
                    <div style={{ width: "70px", fontSize: "18px", textAlign: "center", color: "blue", cursor: "pointer" }} onClick={() => !notesSavingStatus && !notesPanelLoading && handleSaveButtonClick()} type="primary">
                      {" "}
                      {notesSavingStatus ? "Saving..." : "Save"}
                    </div>
                  </>
                </div>
              </div>
            </div>
            <div>
              <Row gutter={16}>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <div style={{ display:notesState?.isPanelCollapsed ? "block" : "none", marginTop: source !== "TRANSACTION" ? "75px" : "" }}>
                    <EditorToolbar />
                  </div>
                {componantDidmount && (
                  <div className="ContentEdit">
                    <ReactQuill
                      modules={modules}
                      formats={formats}
                      onChange={(content, delta, source) => {
                        handleInputNotes({ content, delta, source });
                      }}
                      onBlur={handleBlur}
                      value={notesPanelLoading ? "" : notesText?.current || ""}
                      placeholder="What's on your mind today? 💭"
                      contenteditable="true"
                      id="quillEditorDiv"
                      readOnly={notesPanelLoading}
                      style={{
                        border: "0.1",
                        overflowx: "none",
                        overflowY: "none",
                        overflowWrap: "break-word",
                        height: "calc(100vh - 15rem)",
                        background: "lightgoldenrodyellow",
                        color: "black",
                        fontFamily: "monospace",
                        lineHeight: "31px !important",
                      }}
                    />
                  </div>
                )}
                </Col>
              </Row>
            </div>
          </div>
        </div>
    </>
  );
};

export default Notes;
