/* eslint-disable react-hooks/exhaustive-deps */
import { Checkbox } from "antd";
import React, { useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { transactionLaneDataHandle } from "../state/actions";
import { Loading } from "../../Common/components/Loading";
import axios from "axios";
import { PropertyHandlers } from "../../PropertyComp/state/actions";
import moment from "moment";

const DocumentList = ({documentId, clickedItem,data, setData, selectedData, section, nestedModalOpen, RTDInitiator, reloadSection,role, screenSource, processClientDocs }) => {

  const delegate = section === "DELEGATE";
  const delegateOwnerId = delegate && selectedData?.currentAgentId?._id;
 
  const crmSellerDocs = [
    'BDS',
    'DWR',
    'ERTL',
    'ETCT',
    'LCAES_ERTL',
    'LCAES_ETCT',
    "WFD",
  ];
  const { getTransactionLaneData, getTransactionLaneError,  getPropertyDocsData, getPropertyDocsLoading, getPropertyDocsError, getIndividualTransactionData } = useSelector((state) => state.rtdTransactionLane);
  const { personDocs, personDocsLoading } = useSelector((state) => state.client.personDetailReducer, shallowEqual);
  let { propertyDocsWithoutSection, propertDocsWithoutSectionLoading, propertDocsWithoutSectionError, contactDocs } = useSelector((state) => state.PropertyReducer, shallowEqual);
  const dispatch = useDispatch();
  // console.log("selectedData", selectedData);

  let unmounted = {
    value: false,
  };
  let source = axios.CancelToken.source();
  
  const clientDocs = processClientDocs(personDocs);
  
  useEffect(() => {
    
    // Filter contracts based on documentId
    const filteredContracts = getTransactionLaneData?.filter(contract => {
      return contract.documentId === documentId;
    });

    const filteredClientContracts =clientDocs?.filter(contract => {
      return contract._id === documentId;
    });
    
    const propertyDocuments = propertyDocsWithoutSection?.filter(contract => {
      return contract.documentId ===clickedItem;
  });
    if (filteredContracts && filteredContracts.length > 0) {
      // Extract documentIds from filtered contracts
      const documentIds = filteredContracts.map(contract => contract.documentId)
      .filter(documentId => documentId !== undefined);;
      // Update state with the filtered documentIds
      setData(prevState => ({
        ...prevState,
        documentSelected: documentIds,
      }));
    }
if (filteredClientContracts && filteredClientContracts.length > 0) {
    // Extract both documentIds and sentBy from filtered contracts
    const documentDetails = filteredClientContracts
        .map(contract => ({
            documentId: contract?._id,
            sentBy: crmSellerDocs.includes(contract?.contractType)?"SELLER_AGENT":"BUYER_AGENT" // Ensure that the sentBy field exists in your contracts
        }))
        .filter(({ documentId, sentBy }) => documentId !== undefined && sentBy !== undefined); // Filter out any undefined values

    // Update state with the filtered documentDetails
    setData(prevState => ({
        ...prevState,
        clientDocumentSelected: documentDetails,
    }));
}

 
    if (propertyDocuments && propertyDocuments.length > 0) {
      // Extract documentIds from filtered contracts
      
      const documentIds = propertyDocuments.map(contract => contract?.documentId)
      .filter(documentId => documentId !== undefined);
      ;
      // Update state with the filtered documentIds
      setData(prevState => ({
        ...prevState,
        propertyDocsSelected: documentIds,
      }));
     
    }
    
  }, [getTransactionLaneData,propertyDocsWithoutSection,personDocs])
  
  useEffect(() => {
    const delegateSide = delegate && role;
    const client = RTDInitiator === "SELLER" || selectedData?.isProperty ? "SELLER" : "BUYER";
    if (nestedModalOpen?.isclearTransactionLaneData) {
      dispatch(transactionLaneDataHandle.resetTransactionLaneData());
      dispatch(PropertyHandlers.resetPropertyDocs());
    }
    if (getIndividualTransactionData?.offerId && RTDInitiator) {
      if (reloadSection !== "RTD" && RTDInitiator) {
        dispatch(transactionLaneDataHandle.getTransactionLaneMethod({ offerId: getIndividualTransactionData?.offerId, role: client, unmounted, source, delegateSide, delegateOwnerId }));
      }
      dispatch(
        transactionLaneDataHandle.getPropertyDocs({
          offerId: getIndividualTransactionData?.offerId,
          source,
          unmounted,
          delegateSide,
          delegateOwnerId
        })
      );
    } else if (selectedData?.offerId) {
      if (reloadSection !== "RTD") {
        dispatch(transactionLaneDataHandle.getTransactionLaneMethod({ offerId: selectedData?.offerId, role: client, unmounted, source, delegateSide, delegateOwnerId }));
      }
      dispatch(
        transactionLaneDataHandle.getPropertyDocs({
          offerId: selectedData?.offerId,
          source,
          unmounted,
          delegateSide,
          delegateOwnerId
        })
      );
    }

    let params = {};
    let type = "commonSendEmail";
    if (RTDInitiator) {
      params = {
        ...(getIndividualTransactionData?.offerId ? { offerId: getIndividualTransactionData?.offerId } : {}),
        ...(client === "SELLER" && (getIndividualTransactionData?.propertyId ? { propertyId: getIndividualTransactionData?.propertyId } : {})),
        openFor: RTDInitiator === "BUYER" ? "BUYER_AGENT" : "SELLER_AGENT",
        ...(delegate ? { delegateSide: RTDInitiator === "SELLER" ? "SELLER" : "BUYER", delegateOwnerId } : {}),
        section: "SEND_DOCUMENT"
      };
    } else {
      params = {
        ...(selectedData?.offerId ? { offerId: selectedData?.offerId } : {}),
        ...(client === "SELLER" && (selectedData?.propertyId ? { propertyId: selectedData?.propertyId } : {})),
        openFor: selectedData?.isProperty ? "SELLER_AGENT" : "BUYER_AGENT",
        personId: selectedData?.currentAgentId?._id,
        ...(delegate ? { delegateSide: client === "SELLER" ? "SELLER" : "BUYER", delegateOwnerId } : {}),
        section: "SEND_DOCUMENT"
      };
    }
    if (screenSource !== "CLIENT_DOC") {
      dispatch(PropertyHandlers.getPropertyDocsFun({ params, unmounted, source, type }));
    }

    return () => {
      unmounted.value = true;
      source.cancel("Cancelling in cleanup");
    };
  }, []);


  const handleClientDocChange = (documentId, sentBy) => {
    // Create a new array of clientDocumentSelected
    let flag = [...data?.clientDocumentSelected];
    

    // Create an object with documentId
    const newDoc = { documentId,sentBy };

    // Check if the documentId already exists in the array
    const index = flag.findIndex(item => item.documentId === documentId);

    let newSelectedDocs;
    if (index !== -1) {
        // Remove the existing document object from the array
        newSelectedDocs = flag.filter((_, i) => i !== index);
    } else {
        // Add the new document object to the array
        newSelectedDocs = [...flag, newDoc];
    }

    // Update the state with the new array
    setData({
        ...data,
        clientDocumentSelected: newSelectedDocs
    });
};


  const handleSharedDocs = (documentId) => {
    let flag = [...data?.sharedSelected];
    const value = flag?.includes(documentId) ? { sharedSelected: flag?.filter((id) => id !== documentId) } : { sharedSelected: [...flag, documentId] };
    setData({
      ...data,
      ...value,
    });
  };

  const handleCustomerDocs = (documentId) => {
    let flag = [...data?.customerDocumentSelected];
    const value = flag?.includes(documentId) ? { customerDocumentSelected: flag?.filter((id) => id !== documentId) } : { customerDocumentSelected: [...flag, documentId] };
    setData({
      ...data,
      ...value,
    });
  };

  const handlePropertyDocs = (documentId, type) => {
    let flag = [];

    if (type === "PROPETY_DOC") {
      flag = [...data?.propertyDocsSelected];
    } else if (type === "DOCUMENT_WITHOUT_BUYERS") {
      flag = [...data?.documentWithoutBuyerSelected];
    }

    const value = flag.includes(documentId) ? flag.filter((id) => id !== documentId) : [...flag, documentId];

    setData({
      ...data,
      ...(type === "PROPETY_DOC" ? { propertyDocsSelected: value } : {}),
      ...(type === "DOCUMENT_WITHOUT_BUYERS" ? { documentWithoutBuyerSelected: value } : {})
    });
  };

  const handleSelectAll = ({ value, element, type }) => {
    
    if (value) {
     if (type === "CLIENT DOCUMENT") {
        // Map to include both documentId and sentBy for CLIENT DOCUMENT
        const clientDocumentObjects = element.map((document) => ({
          documentId: document._id, // Assuming _id is used as documentId
          sentBy:  crmSellerDocs.includes(document?.contractType)?"SELLER_AGENT":"BUYER_AGENT"
        }));

        setData(prevData => ({
          ...prevData,
          clientDocumentSelected: clientDocumentObjects
        }));
      } else {
        // Handle other types
        const allDocumentIds = element.map((document) => (type === "DOCUMENT" ? document.documentId : document._id));
        setData(prevData => ({
          ...prevData,
          ...(type === "DOCUMENT" ? { documentSelected: allDocumentIds }
            : type === "SHARED" ? { sharedSelected: allDocumentIds }
              : type === "DOCUMENT_WITHOUT_BUYERS" ? { documentWithoutBuyerSelected: allDocumentIds } 
              : type === "CUSTOMER_DOCUMENTS" ? { customerDocumentSelected: allDocumentIds }
                : { propertyDocsSelected: allDocumentIds }),
        }));
      }
    } else {
      // Handle the case where value is false
      setData(prevData => ({
        ...prevData,
        ...(type === "DOCUMENT" ? { documentSelected: [] }
          : type === "SHARED" ? { sharedSelected: [] }
            : type === "CLIENT DOCUMENT" ? { clientDocumentSelected: [] }
              : type === "DOCUMENT_WITHOUT_BUYERS" ? { documentWithoutBuyerSelected: [] }
              : type === "CUSTOMER_DOCUMENTS" ? { customerDocumentSelected: [] }
                : { propertyDocsSelected: [] }),
      }));
    }
  };

const documentsWithCreatedDocuments = personDocs?.filter(doc => doc.createdDocuments.length > 0);
// Transformation logic
const transformedDocuments = [];
documentsWithCreatedDocuments?.forEach(doc => {
  const { createdDocuments, doc_second, ...rest } = doc;
  for (let i = 0; i < createdDocuments.length; i++) {
    const newDocEntry = { ...rest };
    if (i === 0 && doc_second.length > 0) {
      newDocEntry.name += ':';
      doc_second.forEach(docSecond => {
        newDocEntry[`    ${docSecond.name}`] = docSecond; // Indent doc_second entries under the main document
      });
    }
    transformedDocuments.push(newDocEntry);
  }
});

  const transactionAndPropertyDocs = propertyDocsWithoutSection?.filter((ele) => ["ERTS", "FRASCONA_ERTS", "LCAES"].includes(ele?.contractType) || ele?.hasOwnProperty("isShared") || ele?.offerId);

  return (
    <div style={{ width: "100%" }}>
      {screenSource === "CLIENT_DOC" && personDocsLoading ? (
        <div>
          <Loading />
        </div>
      ) : screenSource === "CLIENT_DOC" && getTransactionLaneError ? (
        ""
      ) : (
        screenSource === "CLIENT_DOC" &&
        clientDocs?.length > 0 && (
          <div>
            <div style={{ overflow: "hidden" }}>
              <div style={{ padding: "10px" }}>
                <div style={{ display: "flex", justifyContent: "space-between", fontWeight: "bold", color: "grey" }}>
                  <p>Client Documents:</p>
                  <Checkbox onChange={(e) => handleSelectAll({ value: e.target.checked, element: clientDocs, type: "CLIENT DOCUMENT" })} checked={data?.clientDocumentSelected?.length === clientDocs?.length}>
                    <text style={{ color: "grey" }}>Select All</text>
                  </Checkbox>
                </div>
                {clientDocs?.map((document, index) => (
                  <div key={index} style={{ margin: "0px 0 10px 20px", display: "flex", flexDirection: "column" }}>
                    {document?.isSecondLevel && (
                      <>
                        <div style={{ marginLeft: "20px" }}>
                          <Checkbox style={{ marginRight: "10px" }} checked={data?.clientDocumentSelected?.some((item) => item.documentId === document._id)} onChange={() => handleClientDocChange(document._id, crmSellerDocs.includes(document?.contractType) ? "SELLER_AGENT" : "BUYER_AGENT")}>
                            {document.contractName}
                          </Checkbox>
                        </div>
                      </>
                    )}
                    {!document.isSecondLevel && (
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <Checkbox style={{ marginRight: "10px" }} checked={data?.clientDocumentSelected?.some((item) => item.documentId === document._id)} onChange={() => handleClientDocChange(document._id, crmSellerDocs.includes(document?.contractType) ? "SELLER_AGENT" : "BUYER_AGENT")}>
                          {document?.contractName}
                        </Checkbox>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
        )
      )}

      {/* Property documents list  */}
      {screenSource !== "CLIENT_DOC" && propertDocsWithoutSectionLoading ? (
        <div>
          <Loading />
        </div>
      ) : screenSource !== "CLIENT_DOC" && propertDocsWithoutSectionError ? (
        ""
      ) : <>
        {
          (
            screenSource !== "CLIENT_DOC" &&
            propertyDocsWithoutSection?.length > 0 && (
              <div>
                <div style={{ overflow: "hidden" }}>
                  {transactionAndPropertyDocs.length ? (
                    <div style={{ padding: "10px" }}>
                      <div style={{ display: "flex", justifyContent: "space-between", fontWeight: "bold", color: "grey" }}>
                        <p>Transaction & Property Documents:</p>
                        <Checkbox
                          onChange={(e) =>
                            handleSelectAll({
                              value: e.target.checked,
                              element: propertyDocsWithoutSection?.filter((ele) =>  ["ERTS", "FRASCONA_ERTS", "LCAES"].includes(ele?.contractType) || ele?.hasOwnProperty("isShared") || ele?.offerId),
                              type: "PROPETY_DOC",
                            })
                          }
                          checked={data?.propertyDocsSelected?.length === propertyDocsWithoutSection?.filter((ele) => ["ERTS", "FRASCONA_ERTS", "LCAES"].includes(ele?.contractType) || ele?.hasOwnProperty("isShared") || ele?.offerId)?.length}
                        >
                          <p style={{ color: "grey" }}>Select All</p>
                        </Checkbox>
                      </div>
                      {transactionAndPropertyDocs.map((document, index) => (
                        <div key={index} style={{ margin: "0px 0 10px 20px", display: "flex", alignItems: "center" }}>
                          <Checkbox style={{ marginRight: "10px" }} checked={data?.propertyDocsSelected?.includes(document._id)} onChange={() => handlePropertyDocs(document._id, "PROPETY_DOC")}>
                            {document?.contractId ? document?.documentName : document?.fileName} {moment(document?.createdDateMili).format("MM/DD/YYYY")} at {moment(document?.createdDateMili).format("hh:mm A")}
                          </Checkbox>
                        </div>
                      ))}
                    </div>
                  ): null}
    
                  {/* Section for Documents without Buyers */}
                  {propertyDocsWithoutSection?.filter((ele) => !(["ERTS", "FRASCONA_ERTS", "LCAES"].includes(ele?.contractType)) && !ele?.hasOwnProperty("isShared") && !ele?.offerId)?.length > 0 && (
                    <div style={{ padding: "10px" }}>
                      <div style={{ display: "flex", justifyContent: "space-between", fontWeight: "bold", color: "grey" }}>
                        <p>Documents without Buyers:</p>
                        <Checkbox
                          onChange={(e) =>
                            handleSelectAll({
                              value: e.target.checked,
                              element: propertyDocsWithoutSection?.filter((ele) => !(["ERTS", "FRASCONA_ERTS", "LCAES"].includes(ele?.contractType)) && !ele?.hasOwnProperty("isShared") && !ele?.offerId),
                              type: "DOCUMENT_WITHOUT_BUYERS",
                            })
                          }
                          checked={data?.documentWithoutBuyerSelected?.length === propertyDocsWithoutSection?.filter((ele) => !(["ERTS", "FRASCONA_ERTS", "LCAES"].includes(ele?.contractType)) && !ele?.hasOwnProperty("isShared") && !ele?.offerId)?.length}
                        >
                          <p style={{ color: "grey" }}>Select All</p>
                        </Checkbox>
                      </div>
                      {propertyDocsWithoutSection
                        ?.filter((ele) => !(["ERTS", "FRASCONA_ERTS", "LCAES"].includes(ele?.contractType)) && !ele?.hasOwnProperty("isShared") && !ele?.offerId)
                        ?.map((document, index) => (
                          <div key={index} style={{ margin: "0px 0 10px 20px", display: "flex", alignItems: "center" }}>
                            <Checkbox style={{ marginRight: "10px" }} checked={data?.documentWithoutBuyerSelected?.includes(document?._id)} onChange={() => handlePropertyDocs(document._id, "DOCUMENT_WITHOUT_BUYERS")}>
                              {document?.contractId ? document?.documentName : document?.fileName} {moment(document?.createdDateMili).format("MM/DD/YYYY")} at {moment(document?.createdDateMili).format("hh:mm A")}
                            </Checkbox>
                          </div>
                        ))}
                    </div>
                  )}
                </div>
              </div>
            )
          )
        }
        {
          screenSource !== "CLIENT_DOC" && (contactDocs?.length > 0)  && (
            <div>
              <div style={{ overflow: "hidden" }}>
                <div style={{ padding: "10px" }}>
                  <div style={{ display: "flex", justifyContent: "space-between", fontWeight: "bold", color: "grey" }}>
                    <p>Customer Documents:</p>
                    <Checkbox onChange={(e) => handleSelectAll({ value: e.target.checked, element: contactDocs, type: "CUSTOMER_DOCUMENTS" })} checked={data?.customerDocumentSelected?.length === contactDocs?.length}>
                      <p style={{ color: "grey" }}>Select All</p>
                    </Checkbox>
                  </div>
                  {contactDocs?.map((document, index) => (
                    <div key={index} style={{ margin: "0px 0 10px 20px", display: "flex", alignItems: "center" }}>
                      <Checkbox style={{ marginRight: "10px" }} checked={data?.customerDocumentSelected?.includes(document._id)} onChange={() => handleCustomerDocs(document._id)}>
                        {document?.documentName} {moment(document?.createdDateMili).format("MM/DD/YYYY")} at {moment(document?.createdDateMili).format("hh:mm A")}
                      </Checkbox>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )
        }
      </>}

      {/* shared document list  */}
      {getPropertyDocsLoading ? (
        <div>
          <Loading />
        </div>
      ) : getPropertyDocsError ? (
        ""
      ) : (
        getPropertyDocsData?.length > 0 && (
          <div>
            <div style={{ overflow: "hidden" }}>
              <div style={{ padding: "10px" }}>
                <div style={{ display: "flex", justifyContent: "space-between", fontWeight: "bold", color: "grey" }}>
                  <p>Shared Documents:</p>
                  <Checkbox onChange={(e) => handleSelectAll({ value: e.target.checked, element: getPropertyDocsData, type: "SHARED" })} checked={data?.sharedSelected?.length === getPropertyDocsData?.length}>
                    <p style={{ color: "grey" }}>Select All</p>
                  </Checkbox>
                </div>
                {getPropertyDocsData?.map((document, index) => (
                  <div key={index} style={{ margin: "0px 0 10px 20px", display: "flex", alignItems: "center" }}>
                    <Checkbox style={{ marginRight: "10px" }} checked={data?.sharedSelected?.includes(document._id)} onChange={() => handleSharedDocs(document._id)}>
                      {document?.fileName} {moment(document?.createdDateMili).format("MM/DD/YYYY")} at {moment(document?.createdDateMili).format("hh:mm A")}
                    </Checkbox>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )
      )}
    </div>
  );
};

export default DocumentList;
