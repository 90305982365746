import React from "react";
import { Collapse, Dropdown, List, Menu, Modal } from "antd";
import { FileZipOutlined, MoreOutlined, ExclamationCircleOutlined, DeleteOutlined, EditOutlined, FolderOpenOutlined, FolderFilled, PlusCircleFilled } from "@ant-design/icons";
import { useMediaQuery } from "react-responsive";
import { useDispatch, useSelector } from "react-redux";
import { toolsAction } from "../state/actions";
import { transactionLaneDataHandle } from "../../Rtd/state/actions";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { capitalizeFirst } from "../../Common/utils/extraFunctions";

const { Panel } = Collapse;

const DocumentSetDisplay = ({ documentSetData, setIsModalOpen, isModalOpen, setDocumentSets, setDocumentSetName, setSelectedFiles }) => {
  const isMobile = useMediaQuery({ maxWidth: "480px" });
  const dispatch = useDispatch();
  const location = useLocation();
  const { getUserDetailsData } = useSelector((state) => state.account);
  const delegate = getUserDetailsData?.delegateId?._id ? true : false;

  const handleDeleteDocSet = (id) => {
    Modal.confirm({
      title: "Are you sure you want to delete this document set?",
      okText: "Yes",
      cancelText: "No",
      icon: <ExclamationCircleOutlined />,
      onOk() {
        dispatch(toolsAction.deleteDocTemplate(id));
      },
      onCancel() {},
    });
  };

  const handleDeleteDocFile = (folderId, docId) => {
    Modal.confirm({
      title: "Are you sure you want to delete this document?",
      okText: "Yes",
      cancelText: "No",
      icon: <ExclamationCircleOutlined />,
      onOk() {
        dispatch(toolsAction.deleteDocTemplate(folderId, docId));
      },
      onCancel() {},
    });
  };

  const menu = (set) => (
    <Menu>
      <Menu.Item
        key="edit"
        icon={<PlusCircleFilled style={{ color: "#085190" }} />}
        onClick={() => {
          setDocumentSets([]);
          setSelectedFiles([]);
          setDocumentSetName(set?.name);
          setIsModalOpen({ ...isModalOpen, modalOpen: true, update: true, addToId: set?._id });
        }}
      >
        Add
      </Menu.Item>
      <Menu.Item key="delete" icon={<DeleteOutlined style={{ color: "#1990ff" }} />} onClick={() => handleDeleteDocSet(set?._id)}>
        Delete
      </Menu.Item>
    </Menu>
  );

  const handleOpenDoraDoc = (ele) => {
    let buildData = {
      documentId: ele?._id,
      contractType: ele?.contractType,
      contractId: ele?.contractId?._id,
      // openFor: item?.isProperty ? "SELLER_AGENT" : "BUYER_AGENT",
      token: localStorage.getItem("userAuth"),
      builtForId: getUserDetailsData?._id,
      key: getUserDetailsData?._id,
      // ...(item.offerId ? { offerId: item.offerId } : {}),
      ...(delegate ? { delegateOwnerId: getUserDetailsData?._id } : {}),
      builtForSection: "DOCUMENT_SET",
      signable: true,
      // ...(ele.RTDInitiator && {  RTDInitiator: ele.RTDInitiator}),
      // ...(item?.propertyId ? { propertyId: item?.propertyId } : {})
    };
    dispatch(transactionLaneDataHandle.generateUrlIdAndRedirect({ buildData, currentPath: location?.pathname }));
  };

  const individualMenu = (set, item) => (
    <Menu>
      <Menu.Item key="edit" icon={<EditOutlined style={{ color: "#1990ff" }} />} onClick={() => handleOpenDoraDoc(item)}>
        Edit
      </Menu.Item>
      <Menu.Item key="delete" icon={<DeleteOutlined style={{ color: "#1990ff" }} />} onClick={() => handleDeleteDocFile(set?._id, item?._id)}>
        Delete
      </Menu.Item>
    </Menu>
  );

  return (
    <div
      style={{
        maxWidth: isMobile ? "390px" : "550px",
      }}
    >
      {documentSetData?.map((set, index) => (
        <Collapse key={index} bordered={"false"} ghost expandIcon={({ isActive }) => (isActive ? <FolderOpenOutlined style={{ fontSize: "24px", color: "#6B98C7", marginRight: 10, marginTop:"1px" }} /> : <FolderFilled style={{ fontSize: "24px", color: "#6B98C7", marginRight: 10, marginTop:"1px" }} />)}>
          <Panel
            key={index}
            header={
              <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <span style={{ fontSize: "18px", textTransform: "none" }}>{capitalizeFirst(set?.name)}</span>
                <Dropdown overlay={menu(set)} trigger={["click", "hover"]}>
                  <MoreOutlined style={{ cursor: "pointer", fontSize: "26px", strokeWidth: "2", color: "#085190" }} />
                </Dropdown>
              </div>
            }
          >
            <List
              style={{ padding: "0 30px" }}
              dataSource={set?.documentSets}
              renderItem={(item) => (
                <List.Item>
                  <div style={{display:"flex",gap:"5px"}}>
                    <FileZipOutlined style={{ marginRight: 8, fontSize:"16px" }} />
                    {item?.contractId?.documentaryId?.name}
                  <Dropdown overlay={individualMenu(set, item)} trigger={["click", "hover"]}>
                    <MoreOutlined style={{ cursor: "pointer", fontSize: "20px", color: "#085190" }} />
                  </Dropdown>
                  </div>
                </List.Item>
              )}
            />
          </Panel>
        </Collapse>
      ))}
    </div>
  );
};

export default DocumentSetDisplay;
