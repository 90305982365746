/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { Col, Row, Button, Modal, Form, Input, Select, Spin } from "antd";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { documentAction } from "../../DocumentSection/state/actions";
import { templateAction } from "../state/actions";
const { Option } = Select;

const CreateTemplateModal = ({ templateState, setTemplateState }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { userId } = useSelector((state) => state.account);
  const location = useLocation();
  const { getCategoryData, getCategoryLoading } = useSelector((state) => state.documentSection.categoryReducer, shallowEqual);

  const handleOk = (values) => {
    if (values?.propertyType) {
      values = {
        ...values,
        propertyType: JSON.parse(values?.propertyType),
      };
    }

    if (!values.propertyType || !values.propertyType.contractId) {
      return;
    }

    let buildData = {
      builtForId: userId,
      builtForSection: "TEMPLATE",
      signable: true,
      openFor: "BUYER_AGENT",
      contractType: values?.propertyType?.contractType,
      token: localStorage.getItem("userAuth"),
      contractId: values?.propertyType?.contractId,
      createOrEditTemplate: true,
      ...(values?.title?.length > 0 ? { templateName: values.title } : {}),
      ...(values?.description?.length > 0 ? { templateDescription: values.description } : {}),
    };
    dispatch(templateAction.templateOffer(buildData, location?.pathname, true));
    setTemplateState((prevState) => ({ ...prevState, createTemplateModal: false }));
  };
  const handleCancel = () => {
    setTemplateState((prevState) => ({ ...prevState, createTemplateModal: false }));
    form.resetFields();
  };
  return (
    <Modal visible={templateState?.createTemplateModal} onOk={handleOk} onCancel={handleCancel} footer={false} title={<div className="commonModalTitle">Create Template</div>}>
      <Form onFinish={handleOk} layout="vertical" form={form}>
        <Form.Item
          className="lbl"
          name="propertyType"
          label="Document Type"
          rules={[
            {
              required: true,
              message: "Please select document type!",
            },
          ]}
          onFocus={() => {
            dispatch(documentAction.getDocumentData());
          }}
        >
          <Select showSearch placeholder="Select document type" filterOption={(input, option) => option?.children?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0} allowClear>
            {getCategoryLoading || getCategoryLoading === null ? (
              <div style={{ minHeight: "20vh", display: "flex", alignItems: "center", textAlign: "center" }}>
                <Spin size="medium" />
              </div>
            ) : (
              getCategoryData?.map((element, index) => (
                <Option key={element.contractId} value={JSON.stringify(element)} disabled={element.isDisabled}>
                  {element.name}
                </Option>
              ))
            )}
          </Select>
        </Form.Item>

        <Form.Item
          className="lbl"
          name="title"
          label="Title"
          rules={[
            {
              required: true,
              message: "Please add title!",
            },
          ]}
        >
          <Input placeholder="Enter Title" />
        </Form.Item>

        <Form.Item className="lbl" name="description" label="Description">
          <Input placeholder="Enter Description" />
        </Form.Item>

        <Row gutter={16} justify="end">
          <Col>
            <Button default onClick={handleCancel}>
              Cancel
            </Button>
          </Col>
          <Col>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Create Template
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default CreateTemplateModal;
