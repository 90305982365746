import { clientScreenConstants } from "./action-types.js";
const initialState = {
  setLoading: false,
  setError: "",
  clientScreenData: [],
  clientScreenDataFromApi: false,
  csDeletedDocPersonData: {},

  emailFilesLoading: false,
  emailFilesError: "",
  emailFilesData: [],

  propertUploadDocs: [],
  propertDocsLoading: false,
  propertDocsError: null,

  getSharedCalendarLoading: false,
  getSharedCalendarError: null,
  getSharedCalendarData: {},

};
const clientScreen = (state = initialState, action) => {
  switch (action.type) {
    case  clientScreenConstants.CLIENT_SCREEN_LOADING:
      return {
        ...state,
        setLoading:true,
        setError: "",
      };
    case  clientScreenConstants.CLIENT_SCREEN_ERROR:
      return {
        ...state,
        setLoading: action.setLoading,
        setError: action.setError,
      };
    case  clientScreenConstants.CLIENT_SCREEN_SUCCESS:
      
      return {
        ...state,
        setLoading: false,
        setError: false,
        clientScreenData:action.payload,
        clientScreenDataFromApi: true
      };
      case  clientScreenConstants.EMAIL_FILES_LOADING:
        return {
          ...state,
          emailFilesLoading:true,
          emailFilesError: "",
          emailFilesData: [],
        };
      case  clientScreenConstants.EMAIL_FILES_ERROR:
        return {
          ...state,
          emailFilesLoading: action.emailFilesLoading,
          emailFilesError: action.emailFilesError,
        };
      case  clientScreenConstants.EMAIL_FILES_SUCCESS:
        
        return {
          ...state,
          emailFilesLoading: false,
          emailFilesError: false,
          emailFilesData:action.payload,
        };

        case clientScreenConstants.UPLOAD_DOCUMENT_LOADING:
          return {
            ...state,
            propertDocsLoading: true,
            propertDocsError: null,
          };
        case clientScreenConstants.UPLOAD_DOCUMENT_SUCCESS:
          return {
            ...state,
            propertUploadDocs: action.propertUploadDocs,
            propertDocsLoading: false,
            propertDocsError: null,
          };
        case clientScreenConstants.UPLOAD_DOCUMENT_FAILURE:
          return {
            ...state,
            propertUploadDocs: [],
            propertDocsLoading: false,
            propertDocsError: null,
          };
          case  clientScreenConstants.CLIENT_SCREEN_DELETED_DOC_SUCCESS:
          return {
            ...state,
            setLoading: false,
            setError: false,
            csDeletedDocPersonData : action.payload
          };
    case clientScreenConstants.GET_SHARED_CALENDAR_LOADING:
      return {
        ...state,
        getSharedCalendarLoading: true,
        getSharedCalendarError: null,
        getSharedCalendarData: {},
      };
    case clientScreenConstants.GET_SHARED_CALENDAR_SUCCESS:
      return {
        ...state,
        getSharedCalendarLoading: false,
        getSharedCalendarError: null,
        getSharedCalendarData: action.urlIdData,
      };
    case clientScreenConstants.GET_SHARED_CALENDAR_ERROR:
      return {
        ...state,
        getSharedCalendarLoading: false,
        getSharedCalendarError: action.error,
        getSharedCalendarData: {},
      };
    case clientScreenConstants.LINK_NOT_EXISTS:
      return {
        ...state,
        getSharedCalendarLoading: false,
        getSharedCalendarError: action.error,
        getSharedCalendarData: {},
      };
    case clientScreenConstants.SET_AUTH_KEY_FOR_READONLY_CLIENT:
      return {
        ...state,
        contractAuthKey: action.contractAuthKey,
      };
    default:
      return state;
  }
};
export { clientScreen };
