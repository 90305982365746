import { Menu } from "antd";
import React from "react";
import { FileAddOutlined, DeleteTwoTone } from "@ant-design/icons";
import { canPropertyDocItemBeDeleted } from "../utils/helper";

const ProperyDocMenuItem = ({ parentDoc, eachDoc, getCoordinateItem, handleDeletePropertyDocs, handleCreateAmendExtend, onDeleteItem }) => {
  // console.log({ parentDoc, eachDoc, getCoordinateItem, handleDeletePropertyDocs, handleCreateAmendExtend });
  return (
    <Menu size="large">
      {eachDoc?.doc_second?.length > 0 && (
        <Menu.Item
          key="CreateLCAE"
          onClick={(event) => {
            handleCreateAmendExtend({ parentDoc, docSecondItem: parentDoc?.doc_second?.[0] });
          }}
        >
          <FileAddOutlined
            style={{
              fontSize: 16,
              color: "#0088cc",
            }}
          />

          <span style={{ color: "04396B" }}> Create Amend/Extend</span>
        </Menu.Item>
      )}

      {canPropertyDocItemBeDeleted({ eachDoc: eachDoc, getCoordinateItem: getCoordinateItem }) && (
        <Menu.Item key="draft" onClick={() => handleDeletePropertyDocs(eachDoc, eachDoc?.RTDInitiator ? "contractDoc" : "uploadedDoc")}>
          <DeleteTwoTone
            style={{
              fontSize: 16,
            }}
          />
          <span style={{ color: "04396B" }}> Delete document</span>
        </Menu.Item>
      )}
      {parentDoc?.storageType === "FOLDER" && !parentDoc?.nestedItems?.length && (
        <Menu.Item key="draft" onClick={() => onDeleteItem(parentDoc)}>
          <DeleteTwoTone
            style={{
              fontSize: 16,
            }}
          />
          <span style={{ color: "04396B" }}> Delete Folder</span>
        </Menu.Item>
      )}
    </Menu>
  );
};

export default ProperyDocMenuItem;
