import React, { useEffect, useState } from 'react'
import { ClientNavbar } from '../App/components/ClientNavbar';
import { useLocation } from "react-router-dom";
import { Calendar } from '../PropertyComp/components/Calendar';
import styles from "./styles/ClientScreen.module.css"
import { message, Spin, Tabs } from 'antd';
import { Help } from '../App/components/Help';
import { decryptQuery } from '../Common/utils/extraFunctions';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { clientScreenHandle } from './state/actions';
const { TabPane } = Tabs;

function ReadOnlyClientScreen() {
    const search = useLocation().search;
    const query = new URLSearchParams(search);
    const urlId = decryptQuery(query.get('urlId'));
    const dispatch = useDispatch();
    const { contractAuthKey, getSharedCalendarData, getSharedCalendarError, getSharedCalendarLoading } = useSelector((state) => state.clientScreen);

    useEffect(() => {
        let unmounted = {
          value: false,
        };

        let sourceAxios = axios.CancelToken.source();
        if (urlId) {
          dispatch(clientScreenHandle.getSharedCalendarData({ urlId, unmounted, sourceAxios}));
        }
        
        return () => {
          unmounted.value = true;
          sourceAxios.cancel("Cancelling in cleanup");
        //   if (urlId) {
        //     localStorage.removeItem(urlId);
        //   }
        };
      }, [urlId]);

    return (
        getSharedCalendarError ? ( message.error(getSharedCalendarError)) : 
        getSharedCalendarLoading ? (<Spin size="large" style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }} />) : (
        <div style={{overflow: 'hidden'}}>
            <ClientNavbar source={'ReadOnlyClientScreen'} urlData={getSharedCalendarData} />
            <Tabs defaultActiveKey="1" centered size='small' style={{overflow: 'hidden'}}
                tabBarExtraContent={<div style={{ margin: "5px 5px 0 0" }}><Help tabName="CLIENT_SCREEN" /></div>}
            >
                <TabPane tab={<div>Calendar</div>} key="1">
                    {getSharedCalendarData?.fullAddress && <div className={styles.readOnlyFullAddress}>{getSharedCalendarData?.fullAddress}</div>}
                    <Calendar agentType={(getSharedCalendarData?.openFor === 'SELLER_AGENT') ? "SELLER" : (getSharedCalendarData?.openFor === 'BUYER_AGENT') ? 'BUYER' : getSharedCalendarData?.openFor} viewType={'month'} screen={"ReadOnlyClientScreen"}  offerId={getSharedCalendarData?.offerId} clientToken={contractAuthKey} />
                </TabPane>
            </Tabs>
        </div>
    ))
}

export default ReadOnlyClientScreen
